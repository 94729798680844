import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import Sort from "../../../assets/Icon/Short.png";
import moment from "moment/moment";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ApiCalling from "../../../network/ApiCalling";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "react-dropdown-select";
import { DateRangePicker } from "rsuite";
import PaymentView from "../../ParentsDashboard/Billing/PaymentView";
import Loader from "../../../components/common/Loader";

export default function AllSchoolCredits() {
  const { t } = useTranslation();
  const history = useHistory();
  const school = Util.getSelectedSchool();
  const schoolId = school?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [allCreditList, setAllCreditList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [method, setMethod] = useState("");
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [paymentId, setPaymentId] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [isAscending, setIsAscending] = useState(true);

  const methodList = [
    { value: "", name: "All" },
    { value: "CASH", name: "Cash" },
    { value: "BANK CHANNEL", name: "Bank Channel" },
  ];

  const changeMethod = (data) => {
    setCurrentPage(1);
    setMethod(data[0].value);
  };

  const searchClicked = () => {
    setCurrentPage(1);
    setSearchClick(search);
  };
  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setSearchClick(search);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const ChangeDateRange = (range) => {
    if (range) {
      setStartDate(new Date(range[0]));
      setEndDate(new Date(range[1]));
    }
  };
  const handleCleanDate = () => {
    setStartDate();
    setEndDate();
  };

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const getAllCredits = () => {
    setIsLoading(true);
    const data = {
      school_id: schoolId,
      page: currentPage,
      size: recordPerPage,
      method: method,
      student_name: searchClick,
      start_date: startDate,
      end_date: endDate,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
    };
    ApiCalling.apiCallBodyDataPost("payment/school", data)
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          setAllCreditList(res.data.response);
          setTotalPages(res.data.total_page);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getAllCredits();
  }, [
    currentPage,
    recordPerPage,
    searchClick,
    method,
    startDate,
    endDate,
    sortBy,
    sortOrder,
  ]);

  const toggleSort = () => {
    const sortedData = [...allCreditList].sort((a, b) =>
      isAscending ? a.value - b.value : b.value - a.value
    );
    setAllCreditList(sortedData);
    setIsAscending(!isAscending);
  };

  return (
    <div className="mt-4">
      <div className={"table-div"}>
        <div className="d-flex gap-20 align-items-center table-sort-bar">
          <div className="custom-search-wrap">
            <div className="input-group">
              <input
                className="form-control search"
                placeholder="Search Student"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => EnterHandler(e)}
              />
              <div className="input-group-prepend">
                <div className="search-icon" onClick={searchClicked}>
                  <i className="fa fa-search"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="mr-2">
            <Select
              className="select-custom"
              options={[...methodList]}
              onChange={(values) => changeMethod(values)}
              style={{ width: "100%" }}
              labelField="name"
              values={[{ value: "", name: "Method" }]}
              valueField="value"
            />
          </div>
          <div className="mr-2">
            <DateRangePicker
              onChange={(range) => ChangeDateRange(range)}
              onClean={handleCleanDate}
              placeholder="Select Date"
            />
          </div>
        </div>
        <div className="row m-0">
          <div className="col-lg-12">
            <div className="table-wrapper">
              {!isLoading ? (
                <table
                  className="table"
                  style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
                >
                  <thead style={{ backgroundColor: "#e5e7eb" }}>
                    <tr>
                      <th>
                        {t("Payer")}
                        <img
                          src={Sort}
                          className="ml-1 fa-sort"
                          alt=""
                          onClick={() => {
                            handleSort("payment.payment_method");
                          }}
                        />
                      </th>
                      <th>
                        {t("Date")}{" "}
                        <img
                          src={Sort}
                          className="ml-1 fa-sort"
                          alt=""
                          onClick={() => {
                            handleSort("payment.date");
                          }}
                        />
                      </th>
                      <th>
                        {t("Status")}{" "}
                        <img
                          src={Sort}
                          className="ml-1 fa-sort"
                          alt=""
                          onClick={() => {
                            handleSort("payment.status");
                          }}
                        />
                      </th>
                      <th>
                        {t("Invoices")}{" "}
                        <img
                          src={Sort}
                          className="ml-1 fa-sort"
                          alt=""
                          onClick={() => {
                            toggleSort();
                          }}
                        />
                      </th>
                      <th>
                        {t("Amount")}{" "}
                        <img
                          src={Sort}
                          className="ml-1 fa-sort"
                          alt=""
                          onClick={() => {
                            handleSort("payment.amount");
                          }}
                        />
                      </th>
                      <th>
                        {t("Balance")}{" "}
                        <img
                          src={Sort}
                          className="ml-1 fa-sort"
                          alt=""
                          onClick={() => {
                            handleSort("payment.not_used_amount");
                          }}
                        />
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  {allCreditList.length > 0 ? (
                    <tbody>
                      {allCreditList.map((c, index) => {
                        const invoice_settled_ids = c.invoice_settled.map(
                          (c) => c.invoice_id
                        );

                        const invoice_settled = invoice_settled_ids.map(
                          (id) => {
                            return Util.invoiceIdFormat(id);
                          }
                        );

                        return (
                          <>
                            <tr key={index}>
                              <td>
                                {c.payment_method}
                                <div className="small-text">
                                  {t("By")}{" "}
                                  {c.User.firstName + " " + c.User.lastName}
                                </div>
                              </td>
                              <td>
                                {moment(c.date).format(
                                  Util.getMomentDateTimeFormat(
                                    school.date_format
                                  )
                                )}
                              </td>
                              <td>
                                <TagButton
                                  style={"btn btn-secondary"}
                                  size={"150px"}
                                  title={Util.getPaymentStatus(c.status)}
                                  buttonStyle={
                                    c.status == 0
                                      ? redTagStyle
                                      : c.status == 1
                                      ? orangeTagStyle
                                      : greenTagStyle
                                  }
                                  icon="fa fa-circle"
                                  iconSize="6px"
                                />
                              </td>
                              <td>
                                {Util.getListDisplay(invoice_settled, 2, ", ")}
                              </td>
                              <td>{Util.amountFormat(c.amount)}</td>
                              <td>{Util.balanceFormat(c.not_used_amount)}</td>
                              <td>
                                <div className="dropdown">
                                  <button
                                    className="btn btn-lg custom-primary "
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{
                                      color: "#ffffff",
                                      padding: "5px, 10px",
                                    }}
                                  >
                                    {t("Action")}
                                  </button>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li style={{ cursor: "pointer" }}>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => {
                                          setIsVisiblePayment(true);
                                          setPaymentId(c.id);
                                        }}
                                      >
                                        {t("View")}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tfoot>
                      <tr>
                        <td colSpan={7}>{t("Not Found!")}</td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              ) : (
                <Loader />
              )}
              <PaymentView
                show={isVisiblePayment}
                onClose={() => {
                  setIsVisiblePayment(false);
                }}
                PaymentId={paymentId}
              />
            </div>
            {totalPages > 0 && (
              <div className="row m-0 table-footer border-top mb-2 p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <div className="basic-text">{t("Students Per Page")}</div>
                    <div>
                      <select
                        name=""
                        id=""
                        className="select-dropdown custom-input"
                        value={recordPerPage}
                        onChange={(e) => {
                          setRecordPerPage(e.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <div
                      className={`link-button ${
                        currentPage == 1 ? "disabled" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviousClick();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <KeyboardArrowLeftIcon />
                      <span className="basic-bold-text">{t("Previous")}</span>
                    </div>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, page) => {
                        setCurrentPage(page);
                      }}
                      size="small"
                      hideNextButton
                      hidePrevButton
                    />
                    <div
                      className={`link-button ${
                        currentPage == totalPages ? "disabled" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextClick();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="basic-bold-text">{t("Next")}</span>
                      <KeyboardArrowRightIcon />
                    </div>
                  </Stack>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
