import React, { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import Loader from "../../../components/common/Loader";
import Util from "../../../Util";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "../../../components/common/ButtonPrimary";
import CreateBillingPlan from "./CreateBillingPlan";
import ToastHOC, { ToastComponent } from "../../HOC/ToastHOC";
import ModalComponent from "../ModalComponent";
import { Modal, Button } from "react-bootstrap";
import ModalContent from "../../../components/common/ModalContent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";

function BillPlanLibrary() {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const [billPlanList, setBillPlanList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateBillingPlan, setShowCreateBillingPlan] = useState({
    show: false,
    isEdit: false,
  });
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [editValue, setEditValue] = useState(null);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [newPlanName, setNewPlanName] = useState("");
  const [newPlanNameError, setNewPlanNameError] = useState("");
  const [item, setItem] = useState(0);

  const getBillPlanList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("billing-plans/saved")
      .then((res) => {
        setIsLoading(false);
        setBillPlanList(res.data || []);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getBillPlanList();
  }, []);

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`billing-plans/delete/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setShowDeleteConfirmation(false);
        setShowToast(true);
        if (res.data.status) {
          setToastTitle("Billing Plan Deleted Successfully");
          getBillPlanList();
        } else {
          setToastTitle(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddClose = () => {
    setShowPlanModal(false);
    setNewPlanName("");
    setNewPlanNameError("");
  };

  const returnAmount = (item) => {
    let totalPrice;
    if(item?.BillingInvoiceFee?.length > 0){
      const price = item?.BillingInvoiceFee?.reduce(
        (acc, field) => acc + Number(field.total),
        0
      );
      totalPrice = price;
    }
    console.log("price", totalPrice)
    if (item?.BillingPlanTax?.length > 0) {
      const taxFields = item.BillingPlanTax.map((billingPlanTax, index) => ({
        id: index + 1,
        taxId: billingPlanTax?.tax_id,
        taxPercentage: billingPlanTax?.Tax?.tax_percentage,
      }));
      let totalAmount;
      let taxDetails = taxFields.map((ele) => {
        const taxAmount = (ele.taxPercentage / 100) * Number(totalPrice - (item?.discount_amount || 0));
        return { taxName: ele.taxId, taxAmount };
      });
      totalAmount =
        Number(totalPrice - (item?.discount_amount || 0)) +
        taxDetails.reduce((sum, tax) => sum + tax.taxAmount, 0);
      const sum = taxDetails.reduce(
        (acc, field) => acc + Number(field.taxAmount),
        0
      );
      return totalAmount;
    } else {
      return item.amount;
    }
  };

  const HandleSaveAndClose = () => {
    const data = {
      name: newPlanName,
    };
    ApiCalling.apiCallBodyDataPost(
      `billing-plans/updateName/${editValue.id}`,
      data
    )
      .then((res) => {
        console.log(res);
        if (res.data.status === true) {
          console.log(res, "DATA");
          setIsLoading(false);
          setToastTitle(res.data.message);
          setShowPlanModal(false);
          setTimeout(() => {
            getBillPlanList();
          }, 500);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  return (
    <div>
      <div className="row pb-3">
        <div className="d-flex justify-content-end">
          <ButtonPrimary
            onClick={() => {
              setShowCreateBillingPlan({ show: true, isEdit: false });
            }}
          >
            {t("Set up a billing Plan")}
          </ButtonPrimary>
        </div>
      </div>

      <Modal
        show={showPlanModal}
        onHide={handleAddClose}
        animation={true}
        centered={true}
        dialogClassName="modal-view"
        onShow={() => {
          if (editValue !== null) {
            console.log(editValue);
            setNewPlanName(editValue.name);
          }
        }}
      >
        <div className="d-flex justify-content-between align-items center p-4 border-bottom">
          <h6>Edit Billing Plan</h6>

          <button className="hidden-btn" onClick={handleAddClose}>
            <img src={Close} className="svg-icon" height={20} width={20} />
          </button>
        </div>
        <ModalContent isLoading={isLoading}>
          <Modal.Body>
            <>
              <div className="input-filed text-start">
                <label className="basic-text required">{t("Plan Title")}</label>
                <input
                  className={`form-control custom-input ${
                    newPlanNameError.length > 0 && "input-error"
                  }`}
                  type="text"
                  value={newPlanName}
                  onChange={(event) => {
                    setNewPlanName(event.target.value);
                    setNewPlanNameError("");
                  }}
                />
                {newPlanNameError.length > 0 && (
                  <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {newPlanNameError}
                  </span>
                )}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn custom-primary" onClick={HandleSaveAndClose}>
              {t("Save")}
            </Button>
          </Modal.Footer>
        </ModalContent>
      </Modal>

      <div className="table-div">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-wrapper">
              {!isLoading ? (
                <table
                  className="table"
                  style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
                >
                  <thead>
                    <tr>
                      <th>{t("Billing Plan")}</th>
                      <th>{t("Starts/ends")}</th>
                      {/* <th>{t("Fees Type")}</th> */}
                      <th>{t("Amount")}</th>
                      <th>{t("Created On")}</th>
                      <th>{t("Total Students")}</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  {billPlanList?.length > 0 ? (
                    <tbody>
                      {billPlanList.map((a, index) => {
                        return (
                          <tr key={a.id}>
                            <td>{a.name}</td>
                            <td>
                              {moment(a.start_date).format(
                                Util.getMomentDateTimeFormat(school.date_format)
                              )}
                              {a.end_date !== null && (
                                <>
                                  -
                                  {moment(a.end_date).format(
                                    Util.getMomentDateTimeFormat(
                                      school.date_format
                                    )
                                  )}
                                </>
                              )}
                            </td>
                            {/* <td>
                              {a?.BillingInvoiceFee.length > 0 && a?.BillingInvoiceFee?.map(
                                item => item?.Fee_type?.type
                              )}
                            </td> */}
                            <td>
                              {/* {Util.isValidData(a.amount) &&
                                Util.amountFormat(a.amount)} */}
                              {returnAmount(a)}
                            </td>
                            <td>{`${moment(a.created_on).fromNow()}`}</td>
                            <td>{a.students.length}</td>
                            <td>
                              {/* {a.students.length == 0 && ( */}
                              <a
                                onClick={(e) => {
                                  setShowCreateBillingPlan({
                                    show: true,
                                    isEdit: true,
                                  });
                                  setItem(a);
                                }}
                                className={`${
                                  !Util.hasPermission("MASTERS_EDIT") &&
                                  "no-permission"
                                }`}
                              >
                                <i className="fa fa-edit"></i>
                              </a>
                              {/* )} */}
                              {/* 
                              {a.students.length > 0 && (
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEditValue(a);
                                    setShowPlanModal(true);
                                  }}
                                  className={`${
                                    !Util.hasPermission("MASTERS_EDIT") &&
                                    "no-permission"
                                  }`}
                                >
                                  <i className="fa fa-edit"></i>
                                </a>
                              )} */}
                            </td>
                            <td>
                              {a.students.length == 0 && (
                                <span
                                  className={`${
                                    !Util.hasPermission("MASTERS_DELETE") &&
                                    "no-permission"
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowDeleteConfirmation(true);
                                    setDeleteId(a.id);
                                  }}
                                >
                                  <i className="fa fa-trash-o text-danger"></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tfoot>
                      <tr>
                        <td colSpan={6}>{t("Not Found!")}</td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              ) : (
                <Loader />
              )}
            </div>
            <ModalComponent
              show={showDeleteConfirmation}
              onHide={() => {
                setShowDeleteConfirmation(false);
              }}
              title={t("Delete")}
              onCancel={t("Cancel")}
              onConfirm={t("Confirm")}
              subTitle={t("Are you sure, to delete this Billing Plan?")}
              size="sm"
              onCancelClick={() => {
                setShowDeleteConfirmation(false);
              }}
              onConfirmClick={handleDelete}
            />
          </div>
        </div>
      </div>

      <CreateBillingPlan
        show={showCreateBillingPlan}
        item={item}
        onClose={() => {
          setShowCreateBillingPlan({ show: false });
        }}
        onSave={(msg) => {
          setShowToast(true);
          setTimeout(() => {
            getBillPlanList();
          }, 1000);
          if (msg) {
            setToastTitle(msg);
          }
          setShowCreateBillingPlan({ show: false });
        }}
      />

      {showToast && (
        <ToastComponent
          showNotification={showToast}
          setShowNotification={setShowToast}
          title={toastTitle}
        />
      )}
    </div>
  );
}

export default BillPlanLibrary;
