import React from "react";
import { useEffect, useState } from "react";
import ApiCalling from "../../network/ApiCalling";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ActivityCell from "./ActivityCell";
import Select from "react-dropdown-select";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Util from "../../Util";
import AddActivityComp from "./AddActivityComp";
import ToastHOC from "../HOC/ToastHOC";
import Calender from "../../assets/Icon/calendar.svg";
import ExitAlert from "../../components/ExitAlert";
import moment from "moment";
import AddIcon from "../../assets/Icon/add post.svg";
import Loader from "../../components/common/Loader";

const Activity = (props) => {
  const { t } = useTranslation();
  const { setIsToast } = props;
  let openPopup = props.location.state?.isVisibleAddActivity ? true : false;
  const [staff, setStaff] = useState(false);
  const [isVisibleAddActivity, setIsVisibleAddActivity] = useState(false);
  const [activityById, setActivityById] = useState(null);
  const [activityList, setActivityList] = useState([]);
  const [isNoData, setIsNoData] = useState(false);
  const [classId, setClassId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [gradeList, setGradeList] = useState([]);
  const [studentId, setStudentId] = useState(0);
  const [studentList, setStudentList] = useState([]);

  const [activityCategoryList, setActivityCategoryList] = useState([]);
  const [activityCategoryId, setActivityCategoryId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [page_number, setPage_number] = useState(0);

  const [loading, setLoading] = useState(false);
  const [endOfList, setEndOfList] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadComp, setLoadCompo] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [toast, setToast] = useState("");

  // const deleteActivityId = props?.location?.deleteActivityId;

  const recordPerPage = 10;

  const ChangeDateRange = (range) => {
    if (range) {
      setCurrentPage(1);
      setActivityList([]);
      setStartDate(new Date(range[0]));
      setEndDate(new Date(range[1]));
    }
  };
  const handleCleanDate = () => {
    setCurrentPage(1);
    setActivityList([]);
    setStartDate();
    setEndDate();
  };

  const getClassList = async () => {
    setLoading(true);
    ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
      const modifiedClassList = res.data
        .filter((g) => gradeId == 0 || g.Grade.id == gradeId)
        .map((classroom) => ({
          id: classroom.id,
          name: `${classroom.name}`,
        }));
      setClassList(modifiedClassList);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const getGradeList = async () => {
    setLoading(true);
    ApiCalling.apiCallBodyDataGet("grade/list").then((res) => {
      setGradeList(res.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const getStudentList = () => {
    if (gradeId > 0 || classId > 0) {
      setLoading(true);
      let data = {
        page_size: 1000,
        page_number: 1,
        classroom_id: classId,
        grade_id: gradeId,
        str_search: "",
        active: "true",
        ascdesc: "asc",
        order_by: "",
      };
      ApiCalling.apiCallBodyDataPost("student/list", data).then((res) => {
        const modifiedStudentList = res.data.data.map((student) => ({
          id: student.id,
          name: `${student.first_name} ${student.last_name}`,
        }));
        setStudentList(modifiedStudentList);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
  };
  const groupActivitiesByDate = (activityList) => {
    const groupedActivities = {};

    activityList.sort((a, b) => {
      const momentA = moment(a.created_on, "DD/MM/YYYY");
      const momentB = moment(b.created_on, "DD/MM/YYYY");

      return momentB - momentA;
    });

    activityList.forEach((activity) => {
      const createdDate = moment(activity.created_on).format("MM/DD/YYYY");

      if (!groupedActivities[createdDate]) {
        groupedActivities[createdDate] = [];
      }

      groupedActivities[createdDate].push(activity);
    });

    return groupedActivities;
  };
  const ActivityList = () => {
    const data = {
      page_size: recordPerPage,
      page_number: currentPage,
      activity_category_id: activityCategoryId,
      startDate: startDate,
      endDate: endDate,
      grade_id: gradeId,
      room_id: classId,
      student_id: studentId,
    };
    setLoading(true);
    ApiCalling.apiCallBodyDataPost("activity/getStudentActivities", data).then(
      (res) => {
        // console.log("activity", res.data);

        if (res.data.data.length > 0) {
          // setActivityList(groupActivitiesByDate(res.data.data));
          setActivityList((prevActivityList) => ({
            ...prevActivityList,
            ...groupActivitiesByDate(res.data.data),
          }));
          setLoading(false);
          setIsNoData(false);
        } else {
          setIsNoData(true);
        }
        setTotalPages(res.data.totalPages);
        setPage_number(res.data.page_number);
        if (res.data.totalPages === res.data.page_number) {
          setEndOfList(true);
          setIsNoData(false);
        }

        setStaff(true);
      }
    );
  };

  const ActivityListAfterAdd = () => {
    const data = {
      page_size: 1000,
      page_number: 1,
      activity_category_id: activityCategoryId,
      startDate: startDate,
      endDate: endDate,
      grade_id: gradeId,
      room_id: classId,
      student_id: studentId,
    };
    setLoadCompo(true);
    ApiCalling.apiCallBodyDataPost("activity/getStudentActivities", data).then(
      (res) => {
        if (res.data.data.length > 0) {
          // setActivityList(res.data.data);
          setActivityList(groupActivitiesByDate(res.data.data));
          setLoadCompo(false);
          setIsNoData(false);
        } else {
          setIsNoData(true);
        }
        setStaff(true);
      }
    );
  };

  const getActivityCategoryList = async () => {
    setLoading(true);
    ApiCalling.apiCallParamsGet("activity-category/list").then((res) => {
      setLoading(false);
      setShowNotification(false);
      setActivityCategoryList(res.data);
    });
  };
  const changeCheck = (data) => {
    setCurrentPage(1);
    setActivityList([]);
    setActivityCategoryId(data[0].id);
  };
  const deleteActivity = (id) => {
    ApiCalling.apiCallDelete(`activity/${id}`)

      .then((res) => {
        setIsToast(t("Deleted Successfully"));
        ActivityListAfterAdd();
        // setActivityList([]);
        setIsToast(t("Deleted Successfully"));
        ActivityListAfterAdd();
        // setActivityList([]);
      })
      .catch((error) => [console.log(error)]);
  };

  const changeClassRoom = (data) => {
    setCurrentPage(1);
    setActivityList([]);
    setClassId(data[0].id);
  };

  const changeGrade = (data) => {
    setCurrentPage(1);
    setActivityList([]);
    setClassId(0);
    setGradeId(data[0].id);
  };

  const changeStudent = (data) => {
    setCurrentPage(1);
    setActivityList([]);
    setStudentId(data[0].id);
  };

  useEffect(() => {
    getActivityCategoryList();
    getClassList();
    getGradeList();
    ActivityList();
  }, []);

  useEffect(() => {
    ActivityList();
  }, [
    activityCategoryId,
    startDate,
    endDate,
    gradeId,
    classId,
    studentId,
    currentPage,
  ]);

  useEffect(() => {
    if (totalPages == currentPage) {
      setEndOfList(true);
      setIsNoData(false);
    }
  }, [currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;

      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      const windowBottom = windowHeight + window.pageYOffset;

      if (windowBottom >= docHeight - 100 && !loading && !endOfList) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, endOfList]);

  useEffect(() => {
    setIsVisibleAddActivity(openPopup);
    openPopup = !openPopup;
  }, [openPopup]);

  useEffect(() => {
    setClassId(0);
    getClassList();
  }, [gradeId]);

  useEffect(() => {
    if (classId > 0) {
      setStudentId(0);
      getStudentList();
    }
  }, [classId]);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid">
        <div className="body">
          <div className="activity-container col-md-12 p-3">
            <h2 className="page-head p-3">{t("All Activity")}</h2>
            <div className="row py-3">
              <div className="col-lg-9 ">
                <div className="d-flex">
                  <div>
                    <DateRangePicker
                      className="mr-2 custom-range-picker"
                      onChange={(range) => ChangeDateRange(range)}
                      onClean={handleCleanDate}
                      placeholder="Select Date"
                    />
                  </div>
                  <div className="mr-2">
                    <Select
                      className="select-custom "
                      options={[
                        { id: 0, title: "Category" },
                        ...activityCategoryList,
                      ]}
                      onChange={(values) => changeCheck(values)}
                      labelField="title"
                      values={[{ id: 0, title: "Category" }]}
                      valueField="id"
                    />
                  </div>
                  <div className="mr-2">
                    <Select
                      className="select-custom"
                      placeholder="Grade"
                      options={[{ id: 0, title: "Grade" }, ...gradeList]}
                      onChange={(values) => changeGrade(values)}
                      style={{ width: "100%" }}
                      labelField="title"
                      values={[{ id: 0, title: "Grade" }]}
                      valueField="id"
                    />
                  </div>
                  <div className="mr-2">
                    <Select
                      className="select-custom"
                      placeholder="Rooms"
                      options={[{ id: 0, name: "Rooms" }, ...classList]}
                      onChange={(values) => changeClassRoom(values)}
                      style={{ width: "100%" }}
                      labelField="name"
                      values={[{ id: 0, name: "Rooms" }]}
                      valueField="id"
                    />
                  </div>
                  <div className="mr-2">
                    <Select
                      className="select-custom"
                      placeholder="Student"
                      options={[{ id: 0, name: "Student" }, ...studentList]}
                      onChange={(values) => changeStudent(values)}
                      style={{ width: "100%" }}
                      labelField="name"
                      values={[{ id: 0, name: "Student" }]}
                      valueField="id"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3  offset-lg-0 offset-md-0">
                <button
                  type="button"
                  // style={{ position: "absolute", right: "0px"}}
                  onClick={() => {
                    setActivityById(null);
                    setIsVisibleAddActivity(true);
                  }}
                  className={`${
                    Util.hasPermission("ACTIVITY_ADD")
                      ? "btn primary-sm-btn float-right"
                      : "no-permission"
                  }`}
                >
                  <img src={AddIcon} height={20} width={20} />
                  {t(" Add New Activity")}
                </button>
              </div>
            </div>
            <div className="col-md-12">
              {loadComp && <div className="row pt-2">{<Loader />}</div>}

              <>
                {isNoData ? (
                  <>
                    <div className="row">
                      <div
                        className="col-lg-12"
                        style={{ paddingTop: "20%", paddingBottom: "20%" }}
                      >
                        <p className="text-center text-secondary">
                          {t("No Activity found")} :-(
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row">
                    {Object.keys(activityList).map((date, index) => (
                      <div key={index}>
                        <div className="d-flex align-items-center gap-2 mt-4 mb-3 p-0">
                          <div className="calender-picker">
                            <img
                              src={Calender}
                              alt=""
                              style={{ cursor: "pointer" }}
                              width={20}
                              height={20}
                            />
                          </div>
                          <div className="large-bold-text">
                            {moment(date, "MM/DD/YYYY").format("MMMM DD, YYYY")}
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="col-md-12">
                            {activityList[date].map((data, index) => {
                              return (
                                <div key={data.id}>
                                  <ActivityCell
                                    staff={staff}
                                    activity={data}
                                    editActivity={() => {
                                      setIsVisibleAddActivity(true);
                                      setActivityById(data.id);
                                    }}
                                    deleteActivity={() => {
                                      deleteActivity(data.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ))}
                    {loading && <Loader />}
                    {endOfList && (
                      <div className="extra-small-text align-center text-center text-muted pb-2">
                        ----{t("End")}----
                      </div>
                    )}
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
      <AddActivityComp
        show={isVisibleAddActivity}
        onClose={() => {
          setIsVisibleAddActivity(false);
          setActivityById(null);
        }}
        activityById={activityById}
        onSave={(saveMsg) => {
          setIsVisibleAddActivity(false);
          ActivityListAfterAdd();
          setIsToast(saveMsg);
          setActivityById(null);
        }}
      />
    </div>
  );
};
export default ToastHOC(Activity);
