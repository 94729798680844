import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import { useHistory } from "react-router-dom";
import ApiCalling from "../../../network/ApiCalling";
import "react-phone-number-input/style.css";
// import camera from "../../../assets/Icon/cameraIcon.svg";
import camera from "../../../assets/Icon/camera-icon.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SchoolDetailsCard from "./SchoolDetailsCard";
import ContactDetailsCard from "./ContactDetailsCard";
import DoctorDetailsCard from "./DoctorDetailsCard";
import HealthDetailsCard from "./HealthDetailsCard";
import Calander from "../../../assets/Icon/calendar.svg";
import { imageStudentList, studentIdentity } from "../../CommonImage";
import EditPhotoModal from "../EditPhotoModal";
import moment from "moment/moment";
import Loader from "../../../components/common/Loader";
import ToastHOC from "../../HOC/ToastHOC";
import Edit from "../../../assets/Icon/Edit-blue.svg";

import * as faceapi from "face-api.js";
import StudentAvtar from "../../../assets/Icon/StudentAvtar.svg";
import Invalid from "../../../assets/Icon/invalid.svg";
import ExitAlert from "../../../components/ExitAlert";

export const SeprateDot = () => {
  return <span className="sepration-dot"></span>;
};

export const ButtonMd = ({
  title,
  radius,
  className,
  width,
  height,
  onClick,
}) => {
  return (
    <button
      style={{ width: width, height: height, borderRadius: radius }}
      className={className}
      onClick={onclick}
    >
      {title}
    </button>
  );
};
const StudentDetails = (props) => {
  const queryParams = new URLSearchParams(props.props.location?.search);
  const studentIdParam = queryParams.get("studentId");
  const history = useHistory();
  const { t } = useTranslation();
  const datepickerRef = useRef(null);
  const { setIsSubToast } = props;
  const { setIsToast } = props;
  const school = Util.getSelectedSchool();
  const hasRoom = props.props.location.hasClassRoom;
  const studentId = props.props.location?.studentId || studentIdParam;

  const [birthPlaceId, setBirthPlaceId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPersonalDetailLoading, setIsPersonalDetailLoading] = useState(false);
  const [isAddressDetailLoading, setIsAddressDetailLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDate] = useState(null);

  const [age, setAge] = useState(0);
  const [countryId, setCountryId] = useState(0);
  const [BirthPlace, setBirthPlace] = useState("");
  const [RaceList, setRaceList] = useState([]);
  const [raceId, setRaceId] = useState(0);
  const [ethnicityList, setEthnicityList] = useState([]);
  const [ethnicityId, setEthnicityId] = useState(0);
  const [selectedNationality, setSelectedNationality] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [getCountryData, setCountryData] = useState([]);
  const [getAllCountry, setAllCountry] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [getStateData, setStateData] = useState([]);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [cityId, setCityId] = useState("");
  const [city, setCity] = useState();
  const [zipCode, setZipCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [selectedEthnicity, setSelectedEthnicity] = useState();
  const [isCity, setIsCity] = useState("");
  const [isState, setIsState] = useState("");

  const [personalDetailEdit, setPersonalDetailEdit] = useState(
    studentId > 0 ? false : true
  );
  const [AddressDetailEdit, setAddressDetailEdit] = useState(
    studentId > 0 ? false : true
  );
  const [healthDetailsEdit, setHealthDetailsEdit] = useState(
    studentId > 0 ? false : true
  );
  const [doctorDetailsEdit, setDoctorDetailsEdit] = useState(
    studentId > 0 ? false : true
  );
  const [schoolDetailsEdit, setSchoolDetailsEdit] = useState(
    studentId > 0 ? false : true
  );
  const [nationalityId, setNationalityId] = useState(0);

  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [pincode, setPincode] = useState("");
  const [selectedRace, setSelectedRace] = useState();
  const [selectedDob, setSelectedDob] = useState("");

  const [showEditModal, setShowEditModal] = useState(false);
  const [studentObject, setStudentObject] = useState(null);

  const [photoURL, setPhotoURL] = useState("");
  const [photo, setPhoto] = useState("");
  const [isFaceDetection, setIsFaceDetection] = useState(false);
  const [faceError, setfaceError] = useState(false);
  const [faceDetail, setFaceDetail] = useState(null);
  const [faceDetection, setFaceDetection] = useState(null);
  const canvasREF = React.useRef();
  const photoREF = React.useRef();
  const [index, setIndex] = useState(0);
  const [showAccordian, setShowAccordian] = useState(true);
  const [addressAcc, setShowAddressAcc] = useState(false);
  const [healthAcc, setShowHealthAcc] = useState(false);
  const [doctorAcc, setShowDoctorAcc] = useState(false);
  const [schoolCardAcc, setShowSchoolAcc] = useState(false);
  const [contactAcc, setShowContactAcc] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });
  const [isStateUpdated, setIsStateUpdated] = useState(false);

  const toggleDatePicker = () => {
    datepickerRef.current.setOpen(true);
  };

  const handleImage = async () => {
    const detection = await faceapi
      .detectSingleFace(photoREF.current, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceDescriptor();
    setIsFaceDetection(false);
    // let p = new faceapi.LabeledFaceDescriptors(`${firstName}`,[detection.descriptor])
    // setFaceDetail(p.toJSON());

    if (detection !== undefined) {
      setFaceDetail(detection.descriptor.toString());
      canvasREF.current.innerHtml = faceapi.createCanvasFromMedia(
        photoREF.current
      );
      faceapi.matchDimensions(canvasREF.current, {
        width: 300,
        height: 300,
      });
      const resized = faceapi.resizeResults(detection, {
        width: 300,
        height: 300,
      });
      faceapi.draw.drawDetections(canvasREF.current, resized);
    } else {
      setfaceError(
        t(
          "No Faces Detected in Selected Picture. Please upload a clear and sharp Picture."
        )
      );
    }
  };
  useEffect(() => {
    if (photo !== "") {
      setfaceError("");
      setIsFaceDetection(true);
      const loadModels = () => {
        Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
          faceapi.nets.faceExpressionNet.loadFromUri("/models"),
        ]).then((values) => {
          handleImage();
        });
      };
      photoREF && loadModels();
    }
  }, [photo]);

  const handleSavePhoto = () => {
    if (faceDetail !== null) {
      setFaceDetection(faceDetail);
    }
    const formData = new FormData();
    formData.append("id", studentId);
    if (photo) {
      formData.append("photo", photo);
    }
    ApiCalling.apiCallBodyDataPost("student/dynamic-update", formData)
      .then((res) => {
        if (res.data.status == true) {
          setIsStateUpdated(false);
          setIsSubToast(t("Photo saved successfully!"));
          setIsToast(res.data.message);
          setShowEditModal(false);
          StudentDetails();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setFaceDetail(null);
    if (e.target.files[0]) {
      setShowEditModal(true);
    }

    setfaceError("");
    setPhoto(e.target.files[0]);
    setPhotoURL(URL.createObjectURL(e.target.files[0]));
    setIsStateUpdated(true);
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className=" d-flex" onClick={onClick}>
      <input ref={ref} className="custom-input__field" value={value} readOnly />
      <div className="custom-input__icon">
        <img src={Calander} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  ));

  const StudentDetails = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("student/details/" + studentId).then(
      (res) => {
        if (Util.isValidData(res.data)) {
          setStudentObject(res.data);
          const data = res?.data;
          setIsLoading(false);
          setFirstName(data?.first_name);
          setLastName(data?.last_name);
          setBirthPlaceId(
            Util.isValidData(data?.birth_place_id) ? data?.birth_place_id : 0
          );
          setBirthPlace(data?.birth_place);
          setDate(data?.dob ? new Date(data.dob) : null);
          setSelectedDob(data?.dob);
          setSelectedEthnicity(data?.ethnicity);
          setSelectedCountryName(data?.Country?.name);
          setSelectedCityName(data?.City?.name);
          setSelectedStateName(data?.State?.name);
          setIsCity(data?.city);
          setIsState(data?.state);
          setPhotoURL(
            Util.isValidData(data?.photo) ? imageStudentList + data?.photo : ""
          );
          if (data?.dob !== null) {
            const calculatedAge = Util.calculateAge(new Date(data?.dob));
            setAge(calculatedAge);
          } else {
            setAge(0);
          }
          setEthnicityId(
            Util.isValidData(data?.ethnicity_id) ? data?.ethnicity_id : 0
          );
          setNationalityId(
            Util.isValidData(data?.nationality_id) ? data?.nationality_id : 0
          );
          setRaceId(Util.isValidData(data?.race_id) ? data?.race_id : 0);
          setSelectedRace(data?.race);
          setAddressLine1(data?.address1);
          setAddressLine2(data?.address2);
          setCityId(Util.isValidData(data?.city_id) ? data?.city_id : 0);
          setCountryId(
            Util.isValidData(data?.country_id) ? data?.country_id : 0
          );
          setSelectedNationality(data?.nationality);
          setStateId(Util.isValidData(data?.state_id) ? data?.state_id : 0);
          setPincode(data?.pincode);
        }
      }
    );
  };

  useEffect(() => {
    if (studentId > 0) {
      StudentDetails();
    }
  }, [studentId]);
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameError("");
    setIsStateUpdated(true);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameError("");
    setIsStateUpdated(true);
  };

  const changeBirthPlace = (e) => {
    setBirthPlaceId(e.target.value);
    setIsStateUpdated(true);
  };
  const changeEthnicityId = (e) => {
    e.preventDefault();
    setEthnicityId(e.target.value);
    setIsStateUpdated(true);
  };

  const changeDate = (date) => {
    setDate(date);
    const calculatedAge = Util.calculateAge(date);
    setAge(calculatedAge);
    setIsStateUpdated(true);
  };

  const racelist = () => {
    ApiCalling.apiCallBodyDataGet("race")
      .then((res) => {
        setRaceList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ethnicitylist = () => {
    ApiCalling.apiCallBodyDataGet("ethnicity")
      .then((res) => {
        setEthnicityList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const countryList = () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country").then((res) => {
      setIsCountryLoading(false);
      setCountryData(res?.data);
    });
  };

  const getAllCountryList = () => {
    ApiCalling.apiCallBodyDataGet("country/list").then((res) => {
      setAllCountry(res?.data);
    });
  };

  const cityList = (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res?.data);
    });
  };

  const handleAddressLine1Change = (e) => {
    setAddressLine1(e.target.value);
    setIsStateUpdated(true);
  };

  const handleAddressLine2Change = (e) => {
    setAddressLine2(e.target.value);
    setIsStateUpdated(true);
  };

  const resetPersonalDetails = () => {
    setPersonalDetailEdit(false);
    setFirstNameError("");
    setLastNameError("");
  };

  const resetAddressDetails = () => {
    setAddressDetailEdit(false);
    // setAddressLine1("");
    // setAddressLine2("");
    // setStateId(0);
    // setCityId(0);
    // setCountryId(0);
    // setPincode("");
    setPinCodeError("");
  };

  const handleSavePersonalDetails = (e) => {
    e.preventDefault();
    setFirstNameError("");
    setLastNameError("");
    let error = false;
    if (firstName == "") {
      setFirstNameError(t("First Name is required"));
      error = true;
    }
    if (lastName == "") {
      setLastNameError(t("Last Name is required"));
      error = true;
    }
    if (!error) {
      setIsPersonalDetailLoading(true);
      const formData = new FormData();

      if (firstName) {
        formData.append("first_name", firstName);
      }
      if (lastName) {
        formData.append("last_name", lastName);
      }
      if (birthPlaceId) {
        formData.append("birth_place_id", birthPlaceId);
      }
      if (dateOfBirth) {
        formData.append("dob", dateOfBirth);
      }
      if (raceId) {
        formData.append("race_id", raceId);
      }
      if (ethnicityId) {
        formData.append("ethnicity_id", ethnicityId);
      }
      if (nationalityId) {
        formData.append("nationality_id", nationalityId);
      }

      // if (photo) {
      //   formData.append("photo", photo);
      // }
      if (studentId > 0) {
        formData.append("id", studentId);
        ApiCalling.apiCallBodyDataPost("student/dynamic-update", formData).then(
          (res) => {
            if (res.data.status == true) {
              setIsStateUpdated(false);
              setIsPersonalDetailLoading(false);
              setIsToast(res.data.message);
              setIsSubToast(t("Personal Details Updated Successfully!"));
              setPersonalDetailEdit(false);
              StudentDetails();
            }
          }
        );
      } else {
        ApiCalling.apiCallBodyDataPost(`student/add`, formData)
          .then((res) => {
            setIsPersonalDetailLoading(false);
            if (res.data.status == true) {
              history.push({
                pathname: "/Student",
                pendingToast: `${t("Student Created Successfully")}`,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleSaveAddressEdit = (e) => {
    e.preventDefault();
    let error = false;
    setPinCodeError("");
    if (pincode && Util.Zip_regex.test(pincode) === false) {
      setPinCodeError(
        t("Please enter valid Pin-code(i.e: 12345 or 12345-2563)")
      );
      error = true;
    }
    if (!error) {
      setIsAddressDetailLoading(true);
      const data = {
        id: studentId,
        address1: addressLine1,
        address2: addressLine2,
        city: isCity,
        state: isState,
        pincode: pincode,
        ...(countryId !== 0 && { country_id: countryId }),
        ...(stateId !== 0 && { state_id: stateId }),
        ...(cityId !== 0 && { city_id: cityId }),
      };
      ApiCalling.apiCallBodyDataPost("student/dynamic-update", data)
        .then((res) => {
          if (res.data.status == true) {
            setIsStateUpdated(false);
            setIsAddressDetailLoading(false);
            setIsToast(res.data.message);
            setIsSubToast(t("Address Details Updated Successfully!"));
            setAddressDetailEdit(false);
            StudentDetails();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  useEffect(() => {
    ethnicitylist();
    racelist();
    countryList();
    getAllCountryList();
  }, []);
  useEffect(() => {
    if (countryId) {
      let isStateMounted = true;
      const stateList = (id) => {
        setIsCountryLoading(true);
        ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
          (res) => {
            if (isStateMounted) {
              setIsCountryLoading(false);
              setStateData(res?.data);
            }
          }
        );
      };
      stateList();
      return () => {
        isStateMounted = false;
      };
    }
  }, [countryId]);
  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);

  const handleGoToNextPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      setTimeout(() => {
        handleGoToNextPage(triggerExit.path);
      }, 0);
    }
    const unblock = history.block((location) => {
      if (location.pathname !== "/profileTabs" && isStateUpdated) {
        setIsAlertModal(true);
      } else {
        return true;
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToNextPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    isStateUpdated,
  ]);
  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="">
        {!isLoading ? (
          <>
            <div className="school-details my-2">
              <div className="p-4">
                <div
                  className="d-flex align-items-center gap-4 "
                  style={{ marginBottom: "24px" }}
                >
                  {photoURL.length > 0 ? (
                    <div
                      className="profile-area "
                      style={{
                        position: "relative",
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        alt="User"
                        className="rounded-circle user-photo img-responsive  mt-1 "
                        src={photoURL}
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                        }}
                      />{" "}
                      <span
                        // className="choose-profile"
                        className={`${
                          Util.hasPermission("STUDENT_UPDATE")
                            ? "choose-profile"
                            : "no-permission"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          // document.getElementById("photo").click();
                          setShowEditModal(true);
                        }}
                      >
                        <img src={camera} alt="" />
                      </span>
                    </div>
                  ) : (
                    <div
                      className="profile-area"
                      style={{
                        position: "relative",
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        alt="User"
                        className="img-responsive  mt-1"
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                          position: "relative",
                        }}
                        src={StudentAvtar}
                      />
                      <span
                        // className="choose-profile"
                        className={`${
                          Util.hasPermission("STUDENT_UPDATE")
                            ? "choose-profile"
                            : "no-permission"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          document.getElementById("photo").click();
                          // setShowEditModal(true);
                        }}
                      >
                        <img src={camera} alt="" />
                      </span>

                      <input
                        className="sr-only"
                        id="photo"
                        type="file"
                        accept="image/*"
                        onChange={handlePhotoChange}
                      />
                    </div>
                  )}
                  {Util.isValidData(studentObject) && (
                    <div>
                      {studentId > 0 && (
                        <>
                          <h2 className="extra-large-text font-color-primary">
                            {studentObject?.first_name +
                              " " +
                              studentObject?.last_name}
                          </h2>

                          <span className="d-flex flex-direction-row align-items-center justify-content-between">
                            {t("Grade")}: {studentObject?.grade?.title}{" "}
                            <SeprateDot /> {t("Class")}:{" "}
                            {studentObject?.classroom?.name} <SeprateDot />{" "}
                            {t("Age")}: {age?.years} {t("years")} {age?.months}{" "}
                            {t("months")}
                          </span>
                        </>
                      )}
                    </div>
                  )}

                  <EditPhotoModal
                    show={showEditModal}
                    onClose={() => {
                      setShowEditModal(false);
                      setPhotoURL(
                        Util.isValidData(studentObject?.photo)
                          ? imageStudentList + studentObject?.photo
                          : ""
                      );
                    }}
                    title={t("Edit Photo")}
                    subTitle={t(
                      "Show profile picture the best version of yourself"
                    )}
                    onChange={t("Change Image")}
                    onSave={t("Save Photo")}
                    Id={studentId}
                    onConfirmClick={() => setShowEditModal(false)}
                    ProfilePhoto={photoURL}
                    isFaceDetection={isFaceDetection}
                    photoURL={photoURL}
                    photoREF={photoREF}
                    canvasREF={canvasREF}
                    handlePhotoChange={handlePhotoChange}
                    handleSavePhoto={handleSavePhoto}
                    faceDetail={faceDetail}
                    faceError={faceError}
                    defaultAvtar={StudentAvtar}
                    clearFaceError={() => {
                      if (faceError) {
                        setfaceError("");
                      }
                    }}
                  />
                </div>

                <hr style={{ backgroundColor: "#E5E7EB" }} />
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="medium-large-text font-color-primary py-2">
                      {t("Personal Details")}
                    </div>
                    {studentId > 0 && (
                      <>
                        {!personalDetailEdit && (
                          <div className="d-flex justify-content-center align-items-center">
                            <span
                              className={`${
                                Util.hasPermission("STUDENT_UPDATE")
                                  ? "link-button"
                                  : "no-permission"
                              }`}
                              onClick={() => {
                                setPersonalDetailEdit(true);
                                setAddressDetailEdit(false);
                                setHealthDetailsEdit(false);
                                setDoctorDetailsEdit(false);
                                setSchoolDetailsEdit(false);
                                setShowAccordian(true);
                              }}
                            >
                              <img src={Edit} alt="" className="edit-svg " />
                              {t("Edit")}
                            </span>
                            <div className="vertical-bar"></div>

                            <div
                              className="accoridan-btn"
                              onClick={() => setShowAccordian(!showAccordian)}
                            >
                              <i
                                className={
                                  showAccordian
                                    ? "fa fa-angle-up"
                                    : "fa fa-angle-down"
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {!isPersonalDetailLoading ? (
                    <>
                      {showAccordian ? (
                        <>
                          <div className="row text-start">
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text required">
                                  {t("First Name")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(firstName)
                                      ? firstName
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      className={`form-control custom-input ${
                                        firstNameError.length > 0
                                          ? "input-error"
                                          : ""
                                      }`}
                                      value={firstName}
                                      onChange={handleFirstNameChange}
                                    />
                                    {firstNameError.length > 0 && (
                                      <span className="text-danger">
                                        {" "}
                                        <img
                                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                                          src={Invalid}
                                        />
                                        {firstNameError}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text required">
                                  {t("Last Name")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(lastName)
                                      ? lastName
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      className={`form-control custom-input ${
                                        lastNameError.length > 0
                                          ? "input-error"
                                          : ""
                                      }`}
                                      value={lastName}
                                      onChange={handleLastNameChange}
                                    />
                                    {lastNameError.length > 0 && (
                                      <span className="text-danger">
                                        <img
                                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                                          src={Invalid}
                                        />
                                        {lastNameError}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text">
                                  {t("Birth Place")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(BirthPlace)
                                      ? BirthPlace
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <select
                                      name=""
                                      id=""
                                      className="select-dropdown form-select  custom-input "
                                      value={birthPlaceId}
                                      onChange={changeBirthPlace}
                                    >
                                      <option value={0}>
                                        {t("Select Birth Place")}
                                      </option>
                                      {getAllCountry?.map((item) => (
                                        <option
                                          // placeholder="grade"
                                          key={item.id}
                                          value={item.id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                    {/* {BirthPlaceError.length > 0 && (
                          <span className="text-danger">{BirthPlaceError}</span>
                        )} */}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text">
                                  {t("Date of Birth")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(selectedDob)
                                      ? moment(selectedDob).format(
                                          Util.getMomentDateTimeFormat(
                                            school.date_format
                                          )
                                        )
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <DatePicker
                                      ref={datepickerRef}
                                      // className="datePicker"
                                      placeholderText="Select Date"
                                      selected={dateOfBirth}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      onChange={changeDate}
                                      dateFormat={school.date_format}
                                      popperPlacement="bottom"
                                      maxDate={new Date()}
                                      customInput={<CustomInput />}
                                    />
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text">{t("Race")}</span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(selectedRace)
                                      ? selectedRace
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <select
                                      name=""
                                      id=""
                                      className="select-dropdown form-select custom-input"
                                      value={raceId}
                                      onChange={(e) => {
                                        setRaceId(e.target.value);
                                        setIsStateUpdated(true);
                                      }}
                                    >
                                      <option value={0}>
                                        {t("Select Race")}
                                      </option>
                                      {RaceList?.map((item) => (
                                        <option
                                          placeholder="race"
                                          key={item.id}
                                          value={item.id}
                                        >
                                          {item.race}
                                        </option>
                                      ))}
                                    </select>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text">
                                  {t("Nationality")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(selectedNationality)
                                      ? selectedNationality
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <select
                                      name=""
                                      id=""
                                      className="select-dropdown form-select custom-input"
                                      value={nationalityId}
                                      onChange={(e) => {
                                        setNationalityId(e.target.value);
                                        setIsStateUpdated(true);
                                      }}
                                    >
                                      <option value={0}>
                                        {t("Select Nationality")}
                                      </option>
                                      {getAllCountry?.map(
                                        (item) =>
                                          item.nationality && (
                                            <option
                                              placeholder="grade"
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.nationality}
                                            </option>
                                          )
                                      )}
                                    </select>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text ">
                                  {t("Ethnicity")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(selectedEthnicity)
                                      ? selectedEthnicity
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <select
                                      name=""
                                      id=""
                                      className="select-dropdown form-select custom-input"
                                      value={ethnicityId}
                                      onChange={changeEthnicityId}
                                    >
                                      <option value={0}>
                                        {t("Select Ethnicity")}
                                      </option>
                                      {ethnicityList?.map((item) => (
                                        <option
                                          placeholder="ethnicity"
                                          key={item.id}
                                          value={item.id}
                                        >
                                          {item.ethnicity}
                                        </option>
                                      ))}
                                    </select>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {personalDetailEdit && (
                        <div className="text-end">
                          {studentId > 0 && (
                            <button
                              className="btn secondary-lg-btn rounded-btn mr-2 m-0 px-2 "
                              onClick={() => {
                                resetPersonalDetails();
                                setShowAccordian(false);
                              }}
                            >
                              {t("Cancel")}
                            </button>
                          )}
                          <button
                            className="btn primary-lg-btn rounded-btn"
                            onClick={handleSavePersonalDetails}
                          >
                            {t("Save")}
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
                {studentId > 0 && (
                  <>
                    <hr style={{ backgroundColor: "#E5E7EB" }} />
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="medium-large-text font-color-primary py-2">
                          {t("Address")}
                        </div>
                        {studentId > 0 && (
                          <>
                            {!AddressDetailEdit && (
                              <div className="d-flex justify-content-center align-items-center">
                                <span
                                  // style={{
                                  //   fontSize: "14px",
                                  //   fontWeight: "600",
                                  //   color: "#6418C3",
                                  //   cursor: "pointer",
                                  // }}
                                  className={`${
                                    Util.hasPermission("STUDENT_UPDATE")
                                      ? "link-button"
                                      : "no-permission"
                                  }`}
                                  onClick={() => {
                                    setAddressDetailEdit(true);
                                    setPersonalDetailEdit(false);
                                    setDoctorDetailsEdit(false);
                                    setHealthDetailsEdit(false);
                                    setSchoolDetailsEdit(false);
                                    setPinCodeError("");
                                    setShowAddressAcc(true);
                                  }}
                                >
                                  <img
                                    src={Edit}
                                    alt=""
                                    className="edit-svg "
                                  />
                                  {t("Edit")}
                                </span>
                                <div className="vertical-bar"></div>
                                <div
                                  className="accoridan-btn"
                                  onClick={() => setShowAddressAcc(!addressAcc)}
                                >
                                  <i
                                    className={
                                      addressAcc
                                        ? "fa fa-angle-up"
                                        : "fa fa-angle-down"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {!isAddressDetailLoading ? (
                        <>
                          {addressAcc && (
                            <div className="row text-start mt-2">
                              <div className="col-lg-6">
                                <div className="input-filed ">
                                  <span className="basic-text ">
                                    {t("Address 1")}
                                  </span>
                                  {!AddressDetailEdit ? (
                                    <div className="large-bold-text">
                                      {Util.isValidData(addressLine1)
                                        ? addressLine1
                                        : "-"}
                                    </div>
                                  ) : (
                                    <input
                                      className="form-control custom-input"
                                      value={addressLine1}
                                      onChange={handleAddressLine1Change}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="input-filed ">
                                  <span className="basic-text ">
                                    {t("Address 2")}
                                  </span>
                                  {!AddressDetailEdit ? (
                                    <div className="large-bold-text">
                                      {Util.isValidData(addressLine2)
                                        ? addressLine2
                                        : "-"}
                                    </div>
                                  ) : (
                                    <>
                                      <input
                                        className="form-control custom-input"
                                        value={addressLine2}
                                        onChange={handleAddressLine2Change}
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="input-filed ">
                                  <span className="basic-text ">
                                    {t("Country")}
                                  </span>
                                  {!AddressDetailEdit ? (
                                    <div className="large-bold-text">
                                      {Util.isValidData(selectedCountryName)
                                        ? selectedCountryName
                                        : "-"}
                                    </div>
                                  ) : (
                                    <>
                                      <select
                                        className="form-select select-dropdown custom-input"
                                        value={countryId || ""}
                                        onChange={(e) => {
                                          setCountryId(e.target.value);
                                          setIsStateUpdated(true);
                                        }}
                                      >
                                        <option disabled value="">
                                          {t("Select Country")}
                                        </option>
                                        {getCountryData.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="input-filed ">
                                  <span className="basic-text ">
                                    {t("State")}
                                  </span>
                                  {!AddressDetailEdit ? (
                                    <div className="large-bold-text">
                                      {Util.isValidData(isState)
                                        ? isState
                                        : "-"}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="form-group">
                                        {/* <select
                                          className="select-dropdown form-select custom-input"
                                          value={stateId || ""}
                                          onChange={(e) => {
                                            setStateId(e.target.value);
                                            setIsStateUpdated(true);
                                          }}
                                          disabled={!countryId}
                                        >
                                          <option value="">
                                            {isCountryLoading
                                              ? t("Loading...")
                                              : t("Select State")}
                                          </option>
                                          {getStateData?.map((item) => {
                                            return (
                                              <option
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {item?.name}
                                              </option>
                                            );
                                          })}
                                        </select> */}
                                         <input
                                        className="form-control custom-input"
                                        value={isState}
                                        onChange={(e) => {
                                          setIsState(e.target.value);
                                          setIsStateUpdated(true);
                                        }}
                                      />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="input-filed ">
                                  <span className="basic-text ">
                                    {t("City")}
                                  </span>
                                  {!AddressDetailEdit ? (
                                    <div className="large-bold-text">
                                      {Util.isValidData(isCity)
                                        ? isCity
                                        : "-"}
                                    </div>
                                  ) : (
                                    <>
                                      {/* <select
                                        className="select-dropdown form-select custom-input"
                                        value={cityId}
                                        onChange={(e) => {
                                          setCityId(e.target.value);
                                          setIsStateUpdated(true);
                                        }}
                                        disabled={!stateId}
                                      >
                                        <option value={0}>
                                          {isCountryLoading
                                            ? "Loading..."
                                            : "Select City"}
                                        </option>
                                        {city?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select> */}
                                        <input
                                        className="form-control custom-input"
                                        value={isCity}
                                        onChange={(e) => {
                                          setIsCity(e.target.value);
                                          setIsStateUpdated(true);
                                        }}
                                        />
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="input-filed ">
                                  <span className="basic-text ">
                                    {t("PIN Code")}
                                  </span>
                                  {!AddressDetailEdit ? (
                                    <div className="large-bold-text">
                                      {Util.isValidData(pincode)
                                        ? pincode
                                        : "-"}
                                    </div>
                                  ) : (
                                    <>
                                      <input
                                        className={`form-control custom-input ${
                                          pinCodeError.length > 0
                                            ? "input-error"
                                            : ""
                                        }`}
                                        value={pincode}
                                        onChange={(e) => {
                                          setPincode(e.target.value);
                                          setPinCodeError("");
                                          setIsStateUpdated(true);
                                        }}
                                      />
                                      {pinCodeError.length > 0 && (
                                        <span className="invalid-error text-danger">
                                          <img
                                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                                            src={Invalid}
                                          />
                                          {pinCodeError}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              {AddressDetailEdit && (
                                <div className="text-end">
                                  {studentId > 0 && (
                                    <button
                                      className="secondary-lg-btn rounded-btn  mr-2 m-0  px-2"
                                      onClick={() => {
                                        resetAddressDetails();
                                        setShowAddressAcc(false);
                                      }}
                                    >
                                      {t("Cancel")}
                                    </button>
                                  )}
                                  <button
                                    className="primary-lg-btn rounded-btn "
                                    onClick={handleSaveAddressEdit}
                                  >
                                    {t("Save")}
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                    <hr style={{ backgroundColor: "#E5E7EB" }} />
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="medium-large-text font-color-primary py-2">
                          {t("Health Details")}
                        </div>
                        {studentId > 0 && (
                          <>
                            {!healthDetailsEdit && (
                              <div className="d-flex justify-content-center align-items-center">
                                <span
                                  // style={{
                                  //   fontSize: "14px",
                                  //   fontWeight: "600",
                                  //   color: "#6418C3",
                                  //   cursor: "pointer",
                                  // }}
                                  className={`${
                                    Util.hasPermission("STUDENT_UPDATE")
                                      ? "link-button"
                                      : "no-permission"
                                  }`}
                                  onClick={() => {
                                    setHealthDetailsEdit(true);
                                    setPersonalDetailEdit(false);
                                    setAddressDetailEdit(false);
                                    setDoctorDetailsEdit(false);
                                    setSchoolDetailsEdit(false);
                                    setShowHealthAcc(true);
                                  }}
                                >
                                  <img
                                    src={Edit}
                                    alt=""
                                    className="edit-svg "
                                  />
                                  {t("Edit")}
                                </span>
                                <div className="vertical-bar"></div>
                                <div
                                  className="accoridan-btn"
                                  onClick={() => setShowHealthAcc(!healthAcc)}
                                >
                                  <i
                                    className={
                                      healthAcc
                                        ? "fa fa-angle-up"
                                        : "fa fa-angle-down"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {healthAcc && (
                        <HealthDetailsCard
                          healthDetailsEdit={healthDetailsEdit}
                          studentId={studentId > 0 ? studentId : "-1"}
                          data={studentObject}
                          onSave={(msg) => {
                            setIsStateUpdated(false);
                            setHealthDetailsEdit(false);
                            if (msg) {
                              setIsToast(msg);
                              setIsSubToast(
                                t("Health Details Updated Successfully!")
                              );
                            }
                            StudentDetails();
                          }}
                          onCancel={() => {
                            setHealthDetailsEdit(false);
                            setShowHealthAcc(false);
                          }}
                          isStudentUpdate={() => {
                            setIsStateUpdated(true);
                          }}
                        />
                      )}
                    </div>

                    <hr style={{ backgroundColor: "#E5E7EB" }} />
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="medium-large-text font-color-primary py-2">
                          {t("Doctor Details")}
                        </div>
                        {studentId > 0 && (
                          <>
                            {!doctorDetailsEdit && (
                              <div className="d-flex justify-content-center align-items-center">
                                <span
                                  // style={{
                                  //   fontSize: "14px",
                                  //   fontWeight: "600",
                                  //   color: "#6418C3",
                                  //   cursor: "pointer",
                                  // }}
                                  className={`${
                                    Util.hasPermission("STUDENT_UPDATE")
                                      ? "link-button"
                                      : "no-permission"
                                  }`}
                                  onClick={() => {
                                    setDoctorDetailsEdit(true);
                                    setPersonalDetailEdit(false);
                                    setAddressDetailEdit(false);
                                    setHealthDetailsEdit(false);
                                    setSchoolDetailsEdit(false);
                                    setShowDoctorAcc(true);
                                  }}
                                >
                                  <img
                                    src={Edit}
                                    alt=""
                                    className="edit-svg "
                                  />
                                  {t("Edit")}
                                </span>
                                <div className="vertical-bar"></div>
                                <div
                                  className="accoridan-btn"
                                  onClick={() => setShowDoctorAcc(!doctorAcc)}
                                >
                                  <i
                                    className={
                                      doctorAcc
                                        ? "fa fa-angle-up"
                                        : "fa fa-angle-down"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {doctorAcc && (
                        <DoctorDetailsCard
                          doctorDetailsEdit={doctorDetailsEdit}
                          studentId={studentId}
                          data={studentObject}
                          onSave={(msg) => {
                            setIsStateUpdated(false);
                            if (msg) {
                              setIsToast(msg);
                              setIsSubToast(
                                t("Doctor Details Updated Successfully!")
                              );
                            }
                            setDoctorDetailsEdit(false);
                            StudentDetails();
                          }}
                          onCancel={() => {
                            setDoctorDetailsEdit(false);
                            setShowDoctorAcc(false);
                          }}
                          isStudentUpdate={() => {
                            setIsStateUpdated(true);
                          }}
                        />
                      )}
                    </div>
                    <hr style={{ backgroundColor: "#E5E7EB" }} />

                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="medium-large-text font-color-primary py-2">
                          {t("School Details")}
                        </div>
                        {studentId > 0 && (
                          <>
                            {!schoolDetailsEdit && (
                              <div className="d-flex justify-content-center align-items-center">
                                <span
                                  // style={{
                                  //   fontSize: "14px",
                                  //   fontWeight: "600",
                                  //   color: "#6418C3",
                                  //   cursor: "pointer",
                                  // }}
                                  className={`${
                                    Util.hasPermission("STUDENT_UPDATE")
                                      ? "link-button"
                                      : "no-permission"
                                  }`}
                                  onClick={() => {
                                    setSchoolDetailsEdit(true);
                                    setAddressDetailEdit(false);
                                    setPersonalDetailEdit(false);
                                    setDoctorDetailsEdit(false);
                                    setHealthDetailsEdit(false);
                                    setShowSchoolAcc(true);
                                  }}
                                >
                                  <img
                                    src={Edit}
                                    alt=""
                                    className="edit-svg "
                                  />
                                  {t("Edit")}
                                </span>
                                <div className="vertical-bar"></div>
                                <div
                                  className="accoridan-btn"
                                  onClick={() =>
                                    setShowSchoolAcc(!schoolCardAcc)
                                  }
                                >
                                  <i
                                    className={
                                      schoolCardAcc
                                        ? "fa fa-angle-up"
                                        : "fa fa-angle-down"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {schoolCardAcc && (
                        <SchoolDetailsCard
                          schoolDetailsEdit={schoolDetailsEdit}
                          studentId={studentId > 0 ? studentId : "-1"}
                          data={studentObject}
                          onSave={(msg) => {
                            setIsStateUpdated(false);
                            if (msg) {
                              setIsToast(msg);
                              setIsSubToast(
                                t("School Details Updated Successfully!")
                              );
                            }
                            StudentDetails();
                            setSchoolDetailsEdit(false);
                          }}
                          onCancel={() => {
                            setSchoolDetailsEdit(false);
                            setShowSchoolAcc(false);
                          }}
                          isStudentUpdate={() => {
                            setIsStateUpdated(true);
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="school-details my-4 p-4">
              <ContactDetailsCard
                studentId={studentId > 0 ? studentId : "-1"}
                data={studentObject}
                notification={(msg) => {
                  if (msg) {
                    setIsToast(msg);
                    setIsSubToast(t("Contact Updated Successfully!"));
                  }
                }}
                onSave={() => {
                  StudentDetails();
                }}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <ExitAlert
        visible={alertModal}
        onClose={() => setIsAlertModal(false)}
        onOk={() => {
          setTriggerExit((obj) => ({
            ...obj,
            onOk: true,
          }));
          setIsAlertModal(false);
        }}
      />
    </div>
  );
};

export default ToastHOC(StudentDetails);
