import React from "react";
import { useState, useEffect } from "react";
import Close from "../../../assets/Icon/close.png";
import { useTranslation } from "react-i18next";
import Sort from "../../../assets/Icon/Short.svg";
import Loader from "../../../components/common/Loader";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import ModalContent from "../../../components/common/ModalContent";
import { Modal, Button } from "react-bootstrap";
import ToastHOC from "../../HOC/ToastHOC";

const PaymentInfo = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [showPaymentKeyModal, setShowPaymentKeyModal] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [privateKeyName, setPrivateKeyName] = useState("");
  const [privateKeyNameError, setPrivateKeyNameError] = useState("");
  const [publicKeyName, setPublicKeyName] = useState("");
  const [publicKeyNameError, setPublicKeyNameError] = useState("");
  const [keyList, setKeyList] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const { setIsToast } = props;

  useEffect(() => {
    const school = Util.getSelectedSchool();
    if (school) {
      setSelectedSchool(school?.id);
    }
  }, []);

  const getPublishKey = async () => {
    ApiCalling.apiCallBodyDataGet("stripe-key/get-key", selectedSchool)
      .then((res) => {
        if (res.data.status == true) {
          setKeyList([res.data]);
          console.log(res.data);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        } else {
          setKeyList([]);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPublishKey();
  }, []);

  const keyHandleShow = () => {
    setShowPaymentKeyModal(true);
    setEditValue(null);
    setPrivateKeyName("");
    setPrivateKeyNameError("");
    setPublicKeyName("");
    setPublicKeyNameError("");
  };

  const handleAddClose = () => {
    setShowPaymentKeyModal(false);
    setPrivateKeyName("");
    setPrivateKeyNameError("");
    setPublicKeyName("");
    setPublicKeyNameError("");
  };

  const keyHandleSaveAndClose = () => {
    setPublicKeyNameError("");
    setPrivateKeyNameError("");
    let error = false;
    if (privateKeyName == "") {
      setPrivateKeyNameError(t("Private Key is required"));
      error = true;
    }
    if (publicKeyName == "") {
      setPublicKeyNameError(t("Public Key is required"));
      error = true;
    }
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      const data = {
        public: btoa(publicKeyName),
        private: btoa(privateKeyName),
      };
      if (editValue !== null) {
        ApiCalling.apiCallBodyDataPost(
          "stripe-key/update",
          data,
          selectedSchool
        )
          .then((res) => {
            console.log(res);
            if (res.data.status === true) {
              setIsLoading(false);
              setIsToast(res.data.message);
              setShowPaymentKeyModal(false);
              getPublishKey();
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      } else {
        ApiCalling.apiCallBodyDataPost("stripe-key/add", data, selectedSchool)
          .then((res) => {
            console.log(res);
            if (res.data.status === true) {
              setIsLoading(false);
              setIsToast(res.data.message);
              setShowPaymentKeyModal(false);
              getPublishKey();
              setKeyList([]);
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
    }
  };

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`stripe-key/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setIsToast(t("Stripe key Deleted Successfully"));
        setShowDeleteConfirmation(false);
        getPublishKey();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="header text-right mb-3 px-0">
              {keyList.length === 0 && (
                <button
                  className={`${
                    Util.hasPermission("MASTERS_ADD")
                      ? "btn custom-primary"
                      : "no-permission"
                  }`}
                  type="button"
                  aria-expanded="false"
                  style={{ color: "#fff", padding: "5px, 10px" }}
                  onClick={keyHandleShow}
                >
                  {t("Register new Stripe Key")}
                </button>
              )}

              <Modal
                show={showPaymentKeyModal}
                onHide={handleAddClose}
                animation={true}
                centered={true}
                dialogClassName="modal-view"
                onShow={() => {
                  setIsClickedOnce(false);
                  if (editValue !== null) {
                    // setPrivateKeyName(
                    //   atob(decodeURIComponent(editValue.private_key))
                    // );
                    // setPublicKeyName(
                    //   atob(decodeURIComponent(editValue.public_key))
                    // );
                    setPrivateKeyName(editValue.private_key);
                    setPublicKeyName(editValue.public_key);
                  }
                }}
              >
                <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                  <h6>
                    {editValue !== null
                      ? t("Edit Stripe Key")
                      : t("Register Stripe Key")}
                  </h6>

                  <button className="hidden-btn" onClick={handleAddClose}>
                    <img
                      src={Close}
                      className="svg-icon"
                      height={20}
                      width={20}
                    />
                  </button>
                </div>
                <ModalContent isLoading={isLoading}>
                  <Modal.Body>
                    <>
                      <div className="input-filed text-start">
                        <label className="basic-text required">
                          {t("Private Stripe Key")}
                        </label>
                        <input
                          className={`form-control custom-input ${
                            privateKeyNameError.length > 0 && "input-error"
                          }`}
                          type="text"
                          value={privateKeyName}
                          onChange={(event) => {
                            setPrivateKeyName(event.target.value);
                            setPrivateKeyNameError("");
                          }}
                        />
                        {privateKeyNameError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {privateKeyNameError}
                          </span>
                        )}
                      </div>

                      <div className="input-filed text-start">
                        <label className="basic-text required">
                          {t("Public Stripe Key")}
                        </label>
                        <input
                          className={`form-control custom-input ${
                            publicKeyNameError.length > 0 && "input-error"
                          }`}
                          type="text"
                          value={publicKeyName}
                          onChange={(event) => {
                            setPublicKeyName(event.target.value);
                            setPublicKeyNameError("");
                          }}
                        />
                        {publicKeyNameError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {publicKeyNameError}
                          </span>
                        )}
                      </div>
                    </>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn custom-primary"
                      onClick={keyHandleSaveAndClose}
                    >
                      {t("Save")}
                    </Button>
                  </Modal.Footer>
                </ModalContent>
              </Modal>
            </div>
          </div>
          <div className="row clearfix ">
            <div className="table-div overflow-hidden  p-0">
              <div className="col-lg-12 col-md-12 p-0 m-0 ">
                <div className="body">
                  {!isLoading ? (
                    <>
                      <div style={{ overflowX: "scroll" }}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th scope="col">
                                {t("Public Key")}{" "}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  // onClick={() => {
                                  //   handleSort("Description");
                                  // }}
                                />
                              </th>
                              <th scope="col">
                                {t("Private Key")}{" "}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                />
                              </th>
                              <th scope="col"></th>
                              {/* <th scope="col"></th> */}
                            </tr>
                          </thead>
                          {keyList.length > 0 ? (
                            <>
                              <tbody>
                                {keyList.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {/* {atob(
                                        decodeURIComponent(item?.public_key)
                                      )
                                        ?.slice(-4)
                                        .padStart(
                                          atob(
                                            decodeURIComponent(item?.public_key)
                                          )?.length,
                                          "*"
                                        )} */}
                                        {item?.public_key}
                                    </td>
                                    <td>
                                      {/* {atob(
                                        decodeURIComponent(item?.private_key)
                                      )
                                        ?.slice(-4)
                                        .padStart(
                                          atob(
                                            decodeURIComponent(item?.public_key)
                                          )?.length,
                                          "*"
                                        )} */}
                                        {item?.private_key}
                                    </td>
                                    {/* <td className="text-right">
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setEditValue(item);
                                          setShowPaymentKeyModal(true);
                                        }}
                                        className={`${
                                          !Util.hasPermission("MASTERS_EDIT") &&
                                          "no-permission"
                                        }`}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </a>
                                    </td>
                                    <td>
                                      <span
                                        className={`${
                                          !Util.hasPermission(
                                            "MASTERS_DELETE"
                                          ) && "no-permission"
                                        }`}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setShowDeleteConfirmation(true);
                                          setDeleteId(item.id);
                                        }}
                                      >
                                        <i className="fa fa-trash-o text-danger"></i>
                                      </span>
                                    </td> */}
                                    <td>
                                      <button
                                        className={`${
                                          !Util.hasPermission(
                                            "MASTERS_DELETE"
                                          ) && "no-permission"
                                        }`}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setShowDeleteConfirmation(true);
                                          setDeleteId(item.id);
                                        }}
                                      >
                                        Revoke
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          ) : (
                            <>
                              <tfoot>
                                <tr>
                                  <td colSpan={7}>
                                    {t("There are no Stripe Keys")}
                                  </td>
                                </tr>
                              </tfoot>
                            </>
                          )}
                        </table>
                      </div>
                      {keyList.length > 0 && (
                        <div className="text-muted pl-3 mb-3 border-top py-3">
                          <i>
                            {keyList.length} {t("Key found")}.
                          </i>
                        </div>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
                <ModalComponent
                  show={showDeleteConfirmation}
                  onHide={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  title={t("Revoke")}
                  onCancel={t("Cancel")}
                  onConfirm={t("Confirm")}
                  subTitle={t("Are you sure, to Revoke this Stripe Key")}
                  size="sm"
                  onCancelClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  onConfirmClick={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToastHOC(PaymentInfo);
