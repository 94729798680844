import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Sort from "../../../assets/Icon/Short.svg";
import { Modal, Button } from "react-bootstrap";
import Calender from "../../../assets/Icon/calendar.svg";
import ApiCalling from "../../../network/ApiCalling";
import ToastHOC from "../../HOC/ToastHOC";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";
import Util from "../../../Util";
import ModalContent from "../../../components/common/ModalContent";
import moment from "moment";
import DatePicker from "react-datepicker";

const Terms = (props) => {
  const { setIsToast } = props;
  const { t } = useTranslation();
  const datepickerRef = useRef(null);
  const [showTerms, setShowTerms] = useState(false);
  const [term, setTerm] = useState("");
  const [termError, setTermError] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [termList, setTermList] = useState([]);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [sortColumn, setSortColumn] = useState("Terms");
  const [sortDirection, setSortDirection] = useState("asc");
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [endDateError, setEndDateError] = useState("");
  const school = Util.getSelectedSchool();

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedTermList = [...termList].sort((a, b) => {
    if (sortColumn === "Terms") {
      return sortDirection === "asc"
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    }
    return 0;
  });

  const handleAddTerms = () => {
    setShowTerms(true);
    setEditValue(null);
    setTerm("");
    setTermError("");
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setStartDateError("");
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setEndDateError("");
  };

  const handleClose = () => {
    setShowTerms(false);
    setTerm("");
    setTermError("");
  };

  const CustomInput = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className={`custom-input__field ${
          startDateError.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  ));

  const CustomInput1 = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className={`custom-input__field ${
          endDateError.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  ));

  const handleSaveAndClose = () => {
    setTermError("");
    let error = false;
    if (term === "") {
      setTermError(t("Term is required"));
      error = true;
    }
    if (startDate == null) {
      setStartDateError(t("Start Date is required"));
      error = true;
    }
    if (endDate == null) {
      setEndDateError(t("End Date is required"));
      error = true;
    }

    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      const start_date = moment(startDate).format("YYYY-MM-DD");
      const end_date = moment(endDate).format("YYYY-MM-DD");
      if (editValue !== null) {
        const data = {
          id: editValue.id,
          title: term,
          start_date: start_date,
          end_date: end_date,
        };

        ApiCalling.apiCallBodyDataPost("terms/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setShowTerms(false);
              setIsLoading(false);
              setIsToast(res.data.message);

              getTermList();
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      } else {
        const data = {
          title: term,
          start_date: start_date,
          end_date: end_date,
        };
        ApiCalling.apiCallBodyDataPost("terms/create", data)
          .then((res) => {
            if (res.data.status === true) {
              setShowTerms(false);
              setIsLoading(false);
              setIsToast(res.data.message);

              getTermList();
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      }
    }
  };

  const getTermList = () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("terms/school")
      .then((res) => {
        setTermList(res.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`terms/delete/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setIsToast(t("Terms Deleted Successfully"));
        setShowDeleteConfirmation(false);
        getTermList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTermList();
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="header text-right px-0 mb-2">
            <button
              className={`${
                Util.hasPermission("MASTERS_ADD")
                  ? "btn custom-primary"
                  : "no-permission"
              }`}
              type="button"
              aria-expanded="false"
              style={{ color: "#fff", padding: "5px, 10px" }}
              onClick={handleAddTerms}
            >
              {t("Add Terms")}
            </button>
          </div>
        </div>

        <Modal
          show={showTerms}
          onHide={handleClose}
          animation={true}
          centered={true}
          dialogClassName="modal-view"
          onShow={() => {
            setIsClickedOnce(false);
            if (editValue !== null) {
              setTerm(editValue.title);
            }
          }}
        >
          <div className="d-flex justify-content-between align-items center p-4 border-bottom">
            <h6>{editValue !== null ? t("Edit Terms ") : t("Add Terms ")}</h6>

            <button className="hidden-btn" onClick={handleClose}>
              <img src={Close} className="svg-icon" height={20} width={20} />
            </button>
          </div>
          <ModalContent isLoading={isLoading}>
            <Modal.Body>
              <div className="form-group text-start">
                <label className="basic-text required">{t("Terms")}</label>
                <input
                  className={`form-control ${
                    termError.length > 0 ? "input-error" : "custom-input"
                  }`}
                  placeholder={t("Term")}
                  type="text"
                  value={term}
                  onChange={(event) => {
                    setTerm(event.target.value);
                    setTermError("");
                  }}
                />
                {termError.length > 0 && (
                  <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {termError}
                  </span>
                )}

                <div className="form-group text-start mt-3">
                  <label className="basic-text required">
                    {t("Start Date")}
                  </label>
                  <DatePicker
                    ref={datepickerRef}
                    selected={startDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={handleStartDate}
                    dateFormat={school.date_format}
                    popperPlacement="top"
                    customInput={<CustomInput icon={Calender} />}
                  />
                  {startDateError.length > 0 && (
                    <span className="text-danger invalid-error">
                      <img
                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                        src={Invalid}
                      />
                      {startDateError}
                    </span>
                  )}
                </div>

                <div className="form-group text-start mt-3">
                  <label className="basic-text required">{t("End Date")}</label>
                  <DatePicker
                    ref={datepickerRef}
                    selected={endDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={handleEndDate}
                    dateFormat={school.date_format}
                    popperPlacement="top"
                    customInput={<CustomInput1 icon={Calender} />}
                  />
                  {endDateError.length > 0 && (
                    <span className="text-danger invalid-error">
                      <img
                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                        src={Invalid}
                      />
                      {endDateError}
                    </span>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn custom-primary"
                onClick={handleSaveAndClose}
              >
                {t("Save")}
              </Button>
            </Modal.Footer>
          </ModalContent>
        </Modal>

        <div className="row clearfix ">
          <div className="table-div overflow-hidden mt-2 p-0">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="body">
                <>
                  {!isLoading ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">
                            {t("Terms")}{" "}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("Terms");
                              }}
                            />
                          </th>
                          <th scope="col">Start Date</th>
                          <th scope="col">End Date</th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      {termList.length > 0 ? (
                        <tbody>
                          {sortedTermList.map((t, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{t.title}</td>
                                <td>
                                {t.start_date !== null && (
                                <>
                                  {moment(t.state_date).format(
                                    Util.getMomentDateTimeFormat(
                                      school.date_format
                                    )
                                  )}
                                </>
                              )}
                                </td>
                                <td>
                                {t.end_date !== null && (
                                <>
                                  {moment(t.end_date).format(
                                    Util.getMomentDateTimeFormat(
                                      school.date_format
                                    )
                                  )}
                                </>
                              )}
                                </td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditValue(t);
                                      setShowTerms(true);
                                    }}
                                    className={`${
                                      !Util.hasPermission("MASTERS_EDIT") &&
                                      "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </a>
                                </td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowDeleteConfirmation(true);
                                      setDeleteId(t.id);
                                    }}
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? "pl-5"
                                        : "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-trash-o text-danger"></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tfoot>
                          <tr>
                            <td colSpan={4}>
                              {t(
                                "There are no Terms. Click the 'Add Terms ' button to create one."
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  ) : (
                    <Loader />
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        show={showDeleteConfirmation}
        onHide={() => {
          setShowDeleteConfirmation(false);
        }}
        title={t("Delete")}
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        subTitle={t("Are you sure, to delete this Term?")}
        size="sm"
        onCancelClick={() => {
          setShowDeleteConfirmation(false);
        }}
        onConfirmClick={handleDelete}
      />
    </div>
  );
};

export default ToastHOC(Terms);
