import React, { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import close from "../../../assets/Icon/close.svg";
import Search from "../../../assets/Icon/Search.svg";
import Select from "react-dropdown-select";
import Loader from "../../../components/common/Loader";
import Sort from "../../../assets/Icon/Short.svg";
import { imageStudentList } from "../../CommonImage";
import { Avatar } from "@mui/material";
import { Modal } from "react-bootstrap";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle } from "../TagButtonStyle";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Pagination from "@mui/material/Pagination";
import ToastHOC from "../../HOC/ToastHOC";
import ModalComponent from "../ModalComponent";

const Parent = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { setIsToast } = props;
  const pendingToast = props.location.pendingToast;
  const getLanguage = Util.getSelectedLanguage();
  const [role, setRole] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasClassRoom, setHasClassRoom] = useState();
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [active, setActive] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [parentList, setParentList] = useState([]);
  const [totalParents, setTotalParents] = useState(0);
  const [classId, setClassId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [gradeList, setGradeList] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState([]);
  const [invitationPopup, setInvitationPopup] = useState(false);
  const [invitationMailNote, setInvitationMailNote] = useState("");
  const [invitationMailNoteError, setInvitationMailNoteError] = useState("");
  const [studentDetails, setStudentDetails] = useState({});
  const [selectedMultipleStatus, setSelectedMultipleStatus] = useState(null);
  const [openStatusPopup, setOpenStatusPopup] = useState(false);

  const statusList = [
    { value: "all", title: "All" },
    { value: "true", title: "Active" },
    { value: "false", title: "Inactive" },
  ];
  const multipleStatusList = [
    { value: "true", title: "Change to Active" },
    { value: "false", title: "Change to InActive" },
  ];

  const RoleList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("role/list")
      .then((res) => {
        setRole(res.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getClassList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
      const modifiedClassList = res?.data
        .filter((g) => gradeId == 0 || g.Grade.id == gradeId)
        .map((grade) => ({
          id: grade.id,
          name: `${grade.Grade.title}-${grade.name}`,
        }));
      setClassList(modifiedClassList);
      setHasClassRoom(res.data.length);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  const getGradeList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("grade/list").then((res) => {
      setGradeList(res?.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  const changeSelectedMultipleStatus = (data) => {
    setSelectedMultipleStatus(data[0].value);
    setOpenStatusPopup(true);
  };

  const getParent = () => {
    if (search) {
      const queryParams = new URLSearchParams();
      queryParams.set("search", search);
      const currentUrl = window.location.pathname; // Get the current URL without query parameters
      const newUrl = `${currentUrl}?${queryParams.toString()}`;

      history.push(newUrl); // Update the URL without page reload
    }

    let data = {
      page_size: recordPerPage,
      page_number: currentPage,
      classroom_id: classId,
      grade_id: gradeId,
      str_search: searchClick,
      active: active,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("parent/list", data).then((res) => {
      setParentList(res?.data?.data);
      // setFilteredStudentList(res?.data?.data);
      setTotalPages(res.data.totalPages);
      setTotalParents(res.data.total);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    getParent();
  }, [
    sortBy,
    sortOrder,
    recordPerPage,
    classId,
    gradeId,
    searchClick,
    currentPage,
    active,
  ]);

  useEffect(() => {
    setClassId(0);
    getClassList();
  }, [gradeId]);

  useEffect(() => {
    getGradeList();
    getClassList();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.get("search");
    if (searchParam) {
      setSearch(searchParam);
      setSearchClick(searchParam);
    }
  }, [location.search]);

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const changeClassRoom = (data) => {
    setCurrentPage(1);
    setClassId(data[0].id || 0);
  };

  const changeGrade = (data) => {
    setCurrentPage(1);
    setGradeId(data[0].id);
    setClassId(0);
  };

  const changeStatus = (data) => {
    setCurrentPage(1);
    setActive(data[0].value);
  };

  const handleAllSelectedParent = (e) => {
    if (e.target.checked) {
      setSelectedParentId(parentList.map((item) => item?.User?.id));
    } else {
      setSelectedParentId([]);
    }
  };

  const ParentChecked = (event) => {
    const id = Number(event.target.value);
    if (event.target.checked) {
      setSelectedParentId([...selectedParentId, id]);
    } else {
      const data = selectedParentId.filter((s) => s !== id);
      setSelectedParentId(data);
    }
  };

  const searchClicked = () => {
    if (search.length > 0) {
      setSearch("");
      setSearchClick("");
    } else {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };
  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };
  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const openInvitationNotePopup = () => {
    setInvitationPopup(true);
  };

  const invitationSent = () => {
    setInvitationPopup(false);
    setSelectedParentId([]);
    setInvitationMailNote("");
    setInvitationMailNoteError("");
  };

  const inviteAllSelected = () => {
    if (invitationMailNote === "") {
      setInvitationMailNoteError(t("Please enter Note"));
      return;
    }
    const selectedParents = parentList.filter((parent) =>
      selectedParentId.includes(parent.User.id)
    );
    const selectedParentIds = selectedParents.map((parent) => parent.id);
    const data = {
      note: invitationMailNote,
      parentIds: selectedParentIds,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("user/send-parent-invitation", data)
      .then((res) => {
        if (res.data.success === true) {
          setIsToast(t("Invitation(s) Sent Successfully"));
          setInvitationPopup(false);
          setSelectedParentId([]);
        } else if (res.data.success === false) {
          setIsToast(res.data.message);
          setInvitationPopup(false);
          setSelectedParentId([]);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStudentDetails = (studentId) => {
    ApiCalling.apiCallBodyDataGet("student/details/" + studentId)
      .then((res) => {
        if (Util.isValidData(res.data)) {
          setStudentDetails(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleConfirmStatus = () => {
    const students = selectedParentId.join(",");

    const data = {
      user_ids: students,
      status: selectedMultipleStatus,
    };
    ApiCalling.apiCallBodyDataPost("user/update-user-status", data)
      .then((res) => {
        if (res.data.status == true) {
          setIsToast(res.data.message);
          setOpenStatusPopup(false);
          setSelectedParentId([]);
          setSelectedMultipleStatus();
          getParent();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid">
        <div className="body">
          <div className="col-lg col-md-12 p-3">
            <div className="row py-3 pb-4">
              <div className="col-md-4 text-start">
                <h2 className="page-head">{t("Parents")}</h2>
              </div>
              <div className="col-md-8 text-end">
                <div className={"rows-selected dropdown-height me-3"}>
                  {selectedParentId.length > 0 && (
                    <button
                      className="btn secondary-sm-btn"
                      onClick={openInvitationNotePopup}
                    >
                      {t("Send Invite")}
                    </button>
                  )}
                  {selectedParentId.length > 0 && (
                    <Select
                      className="select-custom"
                      options={[...multipleStatusList]}
                      placeholder="Change User Status"
                      onChange={(values) =>
                        changeSelectedMultipleStatus(values)
                      }
                      searchable={false}
                      style={{ width: "100%" }}
                      labelField="title"
                      values={[
                        {
                          value: "",
                          title: "Change Status",
                        },
                      ]}
                      valueField="value"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={`${getLanguage !== "ar" && "text-end"}`}>
              <div className={"table-div"}>
                <div className="d-flex gap-20 align-items-center table-sort-bar">
                  <div className="custom-search-wrap">
                    <div className="input-group">
                      <input
                        className="form-control search "
                        placeholder={t("Search Parent / Student")}
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyPress={(e) => EnterHandler(e)}
                      />
                      <div className="input-group-prepend">
                        <div className="search-icon">
                          <img
                            src={search.length > 0 ? close : Search}
                            className="svg-icon"
                            alt=""
                            onClick={searchClicked}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mr-2 ">
                    <Select
                      searchable={false}
                      className="select-custom text-nowrap"
                      options={[...statusList]}
                      onChange={(values) => changeStatus(values)}
                      style={{ width: "100%" }}
                      labelField="title"
                      values={[{ value: "all", title: "Status" }]}
                      valueField="value"
                    />
                  </div>

                  <div className="mr-2">
                    <Select
                      searchable={false}
                      className="select-custom text-nowrap"
                      placeholder="Grade"
                      options={[{ id: 0, title: "All" }, ...gradeList]}
                      onChange={(values) => changeGrade(values)}
                      style={{ width: "100%" }}
                      labelField="title"
                      values={[{ id: 0, title: "Grade" }]}
                      valueField="id"
                    />
                  </div>

                  <div className="mr-2">
                    <Select
                      searchable={false}
                      className="select-custom text-nowrap"
                      placeholder="Rooms"
                      options={[{ id: 0, name: "All" }, ...classList]}
                      onChange={(values) => changeClassRoom(values)}
                      style={{ width: "100%" }}
                      labelField="name"
                      values={[{ id: 0, name: "Rooms" }]}
                      valueField="id"
                    />
                  </div>
                </div>

                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="table-wrapper">
                        <table className="table">
                          <thead>
                            <tr className="text-nowrap">
                              <th
                                scope="col"
                                className="check-col"
                                style={{
                                  paddingRight: "0px",
                                  width: 0,
                                }}
                              >
                                <label className="fancy-checkbox element-left">
                                  <input
                                    type="checkbox"
                                    name="allselect"
                                    onChange={handleAllSelectedParent}
                                    checked={
                                      parentList.length > 0 &&
                                      parentList.length ===
                                        selectedParentId.length
                                    }
                                  />
                                  <span></span>
                                </label>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  paddingLeft: "0px",
                                  width: 0,
                                }}
                              >
                                {t("Name")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("user.firstName");
                                  }}
                                />
                              </th>
                              {/* <th scope="col"> {t("Primary Contact")} </th> */}
                              <th scope="col">
                                {t("Child Name")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("student.first_name");
                                  }}
                                />
                              </th>
                              <th scope="col">
                                {t("Relation")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("studentParentRelation.type");
                                  }}
                                />
                              </th>
                              <th scope="col">
                                {t("Phone Number")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("user.mobile");
                                  }}
                                />
                              </th>
                              <th scope="col">
                                {t("Email")}{" "}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("user.email");
                                  }}
                                />
                              </th>
                              <th scope="col">
                                {t("Registration Status")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("user.email_verified");
                                  }}
                                />
                              </th>
                              <th>
                              {t("User Status")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("user.is_active");
                                  }}
                                />
                              </th>
                            </tr>
                          </thead>

                          {parentList.length > 0 ? (
                            <tbody>
                              {parentList.map((item) => {
                                return (
                                  <tr
                                    key={item.id}
                                    style={{
                                      backgroundColor: `${
                                        selectedParentId.includes(item.id)
                                          ? "rgba(100, 24, 195, 0.04)"
                                          : ""
                                      }`,
                                    }}
                                  >
                                    <td
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      <label className="fancy-checkbox element-left">
                                        <input
                                          type="checkbox"
                                          name={item?.User?.firstName}
                                          onChange={ParentChecked}
                                          value={item?.User?.id}
                                          checked={selectedParentId.includes(
                                            item?.User?.id
                                          )}
                                        />
                                        <span></span>
                                      </label>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: "0px",
                                        width: 0,
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        {item?.User?.Avatar ? (
                                          <img
                                            className="rounded-circle "
                                            style={{
                                              width: "40px",
                                              height: "40px",
                                            }}
                                            src={
                                              imageStudentList +
                                              item?.User?.avatar
                                            }
                                            alt=""
                                          />
                                        ) : (
                                          <Avatar
                                            aria-label="recipe"
                                            className="avatar-font"
                                          >
                                            {item?.User?.firstName?.toUpperCase()[0] +
                                              item?.User?.lastName?.toUpperCase()[0]}
                                          </Avatar>
                                        )}
                                        <a
                                          className="ml-3"
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            const queryParams =
                                              new URLSearchParams();
                                            queryParams.set(
                                              "parentId",
                                              item?.User?.id
                                            );

                                            // Construct the URL with the query parameter
                                            const newUrl = `/profileTabs?${queryParams.toString()}`;

                                            e.preventDefault();
                                            history.push({
                                              pathname: "/parentContact",
                                              studentId: item?.Student?.id,
                                              data: studentDetails,
                                              id: item?.id,
                                              UserId: item?.User?.id,
                                              search: "Parent",
                                            });
                                          }}
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item?.User?.firstName}{" "}
                                          {item?.User?.lastName}
                                        </a>
                                      </div>
                                    </td>
                                    <td>
                                      {item?.Student?.first_name +
                                        " " +
                                        item?.Student?.last_name}
                                    </td>
                                    <td>
                                      {item?.StudentParentRelationType?.type}
                                    </td>
                                    <td>{item?.User?.mobile}</td>
                                    <td>{item?.User?.email}</td>

                                    <td>
                                      <TagButton
                                        size={"100px"}
                                        title={
                                          item?.User?.email_verified
                                            ? "Active"
                                            : "InActive"
                                        }
                                        buttonStyle={
                                          item?.User?.email_verified
                                            ? greenTagStyle
                                            : orangeTagStyle
                                        }
                                        icon="fa fa-circle"
                                        iconSize="6px"
                                      />
                                    </td>
                                    <td>
                                    <TagButton
                                        size={"100px"}
                                        title={
                                          item?.User?.is_active
                                            ? "Active"
                                            : "InActive"
                                        }
                                        buttonStyle={
                                          item?.User?.is_active
                                            ? greenTagStyle
                                            : orangeTagStyle
                                        }
                                        icon="fa fa-circle"
                                        iconSize="6px"
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          ) : (
                            <tfoot>
                              <tr>
                                <td colSpan={8}>
                                  {t(
                                    "No Students Found! Please recheck the filter or Create One"
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          )}
                        </table>
                      </div>
                      {totalPages > 0 && (
                        <div className="row m-0 table-footer border-top">
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ height: "72px" }}
                          >
                            <div className="d-flex align-items-center gap-2">
                              <div className="basic-text">
                                {t("Students Per Page")}
                              </div>
                              <select
                                style={{ width: "72px", cursor: "pointer" }}
                                className="select-dropdown custom-input"
                                value={recordPerPage}
                                onChange={(e) => {
                                  setRecordPerPage(e.target.value);
                                }}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>

                            <Stack
                              spacing={2}
                              direction="row"
                              alignItems="center"
                            >
                              <div
                                className={`link-button ${
                                  currentPage == 1 ? "disabled" : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handlePreviousClick();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <KeyboardArrowLeftIcon />
                                <span className="basic-bold-text">
                                  {t("Previous")}
                                </span>
                              </div>
                              <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={(event, page) => {
                                  setCurrentPage(page);
                                }}
                                size="small"
                                hideNextButton
                                hidePrevButton
                              />
                              <div
                                className={`link-button ${
                                  currentPage == totalPages ? "disabled" : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleNextClick();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="basic-bold-text">
                                  {t("Next")}
                                </span>
                                <KeyboardArrowRightIcon />
                              </div>
                            </Stack>
                          </div>
                        </div>
                      )}
                    </div>
                    <Modal
                      show={invitationPopup}
                      onHide={invitationSent}
                      animation={true}
                      centered={true}
                      className="modal-view"
                    >
                      <div className="d-flex justify-content-between align-items center p-4 border-0">
                        <h6>{t("Add Note")}</h6>

                        <button className="hidden-btn" onClick={invitationSent}>
                          <img
                            src={Close}
                            className="svg-icon"
                            height={20}
                            width={20}
                          />
                        </button>
                      </div>
                      <Modal.Body>
                        <div className="input-filed">
                          <span className="basic-text required">
                            {t("Note")}
                          </span>
                          <textarea
                            style={{ width: "100%", minHeight: "120px" }}
                            className={`form-control ${
                              invitationMailNoteError.length > 0
                                ? "input-error"
                                : "custom-input"
                            }`}
                            type="text"
                            placeholder={t("Enter Note")}
                            value={invitationMailNote}
                            onChange={(event) => {
                              setInvitationMailNote(event.target.value);
                              setInvitationMailNoteError("");
                            }}
                          />
                          {invitationMailNoteError.length > 0 && (
                            <span className="invalid-error">
                              {" "}
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {invitationMailNoteError}
                            </span>
                          )}
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="border-0">
                        <button
                          className="btn btn-lg custom-primary"
                          onClick={inviteAllSelected}
                        >
                          {t("Invite All Selected User")} (
                          {selectedParentId.length})
                        </button>
                      </Modal.Footer>
                    </Modal>
                    <ModalComponent
                      show={openStatusPopup}
                      onHide={() => {
                        setOpenStatusPopup(false);
                        setSelectedParentId([]);
                        setSelectedMultipleStatus();
                      }}
                      title={t("Change status")}
                      subTitle={t(
                        "Are you sure want to change status"
                      )}
                      // size="sm"
                      onCancel={t("Cancel")}
                      onConfirm={t("Confirm")}
                      onCancelClick={() => {
                        setOpenStatusPopup(false);
                        setSelectedParentId([]);
                        setSelectedMultipleStatus();
                      }}
                      onConfirmClick={handleConfirmStatus}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(Parent);
