import React, { Fragment, useEffect, useState } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import TagButton from "../../Dashbord/TagButton";
import {
  greenTagStyle,
  orangeTagStyle,
  redTagStyle,
} from "../../Dashbord/TagButtonStyle";
import { imageStudentList } from "../../CommonImage";
import ApiCalling from "../../../network/ApiCalling";
import Loader from "../../../components/common/Loader";
import { InvoiceStatus } from "../../../Enums";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Close from "../../../assets/Icon/close.png";

export default function InvoiceView({ show, onClose, invoiceId }) {
  const getLanguage = Util.getSelectedLanguage();
  const { t } = useTranslation();
  const [discount, setDiscount] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const StudentDetails = Util.isValidData(invoiceDetail) && [
    invoiceDetail?.Student,
  ];
  const isParent = Util.getParentMode();
  const getAllInvoiceDetails = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`billing-invoice/${invoiceId}`)
      .then((res) => {
        setIsLoading(false);
        setInvoiceDetail(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (invoiceId > 0) {
      getAllInvoiceDetails();
    }
  }, [invoiceId]);
  useEffect(() => {
    if (Util.isValidData(invoiceDetail)) {
      if (
        Util.isValidData(invoiceDetail?.discount_amount) &&
        Util.isValidData(invoiceDetail?.discount_type)
      ) {
        setDiscount(true);
      } else {
        setDiscount(false);
      }
    }
  }, [invoiceDetail]);

  return (
    <div>
      {Util.isValidData(invoiceDetail) && (
        <SwipeableDrawer
          // hideBackdrop={true}
          anchor={getLanguage == "ar" ? "left" : "right"}
          open={show}
          onClose={() => {
            if (Util.isValidData(invoiceDetail)) {
              onClose();
            }
          }}
          onOpen={() => {}}
        >
          <div
            className="box-area"
            style={{ position: "relative", width: "100%", height: "100%" }}
          >
            <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-start drawer-header">
                  {t("Invoice")}{" "}
                  {`(${Util.invoiceIdFormat(invoiceDetail?.id)})`}
                </div>
                {/* <IconButton
                  aria-label="close"
                  size="small"
                  onClick={() => {
                    if (Util.isValidData(invoiceDetail)) {
                      onClose();
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton> */}
                 <button
                className="hidden-btn"
                onClick={() => {
                  if (Util.isValidData(invoiceDetail)) {
                    onClose();
                  }
                }}
              >
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
              </div>
              {!isLoading ? (
                <div className="body text-start mt-3">
                  <div className="row text-start mt-2">
                    <div className="mt-2 mb-2">
                      <div className="basic-text text-secondary">{t("To")}</div>
                      {Util.isValidArray(StudentDetails) &&
                        StudentDetails.map((s, index) => {
                          return (
                            <span
                              className="badge badge-students mt-2"
                              key={index}
                            >
                              <img
                                className="rounded-circle mr-2"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                src={
                                  s.photo
                                    ? imageStudentList + s.photo
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                }
                                alt=""
                              />
                              {s.first_name + " " + s.last_name}
                            </span>
                          );
                        })}
                    </div>
                    <div className="input-filed mt-2">
                      <span className="basic-text text-secondary">
                        {t("Invoice date")}
                      </span>
                      <div className="text-bold">
                        {Util.isValidData(invoiceDetail?.date) &&
                          moment(invoiceDetail?.date).format("DD/MM/YYYY")}
                      </div>
                    </div>
                    <span className="basic-text text-secondary mt-2">
                      {t("Fee type(s)")}
                    </span>
                    <div className="box-area">
                      <div className="row px-4">
                        {invoiceDetail?.types.map((f, index) => {
                          return (
                            <Fragment key={index}>
                              <div className="col-9 mt-2">
                                <div className="text-bold">
                                  {index + 1}. {f.Fee_Type}
                                </div>
                              </div>
                              <div className="col-3 mt-2">
                                <div className="text-bold">
                                  {Util.amountFormat(f.amount)}
                                </div>
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>

                      <hr style={{ backgroundColor: "#07112b" }} />
                      <div className="row px-4 ">
                        <div className="col-9 text-right">
                          <div>{t("Sub Total")}</div>
                        </div>
                        <div className="col-3">
                          <div className="text-bold">
                            {Util.isValidData(invoiceDetail?.sub_total) &&
                              Util.amountFormat(invoiceDetail?.sub_total)}
                          </div>
                        </div>
                      </div>

                      {discount && (
                        <div className="row px-4">
                          <div className="col-9 mt-2 text-right">
                            <div className="text-success">{`Discount (${invoiceDetail?.discount_type})`}</div>
                          </div>
                          <div className="col-3 mt-2">
                            <div className="text-bold">
                              {Util.isValidData(
                                invoiceDetail?.discount_amount
                              ) &&
                                Util.amountFormat(
                                  invoiceDetail?.discount_amount
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="box-area">
                      <div className="row px-4">
                        <div className="col-9 mt-2 text-right">
                          <div className="text-bold basic-large-text">
                            {t("Total")}
                          </div>
                        </div>
                        <div className="col-3 mt-2">
                          <div className="text-bold basic-large-text">
                            {Util.isValidData(invoiceDetail?.total) &&
                              Util.amountFormat(invoiceDetail?.total)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-4">
                    {invoiceDetail?.status == InvoiceStatus.PAID ? (
                      <div className="alert alert-success text-center ">
                        {t("Invoice is paid.")}
                      </div>
                    ) : invoiceDetail?.status == InvoiceStatus.PARTIAL_PAID ? (
                      <div className="alert alert-warning text-center">
                        {t("Invoice is Partially paid.")}
                      </div>
                    ) : (
                      <div className="alert alert-danger text-center">
                        {t("Invoice is not paid.")}
                      </div>
                    )}
                  </div>
                  {Util.isValidData(invoiceDetail) &&
                    invoiceDetail?.payment_settled.length > 0 && (
                      <div className="box-area">
                        <span className="basic-text text-secondary">
                          {t("Payment(s) against this invoice ")}
                        </span>
                        <table
                          className="table"
                          style={{
                            border: "1px solid #e5e7eb",
                            borderRadius: "12px",
                          }}
                        >
                          <thead style={{ backgroundColor: "#e5e7eb" }}>
                            <tr>
                              <th>{t("Payer")}</th>
                              <th>{t("Date")}</th>
                              <th>{t("Total Amount")}</th>
                              <th>{t("Amount Adjusted")}</th>
                            </tr>
                          </thead>
                          {invoiceDetail?.payment_settled.length > 0 ? (
                            <>
                              <tbody>
                                {invoiceDetail?.payment_settled.map(
                                  (In, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {" "}
                                          {In.method}
                                          <div className="small-text">
                                            By {In.paid_by}
                                          </div>
                                        </td>
                                        <td>
                                          {moment(In.date).format("DD/MM/YYYY")}
                                        </td>
                                        <td>{Util.amountFormat(In.deposit)}</td>
                                        <td>
                                          {Util.amountFormat(
                                            In.amount_adjusted
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                                <tr>
                                  <td
                                    colSpan={3}
                                    className="text-bold text-right"
                                  >
                                    {t("Pending to pay")}
                                  </td>
                                  <td className="text-bold">
                                    {Util.amountFormat(invoiceDetail?.due)}
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          ) : (
                            <tfoot>
                              <tr>
                                <td colSpan={4}>{t("No record found")}</td>
                              </tr>
                            </tfoot>
                          )}
                        </table>
                      </div>
                    )}
                  {!isParent && (
                    <div className="mt-4">
                      NOTE : {invoiceDetail?.payment_settled[0]?.note}
                    </div>
                  )}
                </div>
              ) : (
                <Loader />
              )}
            </Box>
          </div>
        </SwipeableDrawer>
      )}
    </div>
  );
}
