import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../Util";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import event from "../../assets/Icon/event.svg";
import clock from "../../assets/Icon/clock.svg";
import { Avatar } from "@material-ui/core";
import moment from "moment";
import { imageStudentList, imageUserFeed } from "../../screens/CommonImage";

export default function CardEvent(props) {
  const getLanguage = Util.getSelectedLanguage();
  const { t } = useTranslation();
  const is_staff = props.staff;
  const user = Util.getUser();
  const history = useHistory();

  const [data, setData] = useState(null);

  useEffect(() => {
    setData(props.activity);
  }, []);

  useEffect(() => {
    setData(props.activity);
  }, [props]);
  return (
    <div>
      {data && (
        <>
          <div className="activity-card mb-3">
            <div className="event-post-header d-flex justify-content-start align-items-center">
              <img src={event} height={24} width={24} />

              <div className="post-details ">
                <h4 className="medium-lg-text mx-2">
                  {data.CreatedBy?.firstName + " " + data.CreatedBy?.lastName}{" "}
                </h4>
                {/* {data.start_time !== null && ( */}
                  <div className="d-flex align-items-center mx-2 mt-1">
                    <img src={clock} height={16} width={16} />
                    <span className="sm-text mx-1">
                      {moment(data.created_on).format("hh:mm A")}
                    </span>
                  </div>
                {/* )} */}
              </div>
            </div>

            {/* <div className="card-content-details"> */}
            <div
              className={`d-flex  align-items-center gap-4 ${
                getLanguage !== "ar" ? "ml-4" : "mr-4"
              }`}
            >
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {moment(data.event_date).format("MMM")}
                </p>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  {moment(data.event_date).format("DD")}
                </span>
              </div>
              <div>
                <div className="d-flex align-items-center gap-2 inside-bg">
                  <span
                    style={{
                      width: "5px",
                      height: "43px",
                      backgroundColor: `${data?.color_code}`,
                      borderRadius: "10px",
                      content: "",
                    }}
                  ></span>
                  <div className="mt-3 w-100">
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {data.title}
                    </span>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {data.description
                        ? Util.formatWithHyperlinks(data.description)
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="students d-flex align-items-center">
                {(Util.isValidData(data?.event_grade) ||
                  Util.isValidData(data?.event_classroom)) && (
                  <>
                    <span className="badge badge-activity">
                      <img
                        className="rounded-circle mr-2"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                        src={
                          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                        }
                        alt=""
                      />
                      {Util.isValidData(data?.event_grade)
                        ? data?.event_grade.title
                        : data?.event_classroom.name}
                    </span>
                  </>
                )}
              </div> */}
            {/* </div> */}
          </div>
        </>
      )}
    </div>
  );
}
