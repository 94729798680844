import React from "react";
import { useTranslation } from "react-i18next";
import Util from "../../Util";
import Table from "../../components/common/Table";
import TagButton from "../Dashbord/TagButton";
import {
  browneTagStyle,
  greenTagStyle,
  orangeTagStyle,
} from "../Dashbord/TagButtonStyle";
import { Dropdown } from "react-bootstrap";

const UsersTable = ({
  users,
  selectedUserIds,
  handleAllSelectedUsers,
  usersChecked,
  setSortBy,
  setSortOrder,
  impersonate,
}) => {
  const { t } = useTranslation();

  const data = [
    ...users?.map((user) => {
      return {
        checked: selectedUserIds.includes(user.id),
        userEmail: user?.email,
        name: `${user?.firstName} ${user?.lastName}`,
        createdOn: Util.ISOToDateTime(user.created_on),
        status: user?.is_active ? "Active" : "Inactive",
      };
    }),
  ];

  const columns = [
    {
      name: "checked",
      content: (
        <label className="fancy-checkbox element-left">
          <input
            type="checkbox"
            name="allselect"
            onChange={handleAllSelectedUsers}
            checked={
              users.length > 0 && users.length === selectedUserIds.length
            }
          />
          <span></span>
        </label>
      ),
    },
    {
      name: "userEmail",
      content: <div className="m-l-5">{t("User Email")}</div>,
      sortable: true,
      sortBy: "email",
    },
    {
      name: "name",
      content: <>{t("Name")}</>,
      sortable: true,
      sortby: "first_name",
    },
    {
      name: "createdOn",
      content: <>{t("Created On")}</>,
      sortable: true,
      sortBy: "created_on",
    },
    {
      name: "status",
      content: <>{t("Status")}</>,
      sortable: true,
      sortBy: "is_active",
    },
    {
      name: "action",
      content: <>{t("Action")}</>,
    },
  ];

  const customRenderers = [
    ...users?.map((user) => {
      return {
        checked: (data) => {
          return (
            <label className="fancy-checkbox element-left">
              <input
                type="checkbox"
                name={user.id}
                onChange={usersChecked}
                value={user.id}
                checked={selectedUserIds.includes(user.id)}
              />
              <span></span>
            </label>
          );
        },
        status: (data) => (
          <TagButton
            size={"100px"}
            title={data}
            buttonStyle={user.is_active ? greenTagStyle : orangeTagStyle}
            icon="fa fa-circle"
            iconSize="6px"
          />
        ),
        action: (data) => (
          // <button onClick={() => impersonate(user)}>Impersonate</button>
          <Dropdown>
            <Dropdown.Toggle variant="none" as={CustomToggle}></Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  impersonate(user);
                }}
              >
                Impersonate
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      };
    }),
  ];

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <button>
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      </button>
    </a>
  ));

  const rowStyle = (row) => {
    if (row.checked) {
      return { backgroundColor: "rgba(100, 24, 195, 0.04)" };
    }
  };

  return (
    <Table
      data={data}
      columns={columns}
      customRenderers={customRenderers}
      rowStyle={rowStyle}
      setSortBy={setSortBy}
      setSortOrder={setSortOrder}
    />
  );
};

export default UsersTable;
