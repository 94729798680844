import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ApiCalling from "../../../network/ApiCalling";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { imagePost, imageStudentList } from "../../CommonImage";
import EmojiPicker from "emoji-picker-react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import { Rings } from "react-loader-spinner";
import ToastHOC from "../../HOC/ToastHOC";
import { Avatar, Box, SwipeableDrawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Search from "../../../assets/Icon/Search.svg";
import emptyCheck from "../../../assets/Icon/check-box-empty.svg";
import check from "../../../assets/Icon/check-box.svg";
import Loader from "../../../components/common/Loader";
import Invalid from "../../../assets/Icon/invalid.svg";
import ExitAlert from "../../../components/ExitAlert";
import Close from "../../../assets/Icon/close.png";

function AddAnnouncement(props) {
  const isVisible = props.isVisible;
  const isClose = props.isClose;
  const onSave = props.onSave;
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();
  const Category = [
    { value: "student", name: "Student" },
    { value: "room", name: "Room" },
    { value: "grade", name: "Grade" },
    { value: "level", name: "Level" },
    { value: "group", name: "Group" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [stepper, setStepper] = useState(0);
  const [isData, setIsData] = useState("");
  const [announcementId, setAnnouncementId] = useState(0);

  // stepper 1
  const [search, setSearch] = useState("");
  const [selectedStudentId, setSelectedStudentId] = useState([]);
  const [selectedStageId, setSelectedStageId] = useState([]);
  const [selectedGradeId, setSelectedGradeId] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState([]);
  const [selectedStudentsError, setSelectedStudentsError] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [stageData, setStageData] = useState([]);
  const [gradeData, setGradeData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [groupData, setGroupData] = useState([]);

  // stepper 2
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [type, setType] = useState(0);
  const [typeError, setTypeError] = useState("");
  const [description, setDescription] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const emojiPickerRef = useRef(null);

  const [imageFile, setImageFile] = useState([]);
  const [deletedMediaId, setDeletedMediaId] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [activityMedia, setActivityMedia] = useState([]);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [fileError, setFileError] = useState("");

  const handleAllSelectedStudent = (e) => {
    if (e.target.checked) {
      setSelectedStudentId(studentData.map((item) => item.id));
    } else {
      setSelectedStudentId([]);
    }
    setIsStateUpdated(true);
  };

  const handleRowClick = (id) => {
    if (!selectedStudentId.includes(id)) {
      setSelectedStudentId([...selectedStudentId, id]);
    } else {
      const data = selectedStudentId.filter((s) => s !== id);
      setSelectedStudentId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };

  const handleAllSelectedStage = (e) => {
    if (e.target.checked) {
      setSelectedStageId(stageData.map((item) => item.id));
    } else {
      setSelectedStageId([]);
    }
    setIsStateUpdated(true);
  };

  const handleStageRowClick = (id) => {
    if (!selectedStageId.includes(id)) {
      setSelectedStageId([...selectedStageId, id]);
    } else {
      const data = selectedStageId.filter((s) => s !== id);
      setSelectedStageId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };
  const handleAllSelectedGrade = (e) => {
    if (e.target.checked) {
      setSelectedGradeId(gradeData.map((item) => item.id));
    } else {
      setSelectedGradeId([]);
    }
    setIsStateUpdated(true);
  };

  const handleGradeRowClick = (id) => {
    if (!selectedGradeId.includes(id)) {
      setSelectedGradeId([...selectedGradeId, id]);
    } else {
      const data = selectedGradeId.filter((s) => s !== id);
      setSelectedGradeId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };

  const handleAllSelectedRoom = (e) => {
    if (e.target.checked) {
      setSelectedRoomId(roomData.map((item) => item.id));
    } else {
      setSelectedRoomId([]);
    }
    setIsStateUpdated(true);
  };

  const handleRoomRowClick = (id) => {
    if (!selectedRoomId.includes(id)) {
      setSelectedRoomId([...selectedRoomId, id]);
    } else {
      const data = selectedRoomId.filter((s) => s !== id);
      setSelectedRoomId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };

  const handleAllSelectedGroup = (e) => {
    if (e.target.checked) {
      setSelectedGroupId(groupData.map((item) => item.id));
    } else {
      setSelectedGroupId([]);
    }
    setIsStateUpdated(true);
  };

  const handleGroupRowClick = (id) => {
    if (!selectedGroupId.includes(id)) {
      setSelectedGroupId([...selectedGroupId, id]);
    } else {
      const data = selectedGroupId.filter((s) => s !== id);
      setSelectedGroupId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };
  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
    setTitleError("");
    setIsStateUpdated(true);
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
    setTypeError("");
    setIsStateUpdated(true);
  };
  const handleAddEmoji = (emojiObject) => {
    const newDescription = description + emojiObject.emoji;
    setDescription(newDescription);
  };
  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
    setDescriptionError("");
    setIsStateUpdated(true);
  };

  const handleImageFile = (e) => {
    const fileLimit = Util.checkFileSize(e);
    if (!fileLimit) {
      setFileError("File size limit exceeded 25mb");
      return;
    }
    const files = e.target.files;
    const newImageFiles = [...imageFile];
    for (let i = 0; i < files.length; i++) {
      newImageFiles.push(files[i]);
    }
    setImageFile(newImageFiles);
    setFileError("");
    setIsStateUpdated(true);
  };

  const handleRemoveImage = (indexToRemove) => {
    const newImageFiles = [...imageFile];
    newImageFiles.splice(indexToRemove, 1);
    setImageFile(newImageFiles);
    setFileError("");
    setIsStateUpdated(true);
  };
  const reset = () => {
    setIsData("");
    setAnnouncementId(0);
    setStepper(0);
    setSelectedStudentId([]);
    setSelectedGradeId([]);
    setSelectedStageId([]);
    setSelectedGroupId([]);
    setSelectedRoomId([]);
    setActivityMedia([]);
    setImageFile([]);
    setTitle("");
    setType(0);
    setDescription("");
    setSelectedStudentsError("");
    setTitleError("");
    setTypeError("");
    setDescriptionError("");
    setFileError("");
  };

  const handleSave = (e) => {
    setIsStateUpdated(false);
    e.preventDefault();
    setTitleError("");
    setTypeError("");
    setDescriptionError("");
    setFileError("");
    let error = false;
    console.log(type)
    if (title === "") {
      setTitleError(t("Title is required"));
      error = true;
    }
    if (description === "") {
      setDescriptionError(t("Description is required"));
      error = true;
    }
    if (type === 0) {
      setTypeError(t("Announcement Type is required"));
      error = true;
    }

    if (!error) {
      setIsLoading(true);
      const selectedStudentIds = selectedStudentId.join(",");
      const selectedGradeIds = selectedGradeId.join(",");
      const selectedStageIds = selectedStageId.join(",");
      const selectedRoomIds = selectedRoomId.join(",");
      const selectedGroupIds = selectedGroupId.join(",");
      if (announcementId !== 0) {
        const formData = new FormData();
        formData.append("id", announcementId);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("announcement_type_id", type);
        formData.append("student_ids", selectedStudentIds);
        formData.append("group_ids", selectedGroupIds);
        formData.append("stage_ids", selectedStageIds);
        formData.append("grade_ids", selectedGradeIds);
        formData.append("room_ids", selectedRoomIds);
        for (let i = 0; i < imageFile.length; i++) {
          formData.append("activity", imageFile[i]);
        }
        formData.append("deleted_mediaIds", deletedMediaId.toString());
        ApiCalling.apiCallBodyDataPost("activity/update-announcement", formData)
          .then((res) => {
            setIsLoading(false);
            // console.log("Update Announcement", res.data);
            if (res.data.status == true) {
              onSave(t("Announcement Modified Successfully"));
              reset();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("announcement_type_id", type);
        formData.append("student_ids", selectedStudentIds);
        formData.append("group_ids", selectedGroupIds);
        formData.append("stage_ids", selectedStageIds);
        formData.append("grade_ids", selectedGradeIds);
        formData.append("room_ids", selectedRoomIds);
        // formData.append("testing", "true");
        for (let i = 0; i < imageFile.length; i++) {
          formData.append("activity", imageFile[i]);
        }
        ApiCalling.apiCallBodyDataPost("activity/add-announcement", formData)
          .then((res) => {
            setIsLoading(false);
            // console.log("Post Announcement", res.data);
            if (res.data.status == true) {
              onSave(t("Announcement Created Successfully"));

              reset();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const announcementById = (e) => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`activity/getById/${announcementId}`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.ActivityStudents && res.data.ActivityStudents.length > 0) {
          let stu = [],
            sta = [],
            gra = [],
            grp = [],
            room = [];
          res.data.ActivityStudents.map((val) => {
            if (val.Student) {
              stu.push(val.Student.id);
            } else if (val.Grade) {
              gra.push(val.Grade.id);
            } else if (val.Stage) {
              sta.push(val.Stage.id);
            } else if (val.ClassRoom) {
              room.push(val.ClassRoom.id);
            } else if (val.Group) {
              grp.push(val.Group.id);
            }
          });
          if (stu.length > 0) {
            setIsData("student");
          } else if (room.length > 0) {
            setIsData("room");
          } else if (gra.length > 0) {
            setIsData("grade");
          } else if (sta.length > 0) {
            setIsData("level");
          } else if (grp.length > 0) {
            setIsData("group");
          }
          setSelectedStudentId(stu);
          setSelectedGradeId(gra);
          setSelectedStageId(sta);
          setSelectedGroupId(grp);
          setSelectedRoomId(room);
        }
        setTitle(res.data.title);
        setDescription(res.data.description);
        setType(res.data.announcement_type_id);
        setActivityMedia(res.data.ActivityMedia);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();

    ApiCalling.apiCallDelete(`activity/${announcementId}`)
      .then((res) => {
        setShowDeletePopup(false);
        onSave(t("Announcement Deleted successfully!"));
        reset();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const stageList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("stage/list")
      .then((res) => {
        setIsLoading(false);
        setStageData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const groupList = () => {
    setIsLoading(true);
    const data = {
      page_size: 10000,
      page_number: 1,
    };
    ApiCalling.apiCallBodyDataPost("group/list", data)
      .then((res) => {
        setIsLoading(false);
        setGroupData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGradeList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("grade/list")
      .then((res) => {
        setIsLoading(false);
        setGradeData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRoomList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("classroom/list")
      .then((res) => {
        setIsLoading(false);
        setRoomData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStudent = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`student/all?str=${search}`).then((res) => {
      setIsLoading(false);
      setStudentData(res.data);
      setFilteredStudentList(res.data);
    });
  };
  useEffect(() => {
    getStudent();
    getGradeList();
    getRoomList();
    stageList();
    groupList();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    }

    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  useEffect(() => {
    if (announcementId > 0) {
      setStepper(1);
      announcementById();
    } else {
      setStepper(0);
      reset();
    }
  }, [announcementId]);

  useEffect(() => {
    setAnnouncementId(props.announcementId);
  }, [props]);

  const getTypeList = async () => {
    ApiCalling.apiCallBodyDataGet("announcement-type/list")
      .then((res) => {
        setTypeList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTypeList();
  }, []);

  return (
    <>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={isVisible}
        onClose={() => {
          if (announcementId > 0) {
            setStepper(1);
          }
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            isClose();
            setAnnouncementId(0);
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">{t("Add Announcement")}</div>
              {/* <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  if (announcementId > 0) {
                    setStepper(1);
                  }
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    isClose();
                    setAnnouncementId(0);
                    reset();
                    setIsStateUpdated(false);
                  }
                }}
              >
                <CloseIcon />
              </IconButton> */}
              <button
                className="hidden-btn"
                onClick={() => {
                  if (announcementId > 0) {
                    setStepper(1);
                  }
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    isClose();
                    setAnnouncementId(0);
                    reset();
                    setIsStateUpdated(false);
                  }
                }}
              >
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div className="body mt-3">
              <div className="row mt-2">
                <div className="d-flex large-bold-text justify-content-between align-items-center mb-1">
                  <div>
                    {stepper == 0 ? (
                      t("Select Tag")
                    ) : stepper == 1 ? (
                      <>{`${"Select"} ${isData} `}</>
                    ) : (
                      t("Announcement Details")
                    )}
                  </div>
                  <div>{stepper == 0 ? 1 : stepper == 1 ? 2 : 3}/3</div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div className="active-stepper"></div>
                  <div
                    className={
                      stepper == 1 || stepper == 2
                        ? "active-stepper"
                        : "inactive-stepper"
                    }
                  ></div>
                  <div
                    className={
                      stepper == 2 ? "active-stepper" : "inactive-stepper"
                    }
                  ></div>
                </div>
                {!isLoading ? (
                  <>
                    {stepper == 0 ? (
                      <>
                        <div className="row text-start mt-3">
                          <span className="">{t("Whom you want to Tag")}?</span>
                          {Category.map((c, index) => {
                            return (
                              <div
                                key={index}
                                className="col-4 mb-2 mt-2 "
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  className="primary-lg-btn btn"
                                  onClick={() => {
                                    setIsData(c.value);
                                    setStepper((prv) => prv + 1);
                                  }}
                                >
                                  {c.name}
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : stepper == 1 ? (
                      <>
                        {!isLoading ? (
                          <div className="mt-3">
                            {isData === "student" && (
                              <>
                                <div className="drawer-sub-header">
                                  {t("Students")}
                                </div>
                                <div className="input-group">
                                  <input
                                    className="form-control search"
                                    placeholder="Search students"
                                    type="text"
                                    value={search}
                                    onChange={(e) => {
                                      setSearch(e.target.value);
                                      const searchValue = e.target.value;
                                      if (searchValue.trim() === "") {
                                        setFilteredStudentList(studentData);
                                      } else {
                                        const filteredStudent =
                                          studentData.filter((s) =>
                                            s.first_name
                                              .toLowerCase()
                                              .includes(
                                                searchValue.toLowerCase()
                                              )
                                          );
                                        setFilteredStudentList(filteredStudent);
                                      }
                                    }}
                                  />
                                  <div className="input-group-prepend">
                                    <div
                                      className="search-icon"
                                      style={{ paddingRight: "13px" }}
                                    >
                                      <img
                                        src={Search}
                                        className="svg-icon"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="post-table-container mt-3">
                                  <div className="table-wrapper">
                                    <table className="table">
                                      <thead>
                                        <tr className="text-nowrap">
                                          <td
                                            scope="col"
                                            className="check-col"
                                            style={{
                                              paddingRight: "0px",
                                              width: 0,
                                            }}
                                          >
                                            <label className="fancy-checkbox element-left">
                                              <input
                                                type="checkbox"
                                                name="allselect"
                                                onChange={
                                                  handleAllSelectedStudent
                                                }
                                                checked={
                                                  filteredStudentList.length >
                                                    0 &&
                                                  selectedStudentId.length ===
                                                    filteredStudentList.length
                                                }
                                              />
                                              <span tabIndex={0}></span>
                                            </label>
                                          </td>
                                          <th scope="col">
                                            {selectedStudentId.length > 0
                                              ? selectedStudentId.length +
                                                " " +
                                                t("Student Selected")
                                              : t("Student")}
                                          </th>
                                        </tr>
                                      </thead>
                                      {filteredStudentList.length > 0 ? (
                                        <tbody>
                                          {filteredStudentList
                                            // .sort((a, b) =>
                                            //   selectedStudentId.includes(a.id)
                                            //     ? -1
                                            //     : selectedStudentId.includes(
                                            //         b.id
                                            //       )
                                            //     ? 1
                                            //     : 0
                                            // )
                                            .map((item) => {
                                              return (
                                                <tr
                                                  key={item.id}
                                                  style={{
                                                    backgroundColor: `${
                                                      selectedStudentId.includes(
                                                        item.id
                                                      )
                                                        ? "rgba(100, 24, 195, 0.04)"
                                                        : ""
                                                    }`,
                                                  }}
                                                  onClick={() =>
                                                    handleRowClick(item.id)
                                                  }
                                                >
                                                  <td
                                                    style={{
                                                      cursor: "pointer",
                                                      paddingRight: "0px",
                                                      width: 0,
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        selectedStudentId.includes(
                                                          item.id
                                                        )
                                                          ? check
                                                          : emptyCheck
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center">
                                                      {item.photo ? (
                                                        <img
                                                          className="rounded-circle "
                                                          style={{
                                                            width: "40px",
                                                            height: "40px",
                                                          }}
                                                          src={
                                                            imageStudentList +
                                                            item.photo
                                                          }
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <Avatar
                                                          aria-label="recipe"
                                                          className="avatar-font"
                                                        >
                                                          {item.first_name.toUpperCase()[0] +
                                                            item.last_name.toUpperCase()[0]}
                                                        </Avatar>
                                                      )}
                                                      <a
                                                        className="ml-2"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        // onClick={(e) => {
                                                        //   e.preventDefault();
                                                        //   history.push({
                                                        //     pathname: "/profileTabs",
                                                        //     studentId: item.id,
                                                        //   });
                                                        // }}
                                                      >
                                                        {item.first_name}{" "}
                                                        {item.last_name}
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      ) : (
                                        <tfoot>
                                          <tr>
                                            <td colSpan={2}>
                                              {t("Student Not Found")}
                                            </td>
                                          </tr>
                                        </tfoot>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            )}
                            {isData === "level" && (
                              <>
                                <div className="drawer-sub-header">
                                  {t("Level")}
                                </div>
                                <div className="post-table-container">
                                  <div className="table-wrapper">
                                    <table className="table">
                                      <thead>
                                        <tr className="text-nowrap">
                                          <td
                                            scope="col"
                                            className="check-col"
                                            style={{
                                              paddingRight: "0px",
                                              width: 0,
                                            }}
                                          >
                                            <label className="fancy-checkbox element-left">
                                              <input
                                                type="checkbox"
                                                name="allselect"
                                                onChange={
                                                  handleAllSelectedStage
                                                }
                                                checked={
                                                  stageData.length > 0 &&
                                                  selectedStageId.length ===
                                                    stageData.length
                                                }
                                              />
                                              <span tabIndex={0}></span>
                                            </label>
                                          </td>
                                          <th scope="col">
                                            {selectedStageId.length > 0
                                              ? selectedStageId.length +
                                                " " +
                                                t("Level Selected")
                                              : t("Level")}
                                          </th>
                                        </tr>
                                      </thead>
                                      {stageData.length > 0 ? (
                                        <tbody>
                                          {stageData
                                            // .sort((a, b) =>
                                            //   selectedStageId.includes(a.id)
                                            //     ? -1
                                            //     : selectedStageId.includes(b.id)
                                            //     ? 1
                                            //     : 0
                                            // )
                                            .map((item) => {
                                              return (
                                                <tr
                                                  key={item.id}
                                                  style={{
                                                    backgroundColor: `${
                                                      selectedStageId.includes(
                                                        item.id
                                                      )
                                                        ? "rgba(100, 24, 195, 0.04)"
                                                        : ""
                                                    }`,
                                                  }}
                                                  onClick={() =>
                                                    handleStageRowClick(item.id)
                                                  }
                                                >
                                                  <td
                                                    style={{
                                                      cursor: "pointer",
                                                      paddingRight: "0px",
                                                      width: 0,
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        selectedStageId.includes(
                                                          item.id
                                                        )
                                                          ? check
                                                          : emptyCheck
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center">
                                                      <a
                                                        className="ml-2"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {item.stage}
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      ) : (
                                        <tfoot>
                                          <tr>
                                            <td colSpan={2}>
                                              {t("Level Not Found")}
                                            </td>
                                          </tr>
                                        </tfoot>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            )}
                            {isData === "grade" && (
                              <>
                                <div className="drawer-sub-header">
                                  {t("Grade")}
                                </div>
                                <div className="post-table-container">
                                  <div className="table-wrapper">
                                    <table className="table">
                                      <thead>
                                        <tr className="text-nowrap">
                                          <td
                                            scope="col"
                                            className="check-col"
                                            style={{
                                              paddingRight: "0px",
                                              width: 0,
                                            }}
                                          >
                                            <label className="fancy-checkbox element-left">
                                              <input
                                                type="checkbox"
                                                name="allselect"
                                                onChange={
                                                  handleAllSelectedGrade
                                                }
                                                checked={
                                                  gradeData.length > 0 &&
                                                  selectedGradeId.length ===
                                                    gradeData.length
                                                }
                                              />
                                              <span tabIndex={0}></span>
                                            </label>
                                          </td>
                                          <th scope="col">
                                            {selectedGradeId.length > 0
                                              ? selectedGradeId.length +
                                                " " +
                                                t("Grade Selected")
                                              : t("Grade")}
                                          </th>
                                        </tr>
                                      </thead>
                                      {gradeData.length > 0 ? (
                                        <tbody>
                                          {gradeData
                                            // .sort((a, b) =>
                                            //   selectedGradeId.includes(a.id)
                                            //     ? -1
                                            //     : selectedGradeId.includes(b.id)
                                            //     ? 1
                                            //     : 0
                                            // )
                                            .map((item) => {
                                              return (
                                                <tr
                                                  key={item.id}
                                                  style={{
                                                    backgroundColor: `${
                                                      selectedGradeId.includes(
                                                        item.id
                                                      )
                                                        ? "rgba(100, 24, 195, 0.04)"
                                                        : ""
                                                    }`,
                                                  }}
                                                  onClick={() =>
                                                    handleGradeRowClick(item.id)
                                                  }
                                                >
                                                  <td
                                                    style={{
                                                      cursor: "pointer",
                                                      paddingRight: "0px",
                                                      width: 0,
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        selectedGradeId.includes(
                                                          item.id
                                                        )
                                                          ? check
                                                          : emptyCheck
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center">
                                                      <a
                                                        className="ml-2"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        // onClick={(e) => {
                                                        //   e.preventDefault();
                                                        //   history.push({
                                                        //     pathname: "/profileTabs",
                                                        //     studentId: item.id,
                                                        //   });
                                                        // }}
                                                      >
                                                        {item.title}
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      ) : (
                                        <tfoot>
                                          <tr>
                                            <td colSpan={2}>
                                              {t("Grade Not Found")}
                                            </td>
                                          </tr>
                                        </tfoot>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            )}
                            {isData === "room" && (
                              <>
                                <div className="drawer-sub-header">
                                  {t("Classroom")}
                                </div>
                                <div className="post-table-container">
                                  <div className="table-wrapper">
                                    <table className="table">
                                      <thead>
                                        <tr className="text-nowrap">
                                          <td
                                            scope="col"
                                            className="check-col"
                                            style={{
                                              paddingRight: "0px",
                                              width: 0,
                                            }}
                                          >
                                            <label className="fancy-checkbox element-left">
                                              <input
                                                type="checkbox"
                                                name="allselect"
                                                onChange={handleAllSelectedRoom}
                                                checked={
                                                  roomData.length > 0 &&
                                                  selectedRoomId.length ===
                                                    roomData.length
                                                }
                                              />
                                              <span tabIndex={0}></span>
                                            </label>
                                          </td>
                                          <th scope="col">
                                            {selectedRoomId.length > 0
                                              ? selectedRoomId.length +
                                                " " +
                                                t("Classroom Selected")
                                              : t("Classroom")}
                                          </th>
                                        </tr>
                                      </thead>
                                      {roomData.length > 0 ? (
                                        <tbody>
                                          {roomData
                                            // .sort((a, b) =>
                                            //   selectedRoomId.includes(a.id)
                                            //     ? -1
                                            //     : selectedRoomId.includes(b.id)
                                            //     ? 1
                                            //     : 0
                                            // )
                                            .map((item) => {
                                              return (
                                                <tr
                                                  key={item.id}
                                                  style={{
                                                    backgroundColor: `${
                                                      selectedRoomId.includes(
                                                        item.id
                                                      )
                                                        ? "rgba(100, 24, 195, 0.04)"
                                                        : ""
                                                    }`,
                                                  }}
                                                  onClick={() =>
                                                    handleRoomRowClick(item.id)
                                                  }
                                                >
                                                  <td
                                                    style={{
                                                      cursor: "pointer",
                                                      paddingRight: "0px",
                                                      width: 0,
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        selectedRoomId.includes(
                                                          item.id
                                                        )
                                                          ? check
                                                          : emptyCheck
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center">
                                                      <a
                                                        className="ml-2"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        // onClick={(e) => {
                                                        //   e.preventDefault();
                                                        //   history.push({
                                                        //     pathname: "/profileTabs",
                                                        //     studentId: item.id,
                                                        //   });
                                                        // }}
                                                      >
                                                        {item.name}
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      ) : (
                                        <tfoot>
                                          <tr>
                                            <td colSpan={2}>
                                              {t("Room Not Found")}
                                            </td>
                                          </tr>
                                        </tfoot>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            )}
                            {isData === "group" && (
                              <>
                                <div className="drawer-sub-header">
                                  {t("Group")}
                                </div>
                                <div className="post-table-container">
                                  <div className="table-wrapper">
                                    <table className="table">
                                      <thead>
                                        <tr className="text-nowrap">
                                          <td
                                            scope="col"
                                            className="check-col"
                                            style={{
                                              paddingRight: "0px",
                                              width: 0,
                                            }}
                                          >
                                            <label className="fancy-checkbox element-left">
                                              <input
                                                type="checkbox"
                                                name="allselect"
                                                onChange={
                                                  handleAllSelectedGroup
                                                }
                                                checked={
                                                  groupData.length > 0 &&
                                                  selectedGroupId.length ===
                                                    groupData.length
                                                }
                                              />
                                              <span tabIndex={0}></span>
                                            </label>
                                          </td>
                                          <th scope="col">
                                            {selectedGroupId.length > 0
                                              ? selectedGroupId.length +
                                                " " +
                                                t("Group Selected")
                                              : t("Group")}
                                          </th>
                                        </tr>
                                      </thead>
                                      {groupData.length > 0 ? (
                                        <tbody>
                                          {groupData
                                            // .sort((a, b) =>
                                            //   selectedGroupId.includes(a.id)
                                            //     ? -1
                                            //     : selectedGroupId.includes(b.id)
                                            //     ? 1
                                            //     : 0
                                            // )
                                            .map((item) => {
                                              return (
                                                <tr
                                                  key={item.id}
                                                  style={{
                                                    backgroundColor: `${
                                                      selectedGroupId.includes(
                                                        item.id
                                                      )
                                                        ? "rgba(100, 24, 195, 0.04)"
                                                        : ""
                                                    }`,
                                                  }}
                                                  onClick={() =>
                                                    handleGroupRowClick(item.id)
                                                  }
                                                >
                                                  <td
                                                    style={{
                                                      cursor: "pointer",
                                                      paddingRight: "0px",
                                                      width: 0,
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        selectedGroupId.includes(
                                                          item.id
                                                        )
                                                          ? check
                                                          : emptyCheck
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center">
                                                      <a
                                                        className="ml-2"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        // onClick={(e) => {
                                                        //   e.preventDefault();
                                                        //   history.push({
                                                        //     pathname: "/profileTabs",
                                                        //     studentId: item.id,
                                                        //   });
                                                        // }}
                                                      >
                                                        {item.name}
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      ) : (
                                        <tfoot>
                                          <tr>
                                            <td colSpan={2}>
                                              {t("Group Not Found")}
                                            </td>
                                          </tr>
                                        </tfoot>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        ) : (
                          <Loader />
                        )}
                      </>
                    ) : (
                      <>
                        <div className="text-start mt-3 mx-1">
                          <div className="input-filed">
                            <span className="basic-text required">
                              {t("Title")}
                            </span>
                            <input
                              className={`form-control ${
                                titleError.length > 0
                                  ? "input-error"
                                  : "custom-input"
                              }`}
                              // placeholder="Email"
                              type="email"
                              onChange={handleChangeTitle}
                              value={title}
                            />
                            {titleError && (
                              <span className="invalid-error">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {titleError}
                              </span>
                            )}
                          </div>
                          <div className="input-filed">
                            <span className="basic-text required">
                              {t("Type")}
                            </span>
                            <select
                              className={`select-dropdown form-select custom-input ${
                                typeError.length > 0 ? "input-error" : " "
                              }`}
                              value={type}
                              onChange={handleChangeType}
                            >
                              <option value="">{t("Select")}</option>
                              {typeList.map((m, index) => {
                                return (
                                  <option key={index} value={m.id}>
                                    {m.type}
                                  </option>
                                );
                              })}
                            </select>
                            {typeError.length > 0 && (
                              <div className="invalid-error px-0">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {typeError}
                              </div>
                            )}
                          </div>
                          <div className="input-filled">
                            <span className="basic-text px-0 required">
                              {t("Description")}
                            </span>
                            <div style={{ position: "relative" }}>
                              <textarea
                                className={`text-area ${
                                  descriptionError.length > 0
                                    ? "input-error"
                                    : " text-area auto-height"
                                }`}
                                placeholder="Write..."
                                rows="4"
                                cols={4}
                                value={description}
                                onChange={handleChangeDescription}
                              ></textarea>
                              {descriptionError.length > 0 && (
                                <div className="invalid-error px-0">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {descriptionError}
                                </div>
                              )}
                              <div
                                style={{
                                  right: getLanguage !== "ar" ? -13 : 560,
                                  position: "absolute",
                                  top: 3,
                                  zIndex: 9999,
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "24px",
                                    cursor: "pointer",
                                    right: "20px",
                                    position: "absolute",
                                  }}
                                  onClick={() =>
                                    setShowEmojiPicker(!showEmojiPicker)
                                  }
                                >
                                  😊
                                </span>
                                {showEmojiPicker && (
                                  <div className="m-5" ref={emojiPickerRef}>
                                    {" "}
                                    <EmojiPicker
                                      onEmojiClick={handleAddEmoji}
                                      searchDisabled
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4  ">
                            <input
                              accept="image/*"
                              multiple
                              type="file"
                              id="imgupload"
                              style={{ display: "none" }}
                              onChange={handleImageFile}
                            />

                            <button
                              style={{
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                                outline: "none",
                                padding: "1",
                              }}
                            >
                              <i
                                className="fa fa-picture-o fa-lg "
                                aria-hidden="true"
                                onClick={() => {
                                  document.getElementById("imgupload").click();
                                }}
                              ></i>
                            </button>

                            {fileError.length > 0 && (
                              <div className="invalid-error px-0">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {fileError}
                              </div>
                            )}
                          </div>
                          {imageFile.length > 0 && (
                            <>
                              <div className="row">
                                {imageFile.map((file, index) => {
                                  return (
                                    <>
                                      <div
                                        className="col-6 text-center"
                                        key={index}
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            display: "inline-block",
                                          }}
                                          className="m-2"
                                        >
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "310px",
                                              height: "200px",
                                            }}
                                          >
                                            <img
                                              crossOrigin="anonymous"
                                              style={{
                                                position: "relative",
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                              src={URL.createObjectURL(file)}
                                            />
                                            <a
                                              style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                              }}
                                              onClick={() =>
                                                handleRemoveImage(index)
                                              }
                                            >
                                              <button className="btn btn-link">
                                                <CloseIcon />
                                              </button>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          )}
                          {activityMedia.length > 0 && (
                            <div className="row">
                              {activityMedia.map((i) => {
                                return (
                                  <div className="col-6 col-md-6 " key={i.name}>
                                    <div className="d-flex">
                                      <img
                                        src={imagePost + i.name}
                                        style={{ maxHeight: "150px" }}
                                        className="p-3 img-fluid"
                                      />
                                      <a
                                        style={{
                                          float: "right",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          if (
                                            activityMedia.some(
                                              (s) => s.id === i.id
                                            )
                                          ) {
                                            setActivityMedia(
                                              activityMedia.filter(
                                                (s) => s.id !== i.id
                                              )
                                            );
                                          }
                                          let c = [...deletedMediaId];
                                          c.push(i.id);
                                          setDeletedMediaId(c);
                                        }}
                                      >
                                        <CloseIcon />
                                      </a>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Loader />
                  </>
                )}
              </div>
            </div>
            <div
              className="border-top "
              style={{
                position: "fixed",
                width: "656px",
                bottom: "0px",
                right: getLanguage !== "ar" && "0px",
                background: "#ffffff",
                padding: "10px",
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                {stepper == 0 ? (
                  <button
                    className="secondary-md-btn btn ml-2"
                    onClick={() => {
                      isClose();
                      reset();
                    }}
                  >
                    {t("Cancel")}
                  </button>
                ) : (
                  <button
                    className="secondary-md-btn btn mr-2"
                    onClick={() => {
                      setStepper((prv) => prv - 1);
                      setSelectedStudentsError("");
                    }}
                  >
                    {t("Previous")}
                  </button>
                )}

                {stepper == 1 ? (
                  <div className="d-flex align-item center justify-content-end gap-2">
                    {selectedStudentsError.length > 0 && (
                      <span className="text-danger mt-2">
                        {selectedStudentsError}
                      </span>
                    )}
                    <button
                      className="primary-lg-btn btn"
                      onClick={() => {
                        if (
                          selectedStudentId.length > 0 ||
                          selectedGradeId.length > 0 ||
                          selectedStageId.length > 0 ||
                          selectedRoomId.length > 0 ||
                          selectedGroupId.length > 0
                        ) {
                          setStepper((prv) => prv + 1);
                        } else {
                          setSelectedStudentsError(
                            `${t("Please select")} ${isData}!`
                          );
                        }
                      }}
                    >
                      {t("Continue")}
                    </button>
                  </div>
                ) : (
                  <>
                    {stepper == 2 && (
                      <div className="d-flex justify-content-end align-items center">
                        {announcementId > 0 && (
                          <button
                            className={`${
                              Util.hasPermission("ANNOUNCEMENT_DELETE")
                                ? "btn-danger btn mr-2"
                                : "no-permission"
                            }`}
                            onClick={() => {
                              isClose();
                              setShowDeletePopup(true);
                            }}
                          >
                            {t("Delete")}
                          </button>
                        )}
                        <div>
                          <button
                            className={`${
                              Util.hasPermission("ANNOUNCEMENT_EDIT")
                                ? "primary-sm-btn btn mr-1"
                                : "no-permission"
                            }`}
                            style={{ float: "right" }}
                            onClick={handleSave}
                          >
                            {t("Save Announcement")}
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
      <Modal
        size="md"
        show={showDeletePopup}
        onHide={() => setShowDeletePopup(false)}
        animation={true}
        centered={true}
        dialogClassName="modal-view"
      >
        <div className="d-flex justify-content-between align-items-center p-4 ">
          <div className="drawer-sub-header">{t("Delete")}</div>
          <img
            src={Close}
            alt="close"
            className="svg-icon"
            height={20}
            width={20}
            onClick={() => {
              setShowDeletePopup(false);
            }}
          />
        </div>
        <Modal.Body>
          {!isLoading ? (
            <>
              <strong>{t("Are you sure, to delete this Announcement")}?</strong>
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn custom-primary"
            onClick={() => setShowDeletePopup(false)}
          >
            {t("no")}
          </button>
          <button className="btn btn-danger" onClick={handleDelete}>
            {t("Yes")}
          </button>
        </Modal.Footer>
      </Modal>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
        onOk={() => {
          isClose();
          setIsAlertModal(false);
          reset();
          setIsStateUpdated(false);
        }}
      />
    </>
  );
}
export default ToastHOC(AddAnnouncement);
