import React, { useEffect, useState } from "react";
import unknownImg from "../../../assets/images/image 4.png";
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";
import { imageStudentList } from "../../CommonImage";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import StudentSummary from "./StudentSummary";
import Util from "../../../Util";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BillingPlans from "./BillingPlans";
import { useTranslation } from "react-i18next";
import ManualInvoice from "./AddManualInvoice";
import SetBillingPlan from "./SetBillingPlan";
import GeneratePayment from "./AddPayment";
import TransactionHistory from "./TransactionHistory";
import ToastHOC from "../../HOC/ToastHOC";
import UpcomingInvoices from "./UpcomingInvoices";

function StudentBillDetails(props) {
  const { t } = useTranslation();

  const { setIsToast } = props;
  const pendingToast = props.location?.pendingToast;
  const Student = props.location.student;
  const history = useHistory();
  const [value, setValue] = React.useState("1");
  const [isVisibleBillPlan, setIsVisibleBillPlan] = useState(false);
  const [isVisibleInvoice, setIsVisibleInvoice] = useState(false);
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (Student === undefined) {
      history.push("/billing-plan-list");
    }
  }, [Student]);
  return (
    <div className="container-fluid">
      <div className="mt-4">
        <BreadCrumbComponent
          Breadcrumb={[
            {
              name:"Billing",
              navigate:() =>{
                history.push("bill-Dashboard");
              }
            },
            {
              name: "Students",
              navigate: () => {
                history.push("/billing-plan-list");
              },
            },
            { name: `${t("Student Bill Details")}` },
          ]}
        />
      </div>

      <div className="body" style={{ marginTop: "20px" }}>
        <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
          <div className="d-flex align-items-center gap-3">
            <img
              alt="User"
              className="img-responsive  mt-1"
              style={{
                height: "120px",
                width: "120px",
                borderRadius: "50%",
                position: "relative",
              }}
              src={
                Student?.photo ? imageStudentList + Student?.photo : unknownImg
              }
            />
            <div>
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "#07112b",
                }}
              >
                {Student?.first_name + " " + Student?.last_name}
              </div>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#07112b",
                }}
              >
                {Student?.grade.title + " " + Student?.classroom.name}
              </div>
            </div>
          </div>

          <div className="dropdown">
            <button
              className="btn btn-lg custom-primary "
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ color: "#ffffff", padding: "5px, 10px" }}
            >
              {t("Select an action")}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setIsVisibleBillPlan(true);
                  }}
                >
                  {t("Set up a billing Plan")}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setIsVisibleInvoice(true);
                  }}
                >
                  {t("Add a one-time charge")}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setIsVisiblePayment(true);
                  }}
                >
                  {t("Generate a Payment ")}
                </a>
              </li>
            </ul>
          </div>
        </div>
        {Util.isValidData(Student) && (
          <>
            <SetBillingPlan
              show={isVisibleBillPlan}
              onClose={() => {
                setIsVisibleBillPlan(false);
              }}
              studentIds={Student?.id}
              student={[Student]}
              onSave={(msg) => {
                if (msg) {
                  setIsToast(msg);
                }
                setIsVisibleBillPlan(false);
                setUpdateData(null);
              }}
              updateData={() => {
                setUpdateData("updated");
              }}
            />
            <ManualInvoice
              show={isVisibleInvoice}
              onClose={() => {
                setIsVisibleInvoice(false);
              }}
              studentIds={Student?.id}
              student={[Student]}
              onSave={(msg) => {
                if (msg) {
                  setIsToast(msg);
                }
                setIsVisibleInvoice(false);
                setUpdateData(null);
              }}
              updateData={() => {
                setUpdateData("updated");
              }}
            />
            <GeneratePayment
              show={isVisiblePayment}
              onClose={() => {
                setIsVisiblePayment(false);
              }}
              studentIds={Student?.id}
              studentName={Student?.first_name + " " + Student?.last_name}
              onSave={(msg) => {
                if (msg) {
                  setIsToast(msg);
                  setIsVisiblePayment(false);
                  setUpdateData(null);
                }
              }}
              updateData={() => {
                setUpdateData("updated");
              }}
            />
          </>
        )}

        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            // indicatorColor="red"
            aria-label="secondary tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#6418c3",
                height: 2,
              },
            }}
          >
            <Tab
              value="1"
              sx={{
                "&.Mui-selected": {
                  color: "#6418c3",
                  fontWeight: "600",
                },
              }}
              label={t("Current Activity")}
            />
            <Tab
              value="2"
              sx={{
                "&.Mui-selected": {
                  color: "#6418c3",
                  fontWeight: "600",
                },
              }}
              label={t("Upcoming Invoices")}
            />
            <Tab
              value="3"
              sx={{
                "&.Mui-selected": {
                  color: "#6418c3",
                  fontWeight: "600",
                },
              }}
              label={t("Transaction History")}
            />
            <Tab
              sx={{
                "&.Mui-selected": {
                  color: "#6418c3",
                  fontWeight: "600",
                },
              }}
              value="4"
              label={t("Billing Plans")}
            />
          </Tabs>
          {value === "1" && (
            <StudentSummary
              Student={Student}
              updateData={Util.isValidData(updateData) && updateData}
            />
          )}
          {value === "2" && (
            <UpcomingInvoices
              Student={Student}
              onTabChange={() => {
                setValue("4");
              }}
            />
          )}
          {value === "3" && (
            <TransactionHistory
              Student={Student}
              updateData={Util.isValidData(updateData) && updateData}
            />
          )}
          {value === "4" && <BillingPlans Student={Student?.id} />}
        </Box>
      </div>
    </div>
  );
}
export default ToastHOC(StudentBillDetails);
