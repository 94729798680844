import React, { useEffect, useState } from "react";
import unknownImg from "../../../assets/images/Frame 1000004000.png";
import camera from "../../../assets/Icon/camera-icon.svg";
import { useTranslation } from "react-i18next";
import TagButton from "../TagButton";
import { orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import { Divider } from "../Staff/StaffDetails";
import Util from "../../../Util";
import PhoneInput from "react-phone-number-input";
import ApiCalling from "../../../network/ApiCalling";
import DatePicker from "react-datepicker";
import upload from "../../../assets/Icon/upload.svg";
import file from "../../../assets/Icon/file.svg";
import WorkInfo from "./WorkInfo";
import Loader from "../../../components/common/Loader";
import Father from "../../../assets/Icon/Father.svg";
import Mother from "../../../assets/Icon/Mother.svg";
import fileIcon from "../../../assets/Icon/file.svg";
import ParentAvtar from "../../../assets/Icon/Parent-avtar.svg";
import Edit from "../../../assets/Icon/Edit-blue.svg";

import {
  imageMyProfile,
  imageStudentList,
  studentIdentity,
  userIdentity,
} from "../../CommonImage";
import ToastHOC from "../../HOC/ToastHOC";
import EditPhotoModal from "../EditPhotoModal";
import CustomFileUpload from "../CustomFileUpload";
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Invalid from "../../../assets/Icon/invalid.svg";

const ParentDetails = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setIsToast } = props;
  const { setIsSubToast } = props;
  const data = props.location.data;
  const parentId = props.location.id;
  const UserId = props.location.UserId;
  const studentId = props.location.studentId;
  const search = props.location.search;
  console.log(search);
  console.log(data, parentId, UserId, studentId);
  const [isLoading, setIsLoading] = useState(false);
  const [isPersonalDetailsLoading, setIsPersonalDetailsLoading] =
    useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [personalDetailEdit, setPersonalDetailEdit] = useState(false);
  const [workInfoEdit, setWorkInfoEdit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [relationId, setRelationId] = useState(0);
  const [relationIdError, setRelationIdError] = useState("");
  const [relationList, setRelationList] = useState([]);
  const [relation, setRelation] = useState(0);
  const [notes, setNotes] = useState("");
  const [insurance, setInsurance] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [parentData, setParentData] = useState("");
  const [studentData, setStudentData] = useState("");
  const [avatarURL, setAvatarURL] = useState("");
  const [avatar, setAvatar] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [identityUpload, setIdentityUpload] = useState("");
  const [identityURL, setIdentityURL] = useState("");
  const [identityFileName, setIdentityFileName] = useState("");
  const [personalShowAcc, setPersonalShowAcc] = useState(true);
  const [workShowAcc, setWorkShowAcc] = useState(false);
  const [isPrimaryContact, setIsPrimaryContact] = useState(false);
  const [studentDetails, setStudentDetails] = useState({});

  const Chip = ({ relation }) => {
    return (
      <>
        <button
          style={{
            backgroundColor: "transparent",
            border: " 1px solid #5A6677",
            borderRadius: "20px",
            padding: "2px 12px",
            color: "#5A6677",
            fontSize: "14px",
            cursor: "default",
          }}
        >
          <img
            src={relation === "Father" ? Father : Mother}
            alt=""
            className="svg-icon"
            style={{ width: "20px" }}
          />{" "}
          {relation}
        </button>
      </>
    );
  };
  const getRelationList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("student-parent-relation-type").then((res) => {
      setIsLoading(false);
      setRelationList(res.data);
    });
  };
  useEffect(() => {
    getRelationList();
  }, []);
  const getParentDetails = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("parent/by-parentid/" + parentId)
      .then((res) => {
        setIsLoading(false);
        setParentData(res?.data);
        setAvatarURL(
          Util.isValidData(res?.data?.User?.avatar)
            ? imageMyProfile + res?.data?.User?.avatar
            : ""
        );
        setFirstName(res?.data?.User?.firstName);
        setLastName(res?.data?.User?.lastName);
        setEmail(res?.data?.User?.email);
        setMobile(res?.data?.User?.mobile);
        setInsurance(res?.data?.User?.insurance_number);
        setRelationId(
          Util.isValidData(res?.data?.student_parent_relation_type_id)
            ? res?.data?.student_parent_relation_type_id
            : 0
        );
        setRelation(res?.data?.StudentParentRelationType?.type);
        setNotes(res?.data?.User?.notes);
        setIdentityFileName(
          Util.isValidData(res?.data?.User?.identity)
            ? res?.data?.User?.identity
            : ""
        );
        setIsPrimaryContact(res?.data?.make_primary);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (parentId > 0) {
      getParentDetails();
    }
  }, [parentId]);
  const handlePhotoChange = (e) => {
    if (e.target.files[0]) {
      setShowEditModal(true);
    }
    setAvatar(e.target.files[0]);
    setAvatarURL(URL.createObjectURL(e.target.files[0]));
  };
  const handleIdentityDragOver = (e) => {
    e.preventDefault();
  };

  const handleIdentityDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleFileChange = (file) => {
    if (file) {
      const maxSize = 25 * 1024 * 1024;
      if (file.size <= maxSize) {
        setIdentityUpload(file);
        setIdentityURL(URL.createObjectURL(file));
      } else {
        setIsToast(t("File size exceeds the limit of 25MB"));
      }
    }
  };

  const handleIdentityUpload = (e) => {
    const file = e.target.files[0];
    handleFileChange(file);
  };
  const resetPersonalDetails = () => {
    setPersonalDetailEdit(false);
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setMobileError("");
    setRelationIdError("");
  };

  const handleSavePhoto = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("id", UserId);
    if (avatar) {
      formData.append("avatar", avatar);
    }
    ApiCalling.apiCallBodyDataPost("user/dynamic-update", formData)
      .then((res) => {
        if (res.data.status == true) {
          setIsLoading(false);
          setShowEditModal(false);
          setIsToast(res.data.message);
        } else {
          setIsToast(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleSavePersonalDetails = () => {
    let error = false;
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setRelationIdError("");
    setMobileError("");
    if (firstName === "") {
      setFirstNameError(t("First Name is required"));
      error = true;
    }
    if (lastName === "") {
      setLastNameError(t("Last Name is required"));
      error = true;
    }
    if (email === "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }
    if (relationId == 0) {
      setRelationIdError(t("Please Select Relation"));
      error = true;
    }
    if (mobile == undefined || mobile == null) {
      setMobileError(t("Phone Number is required"));
      error = true;
    }
    if (!error) {
      setIsPersonalDetailsLoading(true);
      const formData = new FormData();
      formData.append("id", UserId);
      formData.append("parent_id", parentId);
      formData.append("student_id", studentId);
      formData.append("mobile", mobile);
      if (firstName) {
        formData.append("firstName", firstName);
      }
      if (lastName) {
        formData.append("lastName", lastName);
      }
      if (email) {
        formData.append("email", email);
      }
      if (relationId) {
        formData.append("relation_id", relationId);
      }
      if (insurance) {
        formData.append("insurance_number", insurance);
      }
      if (identityUpload) {
        formData.append("identity", identityUpload);
      }
      if (notes) {
        formData.append("notes", notes);
      }
      if (isPrimaryContact) {
        formData.append("make_primary", isPrimaryContact);
      }

      ApiCalling.apiCallBodyDataPost("user/dynamic-update", formData)
        .then((res) => {
          if (res.data.status == true) {
            setIsPersonalDetailsLoading(false);
            resetPersonalDetails();
            getParentDetails();
            setIsToast(res.data.message);
          } else {
            setIsToast(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const getStudentDetails = (studentId) => {
    ApiCalling.apiCallBodyDataGet("student/details/" + studentId)
      .then((res) => {
        if (Util.isValidData(res.data)) {
          setStudentDetails(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (studentId) {
      getStudentDetails(studentId);
    }
  }, [studentId]);

  useEffect(() => {
    if (studentDetails !== null) {
      setStudentData(studentDetails);
      setPhotoURL(
        Util.isValidData(studentDetails?.photo)
          ? imageStudentList + studentDetails?.photo
          : ""
      );
    }
  }, [studentDetails]);

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);
  return (
    <>
      <div className="container-80">
        <div className="row mt-3 mb-0">
          {search === "?student" ? (
            <>
              <BreadCrumbComponent
                Breadcrumb={[
                  {
                    name: "My School",
                    navigate: () => {
                      history.push("/Dashboard");
                    },
                  },
                  {
                    name: "Student List",
                    navigate: () => {
                      history.push("/Student");
                    },
                  },
                  {
                    name: "Student Details",
                    navigate: () => {
                      history.push({
                        pathname: "/profileTabs",
                        studentId: studentId,
                      });
                    },
                  },
                  { name: `${t("Parent Details")}` },
                ]}
                history={history}
              />
              <div className="col-md-4">
                <h2 className="page-head mb-4">{t("Parent Details")}</h2>
              </div>
            </>
          ) : (
            <>
              <BreadCrumbComponent
                Breadcrumb={[
                  {
                    name: "My School",
                    navigate: () => {
                      history.push("/Dashboard");
                    },
                  },
                  {
                    name: "Parent List",
                    navigate: () => {
                      history.push("/parent");
                    },
                  },
                  { name: `${t("Parent Details")}` },
                ]}
                history={history}
              />
              <div className="col-md-4">
                <h2 className="page-head mb-4">{t("Parent Details")}</h2>
              </div>
            </>
          )}
        </div>

        {!isLoading ? (
          <>
            <div className="school-details my-2 ">
              <div className="p-4">
                <div className="d-flex align-items-center">
                  {avatarURL.length > 0 ? (
                    <div
                      className="profile-area"
                      style={{
                        position: "relative",
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        marginRight: "20px",
                      }}
                    >
                      <img
                        alt="User"
                        className="rounded-circle user-photo img-responsive  mt-1 "
                        src={avatarURL}
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                        }}
                      />{" "}
                      <span
                        className="choose-profile"
                        onClick={(e) => {
                          e.preventDefault();
                          // document.getElementById("photo").click();
                          setShowEditModal(true);
                        }}
                      >
                        <img src={camera} alt="" />
                      </span>
                    </div>
                  ) : (
                    <div
                      className="profile-area"
                      style={{
                        position: "relative",
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        marginRight: "20px",
                      }}
                    >
                      <img
                        alt="User"
                        className="img-responsive  mt-1"
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                          position: "relative",
                        }}
                        src={ParentAvtar}
                      />
                      <span
                        className="choose-profile"
                        onClick={(e) => {
                          e.preventDefault();
                          document.getElementById("photo").click();
                        }}
                      >
                        <img src={camera} alt="" />
                      </span>

                      <input
                        className="sr-only"
                        id="photo"
                        type="file"
                        accept="image/*"
                        onChange={handlePhotoChange}
                      />
                    </div>
                  )}
                  <div>
                    <h2 className="extra-large-text font-color-primary">
                      {parentData?.User?.firstName +
                        " " +
                        parentData?.User?.lastName}
                    </h2>
                    <div className="mt-2">
                      <Chip relation={relation} />
                    </div>
                  </div>
                </div>
                <EditPhotoModal
                  show={showEditModal}
                  onClose={() => {
                    setShowEditModal(false);
                    setAvatarURL(
                      Util.isValidData(parentData.User?.avatar)
                        ? imageMyProfile + parentData.User?.avatar
                        : ""
                    );
                  }}
                  title={t("Edit Photo")}
                  subTitle={t(
                    "Show profile picture the best version of yourself"
                  )}
                  onChange={t("Change Image")}
                  onSave={t("Save Photo")}
                  Id={parentId}
                  onConfirmClick={() => setShowEditModal(false)}
                  ProfilePhoto={avatarURL}
                  photoURL={avatarURL}
                  handlePhotoChange={handlePhotoChange}
                  defaultAvtar={ParentAvtar}
                  handleSavePhoto={handleSavePhoto}
                />

                <div className="inside-grays ">
                  <div className="row text-start">
                    <div className="col">
                      <p>{t("Student Name")}</p>
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "100%",
                        }}
                        className="d-flex"
                      >
                        {photoURL.length > 0 ? (
                          <img
                            src={photoURL}
                            alt=""
                            className="img-fluid mr-2"
                            style={{ borderRadius: "50%" }}
                          />
                        ) : (
                          <>
                            <img
                              src={unknownImg}
                              alt=""
                              className="img-fluid mr-2"
                            />
                          </>
                        )}

                        <span className="text-bold">
                          {" "}
                          {studentData?.first_name}
                        </span>
                      </div>
                    </div>
                    <div className="col">
                      <p>{t("Student ID")}</p>
                      <span className="text-bold">
                        {Util.isValidData(studentData?.id) &&
                          Util.studentIdFormat(studentData?.id)}
                      </span>
                    </div>
                    <div className="col">
                      <p>{t("Grade")}</p>
                      <span className="text-bold">
                        {" "}
                        {studentData?.grade?.Stage.stage}
                      </span>
                    </div>
                    <div className="col">
                      <p>{t("Room")}</p>
                      <span className="text-bold">
                        {studentData?.classroom?.name}
                      </span>
                    </div>
                  </div>
                </div>

                <hr style={{ backgroundColor: "#E5E7EB" }} className="mt-4" />

                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="medium-large-text font-color-primary py-2">
                      {t("Personal Details")}
                    </div>
                    {!personalDetailEdit && (
                      <div className="d-flex justify-content-center align-items-center">
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#6418C3",
                            cursor: "pointer",
                          }}
                          className="link-button"
                          onClick={() => {
                            setPersonalDetailEdit(true);
                            setWorkInfoEdit(false);
                            setPersonalShowAcc(true);
                          }}
                        >
                          <img src={Edit} alt="" className="edit-svg " />
                          {t("Edit")}
                        </span>
                        <div className="vertical-bar"></div>
                        <div
                          className="accoridan-btn"
                          onClick={() => setPersonalShowAcc(!personalShowAcc)}
                        >
                          <i
                            className={
                              personalShowAcc
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    )}
                  </div>

                  {personalShowAcc ? (
                    <>
                      {!isPersonalDetailsLoading ? (
                        <>
                          <div className="row text-start">
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text required">
                                  {t("First Name")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(firstName)
                                      ? firstName
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      className={`form-control custom-input ${
                                        firstNameError.length > 0
                                          ? "input-error"
                                          : ""
                                      }`}
                                      value={firstName}
                                      onChange={(e) => {
                                        setFirstName(e.target.value);
                                        setFirstNameError("");
                                      }}
                                    />
                                    {firstNameError.length > 0 && (
                                      <span className="text-danger">
                                        <img
                                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                                          src={Invalid}
                                        />
                                        {firstNameError}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text required">
                                  {t("Last Name")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(lastName)
                                      ? lastName
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      className={`form-control custom-input ${
                                        lastNameError.length > 0
                                          ? "input-error"
                                          : ""
                                      }`}
                                      value={lastName}
                                      onChange={(e) => {
                                        setLastName(e.target.value);
                                        setLastNameError("");
                                      }}
                                    />
                                    {lastNameError.length > 0 && (
                                      <span className="text-danger">
                                        <img
                                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                                          src={Invalid}
                                        />
                                        {lastNameError}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text required">
                                  {t("Email")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(email) ? email : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      className={`form-control custom-input ${
                                        emailError.length > 0
                                          ? "input-error"
                                          : ""
                                      }`}
                                      value={email}
                                      onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError("");
                                      }}
                                    />
                                    {emailError.length > 0 && (
                                      <span className="text-danger">
                                        <img
                                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                                          src={Invalid}
                                        />
                                        {emailError}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text required">
                                  {t("Mobile Number")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(mobile) ? mobile : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <div
                                      className={`form-control custom-input ${
                                        mobileError.length > 0
                                          ? "input-error"
                                          : ""
                                      }`}
                                      tabIndex={0}
                                    >
                                      <PhoneInput
                                        tabIndex="-1"
                                        international
                                        defaultCountry={defaultCountry}
                                        value={mobile}
                                        onChange={(value) => {
                                          setMobile(value);
                                          setMobileError("");
                                        }}
                                      />
                                    </div>
                                    {mobileError.length > 0 && (
                                      <span className="text-danger mt-2">
                                        <img
                                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                                          src={Invalid}
                                        />
                                        {mobileError}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text required">
                                  {t("Relation")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(relation)
                                      ? relation
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <select
                                      name=""
                                      id=""
                                      className={`select-dropdown form-select custom-input ${
                                        relationIdError.length > 0
                                          ? "input-error"
                                          : ""
                                      }`}
                                      value={relationId}
                                      onChange={(e) => {
                                        setRelationId(e.target.value);
                                        setRelationIdError("");
                                      }}
                                    >
                                      <option value={0}>{t("Select")}</option>
                                      {relationList?.map((item) => (
                                        <option value={item.id} key={item.id}>
                                          {item.type}
                                        </option>
                                      ))}
                                    </select>
                                    {relationIdError.length > 0 && (
                                      <span className="text-danger">
                                        <img
                                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                                          src={Invalid}
                                        />
                                        {relationIdError}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text">
                                  {t("Insurance Number")}
                                </span>
                                {!personalDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(insurance)
                                      ? insurance
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      className="form-control custom-input"
                                      value={insurance}
                                      onChange={(e) =>
                                        setInsurance(e.target.value)
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row text-start">
                            <div
                              className={
                                !personalDetailEdit ? "col-lg-6" : "col-lg-12"
                              }
                            >
                              <div className="input-filed ">
                                <span className="basic-text ">
                                  {t("ID Upload")}
                                </span>
                                {!personalDetailEdit ? (
                                  <>
                                    {Util.isValidData(identityFileName) ? (
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={fileIcon}
                                          className="img-responsive"
                                          style={{
                                            background: "#F3F4F6",
                                            padding: "5px",
                                            margin: "3px",
                                            borderRadius: "8px",
                                            marginLeft: 0,
                                          }}
                                        ></img>
                                        <a
                                          target="_blank"
                                          className="large-bold-text mx-1"
                                          href={
                                            identityFileName.match(
                                              /\.(jpg|jpeg|png|gif)$/i
                                            )
                                              ? userIdentity + identityFileName
                                              : studentIdentity +
                                                identityFileName
                                          }
                                        >
                                          {identityFileName}
                                        </a>
                                      </div>
                                    ) : (
                                      <div>-</div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <CustomFileUpload
                                      onChange={handleIdentityUpload}
                                      fileName={
                                        identityUpload
                                          ? identityUpload.name
                                          : identityFileName
                                      }
                                      showFile={
                                        identityURL.length > 0
                                          ? identityURL
                                          : identityFileName.match(
                                              /\.(jpg|jpeg|png|gif)$/i
                                            )
                                          ? userIdentity + identityFileName
                                          : studentIdentity + identityFileName
                                      }
                                      onDragOver={handleIdentityDragOver}
                                      onDrop={handleIdentityDrop}
                                      alertMessage={
                                        identityFileName.length > 0 &&
                                        t(
                                          "(incase you select new file it will replace old)"
                                        )
                                      }
                                      acceptedDocument={t(
                                        "PDF,Doc (max file 25mb)"
                                      )}
                                      accept={"application/pdf,.docs,.docx"}
                                      className={"custom-file"}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              className={
                                !personalDetailEdit ? "col-lg-6" : "col-lg-12"
                              }
                            >
                              <div className="input-filed ">
                                <span className="basic-text">{t("Notes")}</span>
                                {!personalDetailEdit ? (
                                  <div
                                    className="large-bold-text"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {Util.isValidData(notes) ? notes : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <textarea
                                      style={{
                                        width: "100%",
                                        minHeight: "120px",
                                      }}
                                      className="form-control custom-input"
                                      value={notes}
                                      onChange={(e) => setNotes(e.target.value)}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="text-start input-filed">
                            <span className="basic-text ">
                              {t("Primary Contact")}
                            </span>
                            {!personalDetailEdit ? (
                              <div className="large-bold-text">
                                {isPrimaryContact
                                  ? t("is primary contact")
                                  : t("is not a primary contact")}
                              </div>
                            ) : (
                              <div className="form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="flexSwitchCheckChecked"
                                  value={isPrimaryContact}
                                  checked={isPrimaryContact ? true : false}
                                  onChange={(e) => {
                                    setIsPrimaryContact(e.target.checked);
                                  }}
                                />
                                <span
                                  tabIndex={0}
                                  className="form-check-label"
                                  // htmlFor="flexSwitchCheckChecked"
                                >
                                  {t("Primary Contact")}
                                </span>
                              </div>
                            )}
                          </div>
                          {personalDetailEdit && (
                            <div className="text-end">
                              <button
                                className=" btn secondary-lg-btn rounded-btn mr-2 m-0 px-2"
                                onClick={() => {
                                  resetPersonalDetails();
                                  setPersonalShowAcc(false);
                                }}
                              >
                                {t("Cancel")}
                              </button>

                              <button
                                className=" btn primary-lg-btn rounded-btn"
                                onClick={handleSavePersonalDetails}
                              >
                                {t("Save")}
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <Loader />
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <hr style={{ backgroundColor: "#E5E7EB" }} />
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="medium-large-text font-color-primary py-2">
                      {t("Work Information")}
                    </div>

                    {!workInfoEdit && (
                      <div className="d-flex justify-content-center align-items-center">
                        <>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#6418C3",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setWorkInfoEdit(true);
                              setPersonalDetailEdit(false);
                              setWorkShowAcc(true);
                            }}
                          >
                            <img src={Edit} alt="" className="edit-svg " />
                            {t("Edit")}
                          </span>
                          <div className="vertical-bar"></div>
                          <div
                            className="accoridan-btn"
                            onClick={() => setWorkShowAcc(!workShowAcc)}
                          >
                            <i
                              className={
                                workShowAcc
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                              aria-hidden="true"
                            ></i>
                          </div>
                        </>
                      </div>
                    )}
                  </div>
                  {workShowAcc && (
                    <WorkInfo
                      workInfoEdit={workInfoEdit}
                      workInfo={parentData}
                      UserId={UserId}
                      onSave={(msg) => {
                        setWorkInfoEdit(false);
                        getParentDetails();
                        if (msg) {
                          setIsToast(msg);
                        }
                      }}
                      onCancel={() => {
                        setWorkInfoEdit(false);
                        setWorkShowAcc(false);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default ToastHOC(ParentDetails);
