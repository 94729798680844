import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dropdown, Modal } from "react-bootstrap";
import ReactPhotoCollage from "../ReactPhotoCollage/react-photo-collage/ReactPhotoCollageContainer";
import {
  FavoriteBorderOutlined,
  Favorite,
  Add,
  ModeCommentOutlined,
  PictureAsPdf,
} from "@mui/icons-material";
import moment from "moment/moment";
import Util from "../../Util";
import {
  documentPost,
  imageComment,
  imageMyProfile,
  imagePost,
  imageStudentList,
} from "../../screens/CommonImage";
import { useHistory } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import Avatar from "react-avatar";
import Moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import clock from "../../assets/Icon/clock.svg";
import Close from "../../assets/Icon/close.png";
import { Rings } from "react-loader-spinner";
import PDFPreview from "../common/PDFPreview";
import PDFModal from "../common/PDFModal";

const ActivityCard = (props) => {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const getLanguage = Util.getSelectedLanguage();
  const is_staff = props.staff;
  const [data, setData] = useState(null);
  const [likeId, setLikeId] = useState([]);
  const [likeModal, setLikeModal] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState(0);
  const [deleteActivityPopup, setDeleteActivityPopup] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(false);

  const handleCloseModal = () => {
    setIsPdfModalOpen(false);
  };

  const user = Util.getUser();
  const history = useHistory();

  const handleShow = () => {
    setLikeModal(true);
  };
  const handleClose = () => {
    setLikeModal(false);
  };
  const handleShowDelete = () => {
    setDeletePopup(true);
  };
  const handleCloseDelete = () => {
    setDeletePopup(false);
  };
  const handleShowDeleteActivity = () => {
    setDeleteActivityPopup(true);
  };
  const handleCloseDeleteActivity = () => {
    setDeleteActivityPopup(false);
  };

  // useEffect(() => {
  //   setData(props.activity);
  // }, []);

  useEffect(() => {
    setData(props.activity);
  }, [props]);

  const Enterhandler = (event, sendComment) => {
    if (event.key === "Enter") {
      props.sendComment();
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <button>
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      </button>
    </a>
  ));

  function getExtension(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();
    return extension;
  }

  function getIconClass(fileName) {
    const extension = getExtension(fileName);

    switch (extension) {
      case "xls":
        return "fa fa-file-excel-o fa-2x text-success";
      case "xlsx":
        return "fa fa-file-excel-o fa-2x text-success";
      case "pdf":
        return "fa fa-file-pdf-o fa-2x text-color-primary";
      case "doc":
        return "fa fa-file-word-o fa-2x text-color-primary";
      case "docx":
        return "fa fa-file-word-o fa-2x text-color-primary";

      default:
        return "fa fa-file fa-2x text-info";
    }
  }

  const photos = data?.ActivityMedia?.filter((media) =>
    media.name.match(/\.(jpg|jpeg|png|gif)$/i)
  );

  const videos = data?.ActivityMedia.filter((media) =>
    media.name.match(/\.(mkv|mp4)$/i)
  );

  const otherMedia = data?.ActivityMedia.filter(
    (media) => !media.name.match(/\.(jpg|jpeg|png|gif|mkv|mp4)$/i)
  );

  return (
    <div>
      {data ? (
        <>
          <div className="activity-card mb-3">
            <div className="post-header d-flex justify-content-start">
              {Util.getUserAvatar(data.CreatedBy)}

              <div className="post-details ">
                <h4 className="large-text mx-2">
                  {data.CreatedBy.firstName + " " + data.CreatedBy.lastName}
                </h4>
                <div className="d-flex align-items-center mx-2 mt-1">
                  <img src={clock} height={"16px"} width={"16px"} />
                  <span className="sm-text mx-1">
                    {props.commentSection == true ? (
                      <>
                        {moment(data?.created_on).format(
                          "DD/MM/YYYY , hh:mm A"
                        )}
                      </>
                    ) : (
                      <>{moment(data?.created_on).format(" hh:mm A")}</>
                    )}
                  </span>
                </div>
                {/* <p className="small-text mx-2">{`${moment(
                  data.created_on
                ).fromNow()}`}</p> */}
              </div>
              <div className="dots-button">
                {is_staff === true ? (
                  <>
                    {data.created_by === user.id ? (
                      <div
                        className={`${
                          Util.hasPermission("ACTIVITY_EDIT") ||
                          Util.hasPermission("ACTIVITY_DELETE")
                            ? ""
                            : "no-permission"
                        }`}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            as={CustomToggle}
                            id="dropdown-basic"
                            className="user-name"
                          ></Dropdown.Toggle>

                          <Dropdown.Menu
                            className="dropdown-menu-right account"
                            style={{
                              backgroundColor: "white",
                            }}
                          >
                            <Dropdown.Item
                              onClick={() => {
                                props.editActivity(data.id);
                              }}
                              style={{
                                display:
                                  !Util.hasPermission("ACTIVITY_EDIT") &&
                                  "none",
                              }}
                            >
                              {" "}
                              <i className="fa-regular fa-edit mr-2"></i>
                              {t("Edit")}
                            </Dropdown.Item>
                            <hr color="black" />
                            <Dropdown.Item
                              onClick={() => {
                                handleShowDeleteActivity();
                                history.push({
                                  deleteActivityId: data.id,
                                });
                              }}
                              style={{
                                display:
                                  !Util.hasPermission("ACTIVITY_DELETE") &&
                                  "none",
                              }}
                            >
                              <i className="fa-regular fa-trash-can mr-2"></i>
                              {t("Delete")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="card-content-details">
              <div
                className="basic-text activity-description text-start "
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{__html: data.description}}
              >
                {/* {Util.formatWithHyperlinks(data.description)} */}
                {/* {data.description} */}
              </div>

              <div className="justify-content-center mb-2">
                {photos?.length > 0 && (
                  <>
                    <ReactPhotoCollage
                      width="100%"
                      height={["300px", "134px"]}
                      layout={
                        photos.length > 2
                          ? [2, 4]
                          : photos.length == 2
                          ? [2]
                          : [1]
                      }
                      photos={photos?.map((photo) => ({
                        source: `${imagePost}${photo.name}`,
                      }))}
                      showNumOfRemainingPhotos={true}
                    />
                  </>
                )}
                {videos?.length > 0 && (
                  <>
                    {videos.map((media, index) => {
                      return (
                        <video
                          key={index}
                          src={documentPost + media.name}
                          width="400"
                          controls
                        >
                          <source
                            src={documentPost + media.name}
                            type="video/*"
                          />
                        </video>
                      );
                    })}
                  </>
                )}
                {otherMedia?.length > 0 && (
                  <div className="align-left d-flex overflow-hidden">
                    {otherMedia.map((media, index) => {
                      const extension = getExtension(media.name);
                      const isPDF = extension === "pdf";
                      return (
                        <div key={index} className="pr-2">
                          {isPDF && (
                            <PDFPreview
                              file={documentPost + media.name}
                              width={300}
                              setPdfUrl={setPdfUrl}
                              setIsModalOpen={setIsPdfModalOpen}
                            />
                          )}
                          {!isPDF && (
                            <a
                              href={documentPost + media.name}
                              target="_blank"
                              rel="noreferrer"
                              style={{ display: "block", width: "fit-content" }}
                            >
                              {/* <PictureAsPdf
                            fontSize="large"
                            className="text-color-primary"
                          /> */}

                              <div className="icon">
                                <i className={getIconClass(media.name)}></i>
                              </div>
                            </a>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="students d-flex align-items-center mb-2">
                {data?.ActivityStudents.slice(0, 5).map((s, idx) => {
                  return (
                    <div key={idx}>
                      {s.Stage !== null ? (
                        <span
                          className="badge badge-activity "
                          key={s.Stage.id}
                        >
                          <i className="icon-users mr-1"></i>
                          {s.Stage.stage}
                        </span>
                      ) : s.Grade !== null ? (
                        <span className="badge badge-activity" key={s.Grade.id}>
                          <i className="icon-users mr-1"></i>
                          {t("Grade")}-{s.Grade.title}
                        </span>
                      ) : s.ClassRoom !== null ? (
                        <span
                          className="badge badge-activity"
                          key={s.ClassRoom.id}
                        >
                          <i className="icon-users mr-1"></i>
                          {t("Classroom")}-{s.ClassRoom.name}
                        </span>
                      ) : (
                        s.Student !== null && (
                          <span
                            className="badge badge-activity d-flex align-items-center gap-2"
                            key={s.Student.id}
                          >
                            <img
                              className="rounded-circle"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                              src={
                                s.Student.photo
                                  ? imageStudentList + s.Student.photo
                                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                              }
                              alt=""
                            />
                            {s.Student.first_name + " " + s.Student.last_name}
                          </span>
                        )
                      )}
                    </div>
                  );
                })}

                {data?.ActivityStudents.length > 5 && (
                  <>
                    <div className="dropdown">
                      <span
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="badge badge-activity badge-activity-count"
                      >
                        {data?.ActivityStudents.length - 5 + "+"}
                      </span>

                      <ul
                        className="dropdown-menu more-student-dropdown"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {data.ActivityStudents.slice(5).map((s, index) => {
                          return (
                            <>
                              {s.Stage !== null ? (
                                <li key={index}>
                                  <a className="dropdown-item">
                                    <div className="d-flex align-items-center gap-2">
                                      <i className="icon-users mr-1"></i>
                                      <div> {s.Stage.stage}</div>
                                    </div>
                                  </a>
                                </li>
                              ) : s.Grade !== null ? (
                                <li key={index}>
                                  <a className="dropdown-item">
                                    <div className="d-flex align-items-center gap-2">
                                      <i className="icon-users mr-1"></i>
                                      <div>
                                        {" "}
                                        {t("Grade")}-{s.Grade.title}
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              ) : s.ClassRoom !== null ? (
                                <li key={index}>
                                  <a className="dropdown-item">
                                    <div className="d-flex align-items-center gap-2">
                                      <i className="icon-users mr-1"></i>
                                      <div> {s.ClassRoom.title}</div>
                                    </div>
                                  </a>
                                </li>
                              ) : (
                                s.Student !== null && (
                                  <li key={index}>
                                    <a className="dropdown-item">
                                      <div className="d-flex align-items-center gap-2">
                                        <img
                                          className="rounded-circle mr-2"
                                          height={20}
                                          width={20}
                                          src={
                                            s.Student.photo
                                              ? imageStudentList +
                                                s.Student.photo
                                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                          }
                                          alt=""
                                        />
                                        <div>
                                          {" "}
                                          {s.Student.first_name +
                                            " " +
                                            s.Student.last_name}
                                        </div>
                                      </div>
                                    </a>
                                  </li>
                                )
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="footer d-flex justify-content-between hr-line">
              <div className="">
                <span
                  className="extra-small-text"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (data.ActivityLike.length > 0) {
                      handleShow();
                      console.log("data.ActivityLike", data.ActivityLike);
                      setLikeId(data.ActivityLike);
                    }
                  }}
                >
                  {data.ActivityLike.length > 1
                    ? data.ActivityLike.length + " " + t("Likes")
                    : data.ActivityLike.length + " " + t("Like")}
                </span>
              </div>
              {props.commentSection == false ||
                (props.commentSection == undefined && (
                  <div className="">
                    <span
                      className="text-right extra-small-text"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (data.ActivityComments?.length > 0) {
                          props.activityDetails();
                        }
                      }}
                    >
                      {data.ActivityComments?.length > 1
                        ? t("View All") +
                          " " +
                          "  " +
                          data.ActivityComments?.length +
                          " " +
                          t("Comments")
                        : data.ActivityComments?.length > 0
                        ? t("View 1 Comment")
                        : t("0 Comment")}
                    </span>
                  </div>
                ))}
            </div>
            <div className="action-button d-flex justify-content-start mt-2">
              <div className="like-bx">
                {data.ActivityLike.some((element) => {
                  return element.created_by === user.id;
                }) ? (
                  <>
                    <Favorite
                      fontSize="small"
                      className="text-color-primary"
                      onClick={() => {
                        props.dislikeOnClick();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <FavoriteBorderOutlined
                      fontSize="small"
                      onClick={() => {
                        props.likeOnClick();
                      }}
                    />
                  </>
                )}
              </div>
              <div className="mx-5">
                <ModeCommentOutlined
                  fontSize="small"
                  style={{ cursor: "pointer" }}
                  onClick={props.activityDetailsPage}
                />
              </div>
            </div>

            {props.commentSection == true && (
              <>
                {data?.ActivityComments !== undefined &&
                  data?.ActivityComments?.map((c, index) => {
                    return (
                      <div className="mt-3" key={index}>
                        <div className="row">
                          <div
                            className="col-lg-12"
                            key={c.CommentedBy?.firstName}
                          >
                            <a data-target="#view_info" data-toggle="modal">
                              <Avatar
                                size="20"
                                src={
                                  c.CommentedBy.avatar === null
                                    ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                    : imageMyProfile + c.CommentedBy.avatar
                                }
                                style={{
                                  width: "45px",
                                  borderRadius: "50%",
                                  float: getLanguage == "ar" ? "right" : "left",
                                }}
                                round={true}
                              />
                            </a>

                            <ul className="chat-comment">
                              <div className="d-flex justify-content-between">
                                <span className="left float-left comment-title-text">
                                  {c.CommentedBy?.firstName +
                                    " " +
                                    c.CommentedBy?.lastName}
                                </span>
                                {/* <div className="text-muted right float-right">
                                    {moment(c.commented_on).format(
                                      Util.getMomentDateTimeFormat(
                                        school.date_format
                                      )
                                    )}
                                  </div> */}
                              </div>
                              <li className="line-style-none w-100">
                                <div className="chat-info d-flex align-item-center">
                                  <span className="message my-1 text-13 ">
                                    {c.comment}
                                  </span>
                                  {c.commented_by == user.id && (
                                    <div className=" dots-button">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="none"
                                          as={CustomToggle}
                                          id="dropdown-basic"
                                        ></Dropdown.Toggle>

                                        <Dropdown.Menu className="dropdown-menu-right">
                                          <Dropdown.Item
                                            onClick={() => {
                                              setDeletePopup(true);
                                              setDeleteCommentId(c.id);
                                            }}
                                            className="text-danger"
                                          >
                                            <i className="fa-regular fa-trash-can mr-2"></i>
                                            {t("Delete")}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  )}
                                </div>
                                <div>
                                  {c.image !== null && (
                                    <>
                                      <div className="comment-img-wrap">
                                        <img
                                          id="full-screen-img"
                                          alt="User"
                                          // className="image"
                                          src={imageComment + c.image}
                                          onClick={(event) => {
                                            const img = event.target;
                                            img.requestFullscreen();
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="d-flex justify-content-between align-item-center mt-3">
                  <div
                    className="custom-plus-button "
                    onClick={() => {
                      document.getElementById("filePhoto").click();
                    }}
                  >
                    <Add />
                  </div>
                  <div className="custom-form-wrap">
                    <div className="input-group">
                      <input
                        className="form-control custom-input-form mt-1"
                        placeholder="Type a message"
                        type="text"
                        rows="3"
                        value={props.commentValue}
                        onChange={props.commentChange}
                        ref={props.inputRefs}
                        onKeyPress={(e) => {
                          Enterhandler(e);
                        }}
                      />
                      <div className="input-group-prepend">
                        <input
                          className="sr-only"
                          id="filePhoto"
                          type="file"
                          accept="image/*"
                          onChange={props.commentPhotoChange}
                        />
                        <div className="telegram-button">
                          <i
                            className="icon-paper-plane"
                            onClick={props.sendComment}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {props.commentURL.length === 0 ? (
                  ""
                ) : (
                  <div className="row">
                    <div className="col-4">
                      <a
                        style={{ cursor: "pointer", float: "right" }}
                        onClick={props.closeCommentPhoto}
                      >
                        <CloseIcon />
                      </a>
                      <img
                        alt="User"
                        className="img-fluid p-3"
                        style={{
                          maxHeight: "140px",
                          maxWidth: "200px",
                        }}
                        src={props.commentURL}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <Modal
            size="md"
            show={deleteActivityPopup}
            onHide={handleCloseDeleteActivity}
            animation={true}
            centered={true}
            dialogClassName="modal-view"
          >
            <div className="d-flex justify-content-between align-items-center p-4 border-bottom">
              <strong>{t("Delete Post")}?</strong>

              <button
                className="hidden-btn"
                onClick={handleCloseDeleteActivity}
              >
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>

            <Modal.Body>
              <strong>{t("Are you sure you want to delete this Post")}?</strong>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-link"
                onClick={handleCloseDeleteActivity}
              >
                {t("No")}
              </button>
              <button
                className="btn custom-primary"
                onClick={() => {
                  props.deleteActivity();
                  handleCloseDeleteActivity();
                }}
              >
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            size="md"
            show={likeModal}
            onHide={handleClose}
            animation={true}
            centered={true}
            dialogClassName="modal-view"
          >
            <div className="d-flex justify-content-between align-items center p-4 border-bottom">
              <div className="d-flex align-items-center gap-1">
                <Favorite fontSize="small" className=" text-color-primary" />
                <strong>{likeId.length}</strong>
              </div>

              <button className="hidden-btn" onClick={handleClose}>
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>
            {likeId
              .filter(
                (user, index, self) =>
                  index ===
                  self.findIndex((u) => u.CreatedBy.id === user.CreatedBy.id)
              )
              .map((likedUser, index) => {
                return (
                  <>
                    <Modal.Body key={index}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={likedUser?.CreatedBy?.firstName}
                      >
                        <img
                          src={
                            likedUser?.CreatedBy?.avatar !== null
                              ? imageMyProfile + likedUser?.CreatedBy?.avatar
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                          }
                          className="rounded-circle user-photo"
                          style={{ width: "40px", height: "40px" }}
                          alt=""
                        />
                        <Favorite
                          fontSize="small"
                          className="mt-4 text-color-primary"
                          style={{
                            marginLeft: getLanguage !== "ar" && "-3%",
                            marginRight: getLanguage == "ar" && "-3%",
                          }}
                        />
                        <h6 style={{ color: "black" }}>
                          {likedUser?.CreatedBy?.firstName +
                            " " +
                            likedUser?.CreatedBy?.lastName}
                        </h6>
                      </div>
                    </Modal.Body>
                  </>
                );
              })}
          </Modal>
          <Modal
            size="sm"
            show={deletePopup}
            onHide={handleCloseDelete}
            animation={true}
            centered={true}
            dialogClassName="modal-view"
          >
            <div className="d-flex justify-content-between align-items center p-4 border-bottom">
              <strong>{t("Delete Comment")}?</strong>

              <button className="hidden-btn" onClick={handleCloseDelete}>
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>

            <Modal.Body>
              <div>{t("Are you sure you want to delete this comment")}?</div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn custom-primary"
                onClick={handleCloseDelete}
              >
                {t("No")}
              </button>
              <button
                className="btn custom-primary"
                onClick={() => {
                  props.deleteComment(deleteCommentId);
                  handleCloseDelete();
                }}
              >
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
          <PDFModal
            isOpen={isPdfModalOpen}
            onClose={handleCloseModal}
            url={pdfUrl}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default ActivityCard;
