import { Avatar, Box, IconButton, SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../components/common/Loader";
import ApiCalling from "../../../network/ApiCalling";
import Invalid from "../../../assets/Icon/invalid.svg";
import Search from "../../../assets/Icon/Search.svg";
import emptyCheck from "../../../assets/Icon/check-box-empty.svg";
import check from "../../../assets/Icon/check-box.svg";
import { imageStudentList } from "../../CommonImage";
import ExitAlert from "../../../components/ExitAlert";
import Close from "../../../assets/Icon/close.png";

const AssignTeacher = ({
  show,
  onClose,
  roomDetails,
  editSubject,
  teacherData,
  onSave,
  subjectDetails,
}) => {
  const getLanguage = Util.getSelectedLanguage();
  const { t } = useTranslation();
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stepper, setStepper] = useState(0);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectId, setSubjectId] = useState(0);
  const [subjectIdError, setSubjectIdError] = useState("");
  const [selectedTeacherId, setSelectedTeacherId] = useState([]);
  const [search, setSearch] = useState("");
  const [teacherList, setTeacherList] = useState([]);
  const [filteredTeacherList, setFilteredTeacherList] = useState([]);
  const [isTeacherLoading, isSetTeacherLoading] = useState("");
  const [selectedTeachersError, setSelectedTeachersError] = useState("");
  const [selectedCoTeacherId, setSelectedCoTeacherId] = useState([]);
  const [selectedCoTeachersError, setSelectedCoTeachersError] = useState("");
  const [coTeacherList, setCoTeacherList] = useState([]);
  const [filteredCoTeacherList, setFilteredCoTeacherList] = useState([]);

  useEffect(() => {
    getSubjectList();
    staffList();
  }, []);

  //   useEffect(() => {
  //     if (subjectDetails && subjectList && show.show) {
  //       const newsubjectdata = subjectList.filter((s) => {
  //         if (editSubject === null) {
  //           return !subjectDetails.some((sub) => sub.Subject.id === s.id);
  //         } else {
  //           return (
  //             !subjectDetails.some((sub) => sub.Subject.id === s.id) ||
  //             s.id === editSubject.Subject.id
  //           );
  //         }
  //       });
  //         setSubjectList(newsubjectdata);
  //     }
  //   }, [subjectDetails, subjectList, show.show]);

  useEffect(() => {
    if (show.isEdit) {
      setSubjectId(editSubject?.Subject?.id);
      const selectedTeacherValues = teacherData
        .filter(
          (val) =>
            val.class_subject_id === editSubject.id &&
            val.is_co_teacher === false
        )
        .map((data) => data.User.id);
      setSelectedTeacherId(selectedTeacherValues);

      const selectedCoTeacherValues = teacherData
        .filter(
          (val) =>
            val.class_subject_id === editSubject.id &&
            val.is_co_teacher === true
        )
        .map((data) => data.User.id);
      setSelectedCoTeacherId(selectedCoTeacherValues);
    }
  }, [show.isEdit]);

  const getSubjectList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("subject/list")
      .then((res) => {
        setIsLoading(false);
        setSubjectList(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const staffList = async () => {
    isSetTeacherLoading(true);
    const data = {
      page_size: 1000,
      page_number: 1,
      role_id: 0,
      str_search: "",
    };
    ApiCalling.apiCallBodyDataPost("user/list", data)
      .then((res) => {
        setTeacherList(res.data.data);
        setCoTeacherList(res.data.data);
        setFilteredTeacherList(res.data.data);
        isSetTeacherLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleSubjectChange = (e) => {
    setSubjectId(e.target.value);
    setSubjectIdError("");
  };

  const handleAllSelectedTeacher = (e) => {
    if (e.target.checked) {
      setSelectedTeacherId(teacherList.map((item) => item.id));
    } else {
      setSelectedTeacherId([]);
    }
    setIsStateUpdated(true);
  };

  const handleRowClick = (id) => {
    if (!selectedTeacherId.includes(id)) {
      setSelectedTeacherId([...selectedTeacherId, id]);
    } else {
      const data = selectedTeacherId.filter((s) => s !== id);
      setSelectedTeacherId(data);
    }
    setSelectedTeachersError("");
    setIsStateUpdated(true);
  };

  const handleAllSelectedCoTeacher = (e) => {
    if (e.target.checked) {
      setSelectedCoTeacherId(coTeacherList.map((item) => item.id));
    } else {
      setSelectedCoTeacherId([]);
    }
    setIsStateUpdated(true);
  };

  const handleRowClick1 = (id) => {
    if (!selectedCoTeacherId.includes(id)) {
      setSelectedCoTeacherId([...selectedCoTeacherId, id]);
    } else {
      const data = selectedCoTeacherId.filter((s) => s !== id);
      setSelectedCoTeacherId(data);
    }
    setSelectedCoTeachersError("");
    setIsStateUpdated(true);
  };

  const updateCoTeacherList = () => {
    const updatedList = coTeacherList.filter(
      (s) => !selectedTeacherId.includes(s.id)
    );
    setFilteredCoTeacherList(updatedList);
    setCoTeacherList(updatedList);
  };

  const handleSave = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (show.isEdit) {
      const data = {
        id: editSubject.id,
        room_id: roomDetails && roomDetails?.room.id,
        user_ids: selectedTeacherId.join(","),
        co_user_ids: selectedCoTeacherId.join(","),
        subject_id: Number(subjectId),
      };
      console.log(data);
      ApiCalling.apiCallBodyDataPost("class-subject/edit-subject-teacher", data)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status === true) {
            onSave("Successfully Updated Subject & Teacher");
            reset();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      const data = {
        room_id: roomDetails && roomDetails?.room.id,
        user_ids: selectedTeacherId.join(","),
        co_user_ids: selectedCoTeacherId.join(","),
        subject_id: Number(subjectId),
      };
      ApiCalling.apiCallBodyDataPost("class-subject/add-subject-teacher", data)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status === true) {
            onSave("Successfully Added Subject & Teacher");
            reset();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  const reset = () => {
    setSubjectId(0);
    setSelectedTeacherId([]);
    setSelectedCoTeacherId([]);
    setStepper(0);
  };

  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage === "ar" ? "left" : "right"}
        open={show.show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            onClose();
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "656px",
              padding: "20px",
              position: "relative",
            }}
            role="presentation"
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">
                {show.isEdit
                  ? "Edit Subject & Teachers"
                  : t("Add Subject & Teachers")}
              </div>
              {/* <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    onClose();
                    reset();
                    setIsStateUpdated(false);
                  }
                }}
              >
                <CloseIcon />
              </IconButton> */}
              <button
                className="hidden-btn"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    onClose();
                    reset();
                    setIsStateUpdated(false);
                  }
                }}
              >
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>

            <div>
              <div className="body mt-3">
                <div
                  className="row text-start mt-2"
                  style={{ paddingBottom: "50px" }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center mb-1"
                    style={{ fontWeight: "600", fontSize: "16px" }}
                  >
                    <div>
                      {stepper === 0
                        ? t("Select Subject")
                        : stepper === 1
                        ? t("Select Teacher")
                        : t("Select Co Teacher")}
                    </div>
                    <div>{stepper === 0 ? 1 : stepper === 1 ? 2 : 3}/3</div>
                  </div>

                  <div className="d-flex align-items-center gap-2">
                    <div className="active-stepper"></div>
                    <div
                      className={
                        stepper == 1 || stepper == 2
                          ? "active-stepper"
                          : "inactive-stepper"
                      }
                    ></div>
                    <div
                      className={
                        stepper == 2 ? "active-stepper" : "inactive-stepper"
                      }
                    ></div>
                  </div>

                  {!isLoading ? (
                    <>
                      {stepper == 0 ? (
                        <>
                          <div className="mt-3">
                            <span className="required">
                              {t("Select the Subject")}
                            </span>
                            <div className="input-group">
                              <select
                                className={`select-dropdown form-select custom-input ${
                                  subjectIdError.length > 0 ? "input-error" : ""
                                }`}
                                value={subjectId}
                                onChange={handleSubjectChange}
                              >
                                <option value={0}>{t("Select Subject")}</option>
                                {subjectList?.map((item) => (
                                  <option value={item.id} key={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {subjectIdError.length > 0 && (
                              <div className="invalid-error px-0">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {subjectIdError}
                              </div>
                            )}
                          </div>
                        </>
                      ) : stepper == 1 ? (
                        <>
                          <div className="input filed text-start my-3">
                            <span className="basic-text required">
                              {t("Select Teachers")}
                            </span>

                            <div className="input-group mt-3">
                              <input
                                className="form-control search"
                                placeholder="Search teachers"
                                type="text"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                  const searchValue = e.target.value;
                                  if (searchValue.trim() === "") {
                                    setFilteredTeacherList(teacherList);
                                  } else {
                                    const filteredTeacher = teacherList.filter(
                                      (s) =>
                                        s.firstName
                                          .toLowerCase()
                                          .includes(
                                            searchValue.toLowerCase()
                                          ) ||
                                        s.lastName
                                          .toLowerCase()
                                          .includes(searchValue.toLowerCase())
                                    );
                                    setFilteredTeacherList(filteredTeacher);
                                  }
                                }}
                              />
                              <div className="input-group-prepend">
                                <div
                                  className="search-icon"
                                  style={{ paddingRight: "13px" }}
                                  // onClick={searchClicked}
                                >
                                  <img
                                    src={Search}
                                    className="svg-icon"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {!isTeacherLoading ? (
                            <>
                              <div className="table-container mt-3">
                                <div className="table-wrapper">
                                  <table className="table">
                                    <thead>
                                      <tr className="text-nowrap">
                                        <td
                                          scope="col"
                                          className="check-col"
                                          style={{
                                            paddingRight: "0px",
                                            width: 0,
                                          }}
                                        >
                                          <label className="fancy-checkbox element-left">
                                            <input
                                              type="checkbox"
                                              name="allselect"
                                              onChange={
                                                handleAllSelectedTeacher
                                              }
                                              checked={
                                                selectedTeacherId.length > 0 &&
                                                selectedTeacherId.length ===
                                                  filteredTeacherList.length
                                              }
                                            />
                                            <span tabIndex={0}></span>
                                          </label>
                                        </td>
                                        <th scope="col">
                                          {selectedTeacherId.length > 0
                                            ? selectedTeacherId.length +
                                              " " +
                                              t("Teacher Selected")
                                            : t("Teacher")}
                                        </th>
                                      </tr>
                                    </thead>
                                    {filteredTeacherList.length > 0 ? (
                                      <tbody>
                                        {filteredTeacherList
                                          // .sort((a, b) =>
                                          //   selectedTeacherId.includes(a.id)
                                          //     ? -1
                                          //     : selectedTeacherId.includes(b.id)
                                          //     ? 1
                                          //     : 0
                                          // )
                                          .map((item) => {
                                            return (
                                              <tr
                                                key={item.id}
                                                style={{
                                                  backgroundColor: `${
                                                    selectedTeacherId.includes(
                                                      item.id
                                                    )
                                                      ? "rgba(100, 24, 195, 0.04)"
                                                      : ""
                                                  }`,
                                                }}
                                                onClick={() =>
                                                  handleRowClick(item.id)
                                                }
                                              >
                                                <td
                                                  style={{
                                                    cursor: "pointer",
                                                    paddingRight: "0px",
                                                    width: 0,
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      selectedTeacherId.includes(
                                                        item.id
                                                      )
                                                        ? check
                                                        : emptyCheck
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    {item.avatar ? (
                                                      <img
                                                        className="rounded-circle "
                                                        style={{
                                                          width: "40px",
                                                          height: "40px",
                                                        }}
                                                        src={
                                                          imageStudentList +
                                                          item.avatar
                                                        }
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <Avatar
                                                        aria-label="recipe"
                                                        className="avatar-font"
                                                      >
                                                        {item.firstName.toUpperCase()[0] +
                                                          item.lastName.toUpperCase()[0]}
                                                      </Avatar>
                                                    )}
                                                    <a
                                                      className="ml-2"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {item.firstName}{" "}
                                                      {item.lastName}
                                                    </a>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    ) : (
                                      <tfoot>
                                        <tr>
                                          <td colSpan={2}>
                                            {t("Teacher Not Found")}
                                          </td>
                                        </tr>
                                      </tfoot>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </>
                          ) : (
                            <Loader />
                          )}
                        </>
                      ) : (
                        <>
                          <div className="input filed text-start my-3">
                            <span className="basic-text">
                              {t("Select Co Teachers")}
                            </span>

                            <div className="input-group mt-3">
                              <input
                                className="form-control search"
                                placeholder="Search teachers"
                                type="text"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                  const searchValue = e.target.value;
                                  if (searchValue.trim() === "") {
                                    setFilteredCoTeacherList(coTeacherList);
                                  } else {
                                    const filteredTeacher =
                                      coTeacherList.filter(
                                        (s) =>
                                          s.firstName
                                            .toLowerCase()
                                            .includes(
                                              searchValue.toLowerCase()
                                            ) ||
                                          s.lastName
                                            .toLowerCase()
                                            .includes(searchValue.toLowerCase())
                                      );
                                    setFilteredCoTeacherList(filteredTeacher);
                                  }
                                }}
                              />
                              <div className="input-group-prepend">
                                <div
                                  className="search-icon"
                                  style={{ paddingRight: "13px" }}
                                  // onClick={searchClicked}
                                >
                                  <img
                                    src={Search}
                                    className="svg-icon"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {!isTeacherLoading ? (
                            <>
                              <div className="table-container mt-3">
                                <div className="table-wrapper">
                                  <table className="table">
                                    <thead>
                                      <tr className="text-nowrap">
                                        <td
                                          scope="col"
                                          className="check-col"
                                          style={{
                                            paddingRight: "0px",
                                            width: 0,
                                          }}
                                        >
                                          <label className="fancy-checkbox element-left">
                                            <input
                                              type="checkbox"
                                              name="allselect"
                                              onChange={
                                                handleAllSelectedCoTeacher
                                              }
                                              checked={
                                                selectedCoTeacherId.length >
                                                  0 &&
                                                selectedCoTeacherId.length ===
                                                  filteredCoTeacherList.length
                                              }
                                            />
                                            <span tabIndex={0}></span>
                                          </label>
                                        </td>
                                        <th scope="col">
                                          {selectedCoTeacherId.length > 0
                                            ? selectedCoTeacherId.length +
                                              " " +
                                              t("Co Teacher Selected")
                                            : t("Co Teacher")}
                                        </th>
                                      </tr>
                                    </thead>
                                    {filteredCoTeacherList.length > 0 ? (
                                      <tbody>
                                        {filteredCoTeacherList
                                          // .sort((a, b) =>
                                          //   selectedCoTeacherId.includes(a.id)
                                          //     ? -1
                                          //     : selectedCoTeacherId.includes(
                                          //         b.id
                                          //       )
                                          //     ? 1
                                          //     : 0
                                          // )
                                          .map((item) => {
                                            return (
                                              <tr
                                                key={item.id}
                                                style={{
                                                  backgroundColor: `${
                                                    selectedCoTeacherId.includes(
                                                      item.id
                                                    )
                                                      ? "rgba(100, 24, 195, 0.04)"
                                                      : ""
                                                  }`,
                                                }}
                                                onClick={() =>
                                                  handleRowClick1(item.id)
                                                }
                                              >
                                                <td
                                                  style={{
                                                    cursor: "pointer",
                                                    paddingRight: "0px",
                                                    width: 0,
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      selectedCoTeacherId.includes(
                                                        item.id
                                                      )
                                                        ? check
                                                        : emptyCheck
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    {item.avatar ? (
                                                      <img
                                                        className="rounded-circle "
                                                        style={{
                                                          width: "40px",
                                                          height: "40px",
                                                        }}
                                                        src={
                                                          imageStudentList +
                                                          item.avatar
                                                        }
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <Avatar
                                                        aria-label="recipe"
                                                        className="avatar-font"
                                                      >
                                                        {item.firstName.toUpperCase()[0] +
                                                          item.lastName.toUpperCase()[0]}
                                                      </Avatar>
                                                    )}
                                                    <a
                                                      className="ml-2"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {item.firstName}{" "}
                                                      {item.lastName}
                                                    </a>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    ) : (
                                      <tfoot>
                                        <tr>
                                          <td colSpan={2}>
                                            {t("Co Teacher Not Found")}
                                          </td>
                                        </tr>
                                      </tfoot>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </>
                          ) : (
                            <Loader />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Loader />
                    </>
                  )}
                </div>
              </div>
              <div
                className="border-top "
                style={{
                  position: "fixed",
                  width: "656px",
                  bottom: "0px",
                  right: getLanguage !== "ar" && "0px",
                  background: "#ffffff",
                  padding: "10px",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  {stepper == 0 ? (
                    <button
                      className="secondary-md-btn btn ml-2"
                      onClick={() => {
                        // isClose();
                        // reset();
                      }}
                    >
                      {t("Cancel")}
                    </button>
                  ) : (
                    <button
                      className="secondary-md-btn btn mr-2"
                      onClick={() => {
                        setStepper((prv) => prv - 1);
                        setSubjectIdError("");
                      }}
                    >
                      {t("Previous")}
                    </button>
                  )}

                  {stepper == 1 || stepper == 0 ? (
                    <div className="d-flex align-item center justify-content-end gap-2">
                      {selectedTeachersError.length > 0 && (
                        <span
                          className="text-danger"
                          style={{ float: "right", margin: "12px" }}
                        >
                          {selectedTeachersError}
                        </span>
                      )}
                      <button
                        className="primary-lg-btn btn"
                        onClick={() => {
                          if (subjectId !== 0 && stepper === 0) {
                            setStepper((prv) => prv + 1);
                          } else if (stepper === 0 && subjectId === 0) {
                            setSubjectIdError("Please Select Subject");
                          }
                          if (stepper === 1 && selectedTeacherId.length !== 0) {
                            updateCoTeacherList();
                            setStepper((prv) => prv + 1);
                          } else if (
                            stepper === 1 &&
                            selectedTeacherId.length === 0
                          ) {
                            setSelectedTeachersError("Please Select Teachers");
                          }
                        }}
                      >
                        {t("Continue")}
                      </button>
                    </div>
                  ) : (
                    <>
                      {stepper == 2 && (
                        <div className="d-flex justify-content-end align-items center">
                          <div>
                            <button
                              className={`${
                                Util.hasPermission("ANNOUNCEMENT_EDIT")
                                  ? "primary-sm-btn btn mr-1"
                                  : "no-permission"
                              }`}
                              style={{ float: "right" }}
                              onClick={handleSave}
                            >
                              {t("Save")}
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </div>
        <ExitAlert
          visible={alertModal}
          onClose={() => {
            setIsAlertModal(false);
            setIsStateUpdated(false);
          }}
          onOk={() => {
            onClose();
            setIsAlertModal(false);
            reset();
            setIsStateUpdated(false);
          }}
        />
      </SwipeableDrawer>
    </div>
  );
};

export default AssignTeacher;
