import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ApiCalling from "../../network/ApiCalling";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Modal, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import ToastHOC from "../HOC/ToastHOC";
import moment from "moment";
import Select from "react-dropdown-select";
import Util from "../../Util";
import { useTranslation, withTranslation } from "react-i18next";
import "../Dashbord/DashboardCustom.css";
import Stripe from "../../assets/Icon/stripe.png";
import Edit from "../../assets/Icon/Edit.svg";
import Delete from "../../assets/Icon/delete.svg";
import Close from "../../assets/Icon/close.png";
import AddEditEvent from "./AddEditEvent";
import ModalComponent from "../Dashbord/ModalComponent";
import { SeparateDot } from "../../components/common/separateDot";
import Loader from "../../components/common/Loader";
import { useSelector } from "react-redux";
import UpcomingEventCard from "../Dashbord/Home/UpcomingEventCard";

function Calendar(props) {
  console.log(props);
  const { setIsSubToast, setIsToast } = props;
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const ParentMode = Util.getParentMode();
  const getLanguage = Util.getSelectedLanguage();
  const { isEventModal } = props;

  const selectedStudent = useSelector((state) => state.SelectedStudentReducer);

  const [isLoading, setIsLoading] = useState(false);

  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [eventsInFormat, setEventsInFormat] = useState([]);
  const [grade, setGrade] = useState([]);
  const [classList, setClassList] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [filterClassId, setFilterClassId] = useState(0);
  const [filterGradeId, setFilterGradeId] = useState(0);
  const [filterTypeId, setFilterTypeId] = useState(0);
  const [calendarStartDate, setCalendarStartDate] = useState(null);
  const [calendarEndDate, setCalendarEndDate] = useState(null);

  const [eventTypes, setEventTypes] = useState([]);
  const [publicHoliday, setPublicHoliday] = useState(false);
  const [holidayId, setHolidayId] = useState(null);
  const [holidayEvent, setHolidayEvent] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [showEventModal, setShowEventModal] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [dayView, setDayView] = useState(false);
  const [weekView, setWeekView] = useState(false);
  const [monthView, setMonthView] = useState(true);
  const [weekDates, setWeekDates] = useState("");

  const calendarRef = useRef();

  const handlePrevClick = () => {
    setEventsInFormat([]);
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    const newDate = calendarApi.getDate();
    const currentWeek = calendarApi.currentData.viewTitle;
    setCurrentMonth(newDate);
    setWeekDates(currentWeek);
  };

  const handleNextClick = () => {
    setEventsInFormat([]);
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    const newDate = calendarApi.getDate();
    const currentWeek = calendarApi.currentData.viewTitle;
    setCurrentMonth(newDate);
    setWeekDates(currentWeek);
  };

  const handleDayView = () => {
    setEventsInFormat([]);
    setDayView(true);
    setWeekView(false);
    setMonthView(false);
    calendarRef.current.getApi().changeView("timeGridDay");
  };

  const handleMonthView = () => {
    setEventsInFormat([]);
    setMonthView(true);
    setDayView(false);
    setWeekView(false);
    calendarRef.current.getApi().changeView("dayGridMonth");
  };

  const handleWeekView = () => {
    setEventsInFormat([]);
    calendarRef.current.getApi().changeView("timeGridWeek");
    const currentWeek = calendarRef.current.getApi().currentData.viewTitle;
    setWeekView(true);
    setMonthView(false);
    setDayView(false);
    setWeekDates(currentWeek);
  };

  const newEvent = (arg, info) => {
    const isHoliday = holidayEvent.some(
      (eventDate) => moment(eventDate).format("YYYY-MM-DD") === arg.dateStr
    );
    if (isHoliday === true) {
      setIsToast(t("Cannot add an event on a holiday"));
    } else {
      setShowPopup(true);
      setEvent(null);
    }
  };

  const handleCloseDeletePopup = () => {
    setShowDeletePopup(false);
  };

  const renderBgCell = (arg) => {
    const cellEl = arg.el;
    if (arg.date.getUTCDay() === 5 || arg.date.getUTCDay() === 6) {
      cellEl.style.backgroundImage = `url(${Stripe})`;
    }
    const tableRows = document.querySelectorAll(
      ".fc-daygrid-body > table > tbody > tr"
    );
    tableRows.forEach((row, index) => {
      const extraCell = row.firstChild;
      if (extraCell) {
        if (index % 6 === 5 && extraCell?.classList?.contains("fc-day-other")) {
          row.style.display = "none";
          // const tdWithDayOther = lastCell.querySelector('.fc-day-other');

          // // If the last cell contains fc-day-other class, hide the entire row
          // if (tdWithDayOther) {
          //   row.style.display = 'none';
          // }
        }
      }
    });
  };

  const editEvent = (ev) => {
    setEvent(ev);
    setShowEventModal(false);
    setShowPopup(true);
  };

  const showEvent = (info) => {
    setShowEventModal(true);
    events
      .filter((val) => val.event.id == info.event._def.publicId)
      .map((v) => {
        setEvent(v.event);
      });
  };

  const changeClassFilter = (data) => {
    setEventsInFormat([]);
    setFilterClassId(data[0].id);
  };

  const changeGradeFilter = (data) => {
    setEventsInFormat([]);
    setFilterGradeId(data[0].id);
  };

  const changeTypeFilter = (data) => {
    setEventsInFormat([]);
    setFilterTypeId(data[0].id);
  };

  const getClassList = () => {
    ApiCalling.apiCallParamsGet("classroom/list")
      .then((res) => {
        const modifiedClassList = res.data.map((c) => ({
          id: c.id,
          name: c.name,
        }));
        setClassList(modifiedClassList);
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  const gradeList = () => {
    ApiCalling.apiCallParamsGet("grade/list")
      .then((res) => {
        setGrade(res.data);
      })
      .catch((err) => {
        return err;
      });
  };

  const eventTypeList = () => {
    ApiCalling.apiCallParamsGet("event/types")
      .then((res) => {
        setEventTypes(res.data);
        res.data
          .filter((ele) => ele.id === 2)
          .map((val) => {
            setHolidayId(val.id);
          });
      })
      .catch((err) => {
        return err;
      });
  };

  const getEvents = () => {
    setIsLoading(true);
    const startDate = moment(calendarStartDate).format("YYYY/MM/DD");
    const endDate = moment(calendarEndDate).format("YYYY/MM/DD");
    const data = {
      start_date: startDate,
      end_date: endDate,
      grade_id: filterGradeId,
      classroom_id: filterClassId,
      event_type_id: filterTypeId,
      only_public: publicHoliday ? "true" : "false",
    };
    ApiCalling.apiCallBodyDataPost(`event/list`, data)
      .then((res) => {
        // console.log("Event data:", res.data);
        setIsLoading(false);
        var arr = [];
        res.data.forEach((element) => {
          if (element.event.isfullday) {
            var obj = {
              id: element.event.id,
              title: element.event.title,
              allDay: true,
              start: element.dd,
              backgroundColor: element.event.EventType.color_code,
              description: element.event.description,
            };
          } else {
            const startDate =
              element?.dd.substring(0, 11) +
              element?.event.start_time +
              element?.dd.substring(16, element?.dd.length);
            const endDate = element.event.end_time
              ? element?.dd.substring(0, 11) +
                element?.event.end_time +
                element?.dd.substring(16, element?.dd.length)
              : startDate;

            var obj = {
              id: element.event.id,
              title: element.event.title,
              allDay: monthView ? true : false,
              start: startDate,
              end: endDate,
              backgroundColor: element.event.EventType.color_code,
              description: element.event.description,
            };
          }
          arr.push(obj);
        });
        const dates = res.data
          .filter((val) => val.event.EventType.id === holidayId)
          .map((val) => val.dd);
        setEventsInFormat(arr);
        setEvents(res.data);
        setHolidayEvent(dates);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getUpcomingEvents = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`event/next-ten-days`)
      .then((res) => {
        if (res.data.status === true) {
          setIsLoading(false);
          setUpcomingEvents(res.data.response);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getParentEventList = () => {
    // debugger;
    const startDate = moment(calendarStartDate).format("YYYY/MM/DD");
    const endDate = moment(calendarEndDate).format("YYYY/MM/DD");
    const data = {
      start_date: startDate,
      end_date: endDate,
    };
    ApiCalling.apiCallBodyDataPost(
      `event/student-events/${selectedStudent?.selectedStudent?.id}`,
      data
    )
      .then((res) => {
        var arr = [];
        res.data.forEach((element) => {
          if (element.event.isfullday) {
            var obj = {
              id: element.event.id,
              title: element.event.title,
              allDay: true,
              start: element.dd,
              backgroundColor: element.event.EventType.color_code,
            };
          } else {
            var obj = {
              id: element.event.id,
              title: element.event.title,
              allDay: false,
              start: new Date(element.dd + " " + element.event.start_time),
              end: new Date(element.dd + " " + element.event.end_time),
              backgroundColor: element.event.EventType.color_code,
            };
          }
          arr.push(obj);
        });
        setEventsInFormat(arr);
        setEvents(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getParentUpcomingEvents = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(
      `event/next-ten-days/${selectedStudent?.selectedStudent?.id}`,
      selectedStudent?.selectedStudent?.student?.School?.id
    )
      .then((res) => {
        // console.log("upcoming event:", res.data);
        setIsLoading(false);
        setUpcomingEvents(res.data.response);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };

  const deleteEvent = () => {
    setShowDeletePopup(true);
    setShowEventModal(false);
    setIsLoading(false);
  };

  const handleDelete = () => {
    ApiCalling.apiCallDelete(`event/${event.id}`)
      .then((res) => {
        setEventsInFormat([]);
        setIsToast(t("Delete Event Success!"));
        setIsSubToast(t("You have successfully Deleted this event"));
        getEvents();
        getUpcomingEvents();
      })
      .catch((err) => {
        console.log("Error", err);
      });
    setShowDeletePopup(false);
  };

  const handleDatesSet = (arg) => {
    const calendarEndDate = moment(arg.view.currentEnd).subtract(1, "day");
    setCalendarStartDate(arg?.view?.currentStart);
    setCalendarEndDate(calendarEndDate);
  };

  const updateDisplayEventTime = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const currentViewType = calendarApi.view.type;

      if (
        currentViewType === "dayGridMonth" ||
        currentViewType === "timeGridWeek"
      ) {
        calendarApi.setOption("displayEventTime", true);
      } else {
        calendarApi.setOption("displayEventTime", false);
      }
    }
  };

  const customDayHeaderContent = (info) => {
    const currentDate = new Date();
    const isCurrentDate =
      info.date.toDateString() === currentDate.toDateString();

    if (isCurrentDate) {
      return (
        <div>
          {info.date.toLocaleString("default", { weekday: "short" })}
          <span className="day-date">{info.date.getDate()}</span>
        </div>
      );
    }
    return (
      <div style={{ fontSize: "14px" }}>
        {info.date.toLocaleString("default", { weekday: "short" })}
        <span className="mx-2">{info.date.getDate()}</span>
      </div>
    );
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are 0-based
    const day = currentDate.getDate();
    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
  };

  const highlightCurrentDate = (info) => {
    const currentDateString = getCurrentDate();
    if (info.dateStr === currentDateString) {
      info.dayEl.classList.add("highlight"); // Add a CSS class to highlight the current date
    }
  };

  const eventDidMount = ({ el }) => {
    if (el.classList.contains("fc-highlight")) {
      const dayTopElement = el.closest(".fc-daygrid-day-top");
      if (dayTopElement) {
        dayTopElement.classList.add("highlighted-day");
      }
    }
  };

  const daysOfWeek = {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  };

  const getRepeatDays = () => {
    return Object.keys(daysOfWeek).filter((day) => event[day] === true);
  };

  const renderEventContent = (eventInfo) => {
    if (eventInfo.view.type != "dayGridMonth") {
      return (
        <div>
          <div className="event-title wrapTwoLine">
            {eventInfo.event._def.title}
          </div>
          <div className="event-description wrapTwoLine">
            {Util.isValidData(eventInfo.event._def.extendedProps.description) &&
              eventInfo.event._def.extendedProps.description}
          </div>
        </div>
      );
    }
    return null; // Return null for other view types
  };

  useEffect(() => {
    if (ParentMode && calendarEndDate !== null && calendarStartDate !== null) {
      if (
        Util.isValidData(selectedStudent) &&
        selectedStudent?.selectedStudent?.id > 0
      ) {
        getParentEventList();
        getParentUpcomingEvents();
      }
    }
  }, [calendarStartDate, calendarEndDate, selectedStudent]);

  useEffect(() => {
    if (ParentMode) {
      if (
        Util.isValidData(selectedStudent) &&
        selectedStudent?.selectedStudent?.id > 0
      ) {
        getParentUpcomingEvents();
      }
    }
  }, [selectedStudent]);

  useEffect(() => {
    if (!ParentMode) {
      getUpcomingEvents();
      gradeList();
      getClassList();
      eventTypeList();
      updateDisplayEventTime();
    }

    // school = Util.getSelectedSchool(); // Not sure what this line does
  }, []);

  useEffect(() => {
    if (!ParentMode) {
      if (
        Util.isValidData(calendarStartDate) &&
        Util.isValidData(calendarEndDate)
      ) {
        getEvents();
      }
    }
  }, [
    calendarStartDate,
    calendarEndDate,
    filterClassId,
    filterGradeId,
    filterTypeId,
  ]);

  useEffect(() => {
    setMonthView(true);
  }, []);

  return (
    <div>
      <div style={{ flex: 1 }}>
        <div style={{ opacity: isEventModal ? 0.3 : 1 }}>
          <div className="container-fluid p-3">
            <div className="body mx-4">
              <div className="row mb-3 py-3">
                <div className="col-md-6 text-start">
                  <h2 className="page-head">{t("Calendar")}</h2>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-8 d-flex justify-content-between align-items-center">
                  <div className="left-bx d-flex align-items-center">
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {dayView ? (
                        <>
                          {!ParentMode
                            ? moment(currentMonth).format(
                                Util.getMomentDateTimeFormat(school.date_format)
                              )
                            : moment(currentMonth).format("DD/MM/YYYY")}
                        </>
                      ) : weekView ? (
                        weekDates
                      ) : (
                        moment(currentMonth).format("MMMM YYYY")
                      )}
                    </span>
                    <button
                      style={{
                        color: "black",
                        borderTopLeftRadius: 3,
                        borderBottomLeftRadius: 3,
                        backgroundColor: "transparent ",
                      }}
                      onClick={handlePrevClick}
                    >
                      <KeyboardArrowLeftIcon />
                    </button>
                    <button
                      style={{
                        color: "black",
                        borderTopRightRadius: 3,
                        borderBottomRightRadius: 3,
                        backgroundColor: "transparent ",
                      }}
                      onClick={handleNextClick}
                    >
                      <KeyboardArrowRightIcon />
                    </button>
                  </div>
                  {!ParentMode && (
                    <div className="right-bx d-flex">
                      <div className="dropdown-bx">
                        <Select
                          searchable={false}
                          className="select-custom "
                          options={[{ id: 0, type: "Type" }, ...eventTypes]}
                          onChange={(values) => changeTypeFilter(values)}
                          labelField="type"
                          values={[{ id: 0, type: "Type" }]}
                          valueField="id"
                        />
                      </div>
                      <div className="dropdown-bx">
                        <Select
                          searchable={false}
                          className="select-custom "
                          options={[{ id: 0, title: "Grade" }, ...grade]}
                          onChange={(values) => changeGradeFilter(values)}
                          labelField="title"
                          values={[{ id: 0, title: "Grade" }]}
                          valueField="id"
                        />
                      </div>
                      <div className="dropdown-bx">
                        <Select
                          searchable={false}
                          className="select-custom"
                          options={[{ id: 0, name: "Room" }, ...classList]}
                          onChange={(values) => changeClassFilter(values)}
                          labelField="name"
                          values={[{ id: 0, name: "Room" }]}
                          valueField="id"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-4 d-flex mt-lg-0 mt-md-3 justify-content-between align-items-center pl-0 pr-0 m-0 position-relative">
                  <div className="bx-left">
                    <div
                      className="btn-group-toggle dayweekmonth "
                      role="group"
                      data-toggle="buttons"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        className={`day-week-btn ${dayView && "active-btn"}`}
                        onClick={handleDayView}
                      >
                        {t("Day")}
                      </button>
                      <button
                        type="button"
                        className={`day-week-btn ${weekView && "active-btn"}`}
                        onClick={handleWeekView}
                      >
                        {t("Week")}
                      </button>
                      <button
                        type="button"
                        className={`day-week-btn ${monthView && "active-btn"}`}
                        onClick={handleMonthView}
                      >
                        {t("Month")}
                      </button>
                    </div>
                  </div>
                  {!ParentMode && (
                    <div className="bx-right p-0 m-0">
                      <button
                        className={`${
                          Util.hasPermission("CALENDAR_ADD")
                            ? "btn btn-lg custom-primary"
                            : "no-permission"
                        } `}
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={Util.hasPermission("CALENDAR_ADD") && newEvent}
                      >
                        <i className="fab fa-plus"></i>
                        &nbsp;
                        {t("Add New")}
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="row ">
                <div className="col-lg-8">
                  <div className="calander py-4">
                    <FullCalendar
                      viewClassNames={`${dayView ? "Day-view" : ""}`}
                      ref={calendarRef}
                      dayHeaderContent={customDayHeaderContent}
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ]}
                      eventTimeFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        meridiem: false,
                      }}
                      stickyHeaderDates={"auto"}
                      selectable={true}
                      dayCellDidMount={renderBgCell}
                      events={eventsInFormat}
                      weekends={true}
                      eventClick={showEvent}
                      datesSet={handleDatesSet}
                      allDaySlot={true}
                      height="933px"
                      eventDidMount={eventDidMount}
                      eventContent={renderEventContent}
                      initialView="dayGridMonth"
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-4">
                  <div className="upcoming-event-contaier ev-list">
                    {/* <div className="d-flex justify-content-between align-items-center px-2"> */}
                    <h4 className="list-head m-2">{t("Upcoming Event")}</h4>
                    {/* <Select
                        className="select-custom"
                        placeholder="This Month"
                        options={[{ id: 0, name: "Holiday" }]}
                        // onChange={(values) => changeClassRoom(values)}
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          color: "#07112B",
                        }}
                        labelField="name"
                        searchable={false}
                        // values={[{ id: 0, name: "Rooms" }]}
                        valueField="id"
                      /> */}
                    {/* </div> */}
                    {upcomingEvents

                      .sort((a, b) => {
                        if (a.dd !== "" && b.dd !== "") {
                          return new Date(b.dd) < new Date(a.dd) ? 1 : -1;
                        }
                      })
                      .slice(0, 11)
                      .map((ev, index) => {
                        return (
                          <div key={index}>
                            <UpcomingEventCard
                              month={moment(ev?.dd).format("MMM")}
                              date={moment(ev?.dd).format("DD")}
                              title={ev?.event?.title}
                              description={ev?.event?.description}
                              bgColor={ev?.event?.EventType?.color_code}
                              onEventClick={() => {
                                events.map((val) => {
                                  if (val.event.id == ev?.event?.id) {
                                    setEvent(val.event);
                                    setShowEventModal(true);
                                  }
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <Modal
                className="calender-ev-show"
                style={{ borderRadius: "12px", padding: "25px" }}
                onHide={() => {
                  setShowEventModal(false);
                }}
                show={showEventModal}
                animation={true}
                centered={true}
                dialogClassName="modal-view"
              >
                <Modal.Header className="border-0">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        width: "5px",
                        height: "48px",
                        backgroundColor: `${event?.EventType?.color_code}`,
                        borderRadius: "10px",
                      }}
                    ></span>
                    <div>
                      <h4
                        className="mx-2"
                        style={{
                          fontSize: 20,
                          fontWeight: 600,
                          width: "294px",
                        }}
                      >
                        {event?.title}
                      </h4>
                      <span
                        className="mx-2 mt-0 small-text-cal d-flex align-items-center"
                        style={{ position: "relative", top: "-5px" }}
                      >
                        {Util.isValidData(event?.date) ? (
                          <>
                            {!ParentMode
                              ? moment(event?.date).format(
                                  Util.getMomentDateTimeFormat(
                                    school.date_format
                                  )
                                )
                              : moment(event?.date).format("DD/MM/YYYY")}
                          </>
                        ) : (
                          ""
                        )}{" "}
                        {event?.isfullday === false &&
                        Util.isValidData(event?.start_time) ? (
                          <>
                            <SeparateDot />{" "}
                            {moment(event?.start_time, "hh:mm").format("hh:mm")}
                            {`- ${moment(event?.end_time, "hh:mm A").format(
                              "hh:mm A"
                            )}`}
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div
                      className="icon-area text-right "
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        right: getLanguage !== "ar" && 0,
                        left: getLanguage == "ar" && 0,
                        top: 15,
                      }}
                    >
                      {!ParentMode && (
                        <>
                          <button
                            className={`${
                              Util.hasPermission("CALENDAR_DELETE")
                                ? "hidden-btn"
                                : "no-permission"
                            }`}
                            onClick={deleteEvent}
                          >
                            <img
                              src={Delete}
                              alt="Delete"
                              className="svg-icon"
                              height={20}
                              width={20}
                            />
                          </button>
                          <button
                            className={`${
                              Util.hasPermission("CALENDAR_EDIT")
                                ? "hidden-btn"
                                : "no-permission"
                            }`}
                          >
                            <img
                              src={Edit}
                              alt="Edit"
                              className="svg-icon"
                              onClick={() => editEvent(event)}
                              height={20}
                              width={20}
                            />
                          </button>
                        </>
                      )}
                      <button className="hidden-btn">
                        <img
                          src={Close}
                          alt="Close"
                          className="svg-icon"
                          onClick={() => {
                            setShowEventModal(false);
                          }}
                          height={20}
                          width={20}
                        />
                      </button>
                    </div>
                  </div>
                </Modal.Header>

                <Modal.Body>
                  <div className="event-border"></div>
                  <div className="modal-contents">
                    {Util.isValidData(event?.Grade) && (
                      <div className="col px-0 mb-2">
                        <div className="small-text-cal">{t("Grade")}</div>

                        <span className="bold-text">
                          {" "}
                          {event?.Grade?.title}
                        </span>
                      </div>
                    )}
                    {Util.isValidData(event?.ClassRoom) && (
                      <div className="col px-0 mb-2">
                        <div className=" small-text-cal">{t("Class Room")}</div>
                        <span className="bold-text">
                          {event?.ClassRoom?.name}
                        </span>
                      </div>
                    )}
                    <div className="col px-0 mb-2">
                      <div className=" small-text-cal">{t("Event Type")}</div>
                      <span className="bold-text">
                        {event?.EventType?.type}
                      </span>
                    </div>
                    {Util.isValidData(event?.description) && (
                      <div className="col px-0 mb-2">
                        <div className=" small-text-cal">
                          {t("Description")}
                        </div>
                        <span className="bold-text">{event?.description}</span>
                      </div>
                    )}
                    {event?.repeat == true && (
                      <div className="col px-0 mb-2">
                        <span className="bold-text">
                          {t("Event will repeat Every")}{" "}
                          {getRepeatDays()
                            .map((day) => daysOfWeek[day])
                            .join(", ")}{" "}
                          {t("Till")}{" "}
                          {!ParentMode
                            ? moment(event?.repeat_ends_on).format(
                                Util.getMomentDateTimeFormat(school.date_format)
                              )
                            : moment(event?.repeat_ends_on).format(
                                "DD/MM/YYYY"
                              )}
                          .
                        </span>
                      </div>
                    )}
                  </div>
                </Modal.Body>
              </Modal>

              {!ParentMode && (
                <AddEditEvent
                  show={showPopup}
                  onClose={() => {
                    setShowPopup(false);
                  }}
                  onSave={(msg) => {
                    setShowPopup(false);
                    if (msg) {
                      setEventsInFormat([]);
                      getEvents();
                      setIsToast(t("Added Event success"));
                      setIsSubToast(t("You have successfully added new event"));
                      getUpcomingEvents();
                    }
                  }}
                  isEditEvent={event}
                  onEdit={(msg) => {
                    setShowPopup(false);
                    if (msg) {
                      setEventsInFormat([]);
                      getEvents();
                      setIsToast(t("Update Event success"));
                      setIsSubToast(t("You have successfully Updated event"));
                      setEvent(null);
                      getUpcomingEvents();
                    }
                  }}
                />
              )}

              <ModalComponent
                show={showDeletePopup}
                onHide={handleCloseDeletePopup}
                title={t("Delete Event")}
                subTitle={t("Are you sure want to Delete this Event")}
                size="sm"
                onCancel={t("Cancel")}
                onConfirm={t("Confirm")}
                onCancelClick={handleCloseDeletePopup}
                onConfirmClick={handleDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(Calendar);
