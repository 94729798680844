import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import camera from "../../../assets/Icon/camera-icon.svg";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import ApiCalling from "../../../network/ApiCalling";
import CustomFileUpload from "../CustomFileUpload";
import Util from "../../../Util";
import ToastHOC from "../../HOC/ToastHOC";
import Close from "../../../assets/Icon/close.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ParentAvtar from "../../../assets/Icon/Parent-avtar.svg";
import EditPhotoModal from "../EditPhotoModal";
import Invalid from "../../../assets/Icon/invalid.svg";
import ExitAlert from "../../../components/ExitAlert";
import Loader from "../../../components/common/Loader";
// import { isValidPhoneNumber } from 'react-phone-number-input'

function AddContactComponent({
  show,
  onClose,
  onSave,
  studentId,
  notification,
  AddPrimary,
}) {
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [LastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [relation, setRelation] = useState(0);
  const [relationError, setRelationError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [relationListOfParent, setRelationListOfParent] = useState([]);
  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [idUpload, setIdUpload] = useState(null);
  const [identityURL, setIdentityURL] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [workAlternatePhone, setWorkAlternatePhone] = useState("");
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState("");
  const [workCity, setWorkCity] = useState("");
  const [workState, setWorkState] = useState("");
  const [city, setCity] = useState([]);
  const [workCountryId, setWorkCountryId] = useState(0);
  const [workPhone, setWorkPhone] = useState("");
  const [workAddress1, setWorkAddress1] = useState("");
  const [workCountry, setWorkCountry] = useState([]);
  // const [workState, setWorkState] = useState([]);
  const [photo, setPhoto] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [invitation, setInvitation] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [isStateUpdated, setIsStateUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  // console.log("isValidPhoneNumber",isValidPhoneNumber(phone))

  const handlePhotoChange = (e) => {
    setIsStateUpdate(true);
    if (e.target.files[0]) {
      setShowEditModal(true);
    }
    setPhoto(e.target.files[0]);
    setPhotoURL(URL.createObjectURL(e.target.files[0]));
  };
  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameError("");
    setIsStateUpdate(true);
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
    setLastNameError("");
    setIsStateUpdate(true);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailError("");
    setIsStateUpdate(true);
  };
  const handleRelation = (e) => {
    setRelation(e.target.value);
    setRelationError("");
    setIsStateUpdate(true);
  };
  const handleInsuranceNumber = (e) => {
    setInsuranceNumber(e.target.value);
    setIsStateUpdate(true);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleFileChange = (file) => {
    if (file) {
      const maxSize = 25 * 1024 * 1024;
      if (file.size <= maxSize) {
        setIdUpload(file);
        setIdentityURL(URL.createObjectURL(file));
      } else {
        notification(t("File size exceeds the limit of 25MB"));
      }
    }
    setIsStateUpdate(true);
  };

  const handleIdUpload = (e) => {
    const file = e.target.files[0];
    handleFileChange(file);
  };
  const changeCompanyName = (e) => {
    setCompanyName(e.target.value);
    setIsStateUpdate(true);
  };
  const changeWorkCity = (e) => {
    setWorkCity(e.target.value);
    setIsStateUpdate(true);
  };
  const changeWorkState = (e) => {
    setWorkState(e.target.value);
    setIsStateUpdate(true);
  };
  const changeWorkCountryId = (e) => {
    setWorkCountryId(e.target.value);
    setIsStateUpdate(true);
  };
  const changeWorkAddress1 = (e) => {
    setWorkAddress1(e.target.value);
    setIsStateUpdate(true);
  };
  const handleZipCodeChange = (e) => {
    setZipCode(e.target.value);
    setPinCodeError("");
    setIsStateUpdate(true);
  };
  const sendInvitation = (e) => {
    setInvitation(e.target.checked);
    setIsStateUpdate(true);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setRelationError("");
    let error = false;

    if (firstName === "") {
      setFirstNameError(t("First Name is required"));
      error = true;
    }
    if (lastName === "") {
      setLastNameError(t("Last Name is required"));
      error = true;
    }
    if (email === "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }
    if (relation === 0) {
      setRelationError(t("Please Select Relation"));
      error = true;
    }
    if (phone === "") {
      setPhoneError(t("Phone Number is required"));
      error = true;
    }
    if (zipCode && Util.Zip_regex.test(zipCode) === false) {
      setPinCodeError(
        t("Please enter valid zip-code(i.e: 12345 or 12345-2563)")
      );
      error = true;
    }

    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      setIsStateUpdate(false);
      const formData = new FormData();

      formData.append("student_id", studentId);
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("student_parent_relation_type_id", relation);
      formData.append("insurance", insuranceNumber);
      if (idUpload) {
        formData.append("user_identity", idUpload);
      }
      formData.append("work_company", companyName);
      formData.append("work_phone", workPhone);
      formData.append("work_phone_alternate", workAlternatePhone);
      formData.append("work_address1", workAddress1);
      if (workCity) {
        formData.append("work_city", workCity);
      }
      if (workCountryId) {
        formData.append("work_country_id", workCountryId);
      }
      if (workState) {
        formData.append("work_state", workState);
      }
      if (zipCode) {
        formData.append("work_zip", zipCode);
      }
      formData.append("avatar", photo);
      formData.append("send_mail", invitation);
      formData.append("is_primary", AddPrimary == true ? true : false);

      ApiCalling.apiCallBodyDataPost(`parent/add-parent`, formData)
        .then((res) => {
          if (res.data.status == true) {
            setIsClickedOnce(false);
            setIsLoading(false);
            notification(res.data.message);
            onSave();
            reset();
          } else {
            setEmailError(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // useEffect(() => {
  //   if (workStateId) {
  //     let isCityListMounted = true;
  //     const cityList = () => {
  //       setIsCountryLoading(true);
  //       ApiCalling.apiCallBodyDataGet(`city/list/${workStateId}`).then(
  //         (res) => {
  //           if (isCityListMounted) {
  //             setIsCountryLoading(false);
  //             setCity(res.data);
  //           }
  //         }
  //       );
  //     };
  //     cityList();
  //     return () => {
  //       isCityListMounted = false;
  //     };
  //   }
  // }, [workStateId]);
  useEffect(() => {
    let isWorkCountryMounted = true;
    const WorkCountryList = () => {
      setIsCountryLoading(true);
      ApiCalling.apiCallBodyDataGet("country/list").then((res) => {
        if (isWorkCountryMounted) {
          setIsCountryLoading(false);
          setWorkCountry(res.data);
        }
      });
    };
    WorkCountryList();
    return () => {
      isWorkCountryMounted = false;
    };
  }, []);
  useEffect(() => {
    if (workCountryId) {
      let isWorkStateMounted = true;
      const workStateList = () => {
        setIsCountryLoading(true);
        ApiCalling.apiCallBodyDataGet(`state/get-states/${workCountryId}`).then(
          (res) => {
            if (isWorkStateMounted) {
              setIsCountryLoading(false);
              setWorkState(res.data);
            }
          }
        );
      };
      workStateList();
      return () => {
        isWorkStateMounted = false;
      };
    }
  }, [workCountryId]);
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);

  useEffect(() => {
    let isRelationMounted = true;
    const relationList = () => {
      ApiCalling.apiCallParamsGet("student-parent-relation-type").then(
        (res) => {
          if (isRelationMounted) {
            setRelationListOfParent(res.data);
          }
        }
      );
    };
    relationList();
    return () => {
      isRelationMounted = false;
    };
  }, []);
  const reset = () => {
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setRelationError("");
    setPhoto("");
    setPhotoURL("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setRelation(0);
    setInsuranceNumber("");
    setIdUpload(null);
    setCompanyName("");
    setWorkPhone("");
    setWorkAlternatePhone("");
    setWorkAddress1("");
    setWorkCountryId(0);
    setWorkCity("");
    setWorkState("");
    setInvitation(false);
    setZipCode("");
  };
  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            onClose();
            reset();
            setIsStateUpdate(false);
          }
        }}
        onOpen={() => {}}
      >
        <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
          {/* <i className="fa fa-times float-right" ></i> */}
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-start drawer-header">
              {t("Add New Contact Parent")}
            </div>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => {
                if (isStateUpdated) {
                  setIsAlertModal(true);
                } else {
                  onClose();
                  reset();
                  setIsStateUpdate(false);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>

          {!isLoading ? (
            <div className="body mt-3">
              <div className="drawer-sub-header">
                {t("Personal Information")}
              </div>
              <div className="mt-3">
                <div className="basic-text">{t("Parent's Photo")}</div>
                {photoURL.length > 0 ? (
                  <div
                    className="profile-area"
                    style={{
                      position: "relative",
                      height: "120px",
                      width: "120px",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      alt="User"
                      className="rounded-circle user-photo img-responsive  mt-1 "
                      src={photoURL}
                      style={{
                        height: "120px",
                        width: "120px",
                        borderRadius: "50%",
                      }}
                    />{" "}
                    <span
                      className="choose-profile"
                      onClick={(e) => {
                        e.preventDefault();
                        // document.getElementById("image").click();
                        setShowEditModal(true);
                      }}
                    >
                      <img src={camera} alt="" />
                    </span>
                  </div>
                ) : (
                  <div
                    className="profile-area"
                    style={{
                      position: "relative",
                      height: "120px",
                      width: "120px",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      alt="User"
                      className="img-responsive  mt-1"
                      style={{
                        height: "120px",
                        width: "120px",
                        borderRadius: "50%",
                        position: "relative",
                      }}
                      src={ParentAvtar}
                    />
                    <span
                      className="choose-profile"
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById("image").click();
                      }}
                    >
                      <img src={camera} alt="" />
                    </span>

                    <input
                      className="sr-only"
                      id="image"
                      type="file"
                      accept="image/*"
                      onChange={handlePhotoChange}
                    />
                  </div>
                )}
              </div>
              <EditPhotoModal
                show={showEditModal}
                onClose={() => {
                  setShowEditModal(false);
                  setPhotoURL("");
                }}
                title={t("Edit Photo")}
                subTitle={t(
                  "Show profile picture the best version of yourself"
                )}
                onChange={t("Change Image")}
                onSave={t("Save Photo")}
                handleSavePhoto={() => setShowEditModal(false)}
                ProfilePhoto={photoURL}
                photoURL={photoURL}
                handlePhotoChange={handlePhotoChange}
                defaultAvtar={ParentAvtar}
              />
              <div className="row text-start mt-2">
                <div className="col-6">
                  <div className="input-filed ">
                    <span className="basic-text required">
                      {t("First Name")}
                    </span>
                    <input
                      type="text"
                      className={`form-control ${
                        firstNameError.length > 0
                          ? "input-error"
                          : "custom-input"
                      }`}
                      // placeholder="First Name"
                      onChange={handleFirstName}
                      value={firstName}
                    />
                    {firstNameError ? (
                      <span className="invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {firstNameError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-filed">
                    <span className="basic-text required">
                      {t("Last Name")}
                    </span>
                    <input
                      className={`form-control ${
                        LastNameError.length > 0
                          ? "input-error"
                          : "custom-input"
                      }`}
                      // placeholder="Last Name"
                      type="text"
                      onChange={handleLastName}
                      value={lastName}
                    />
                    {LastNameError ? (
                      <span className="invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {LastNameError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-filed">
                    <span className="basic-text required">{t("Email")}</span>
                    <input
                      className={`form-control ${
                        emailError.length > 0 ? "input-error" : "custom-input"
                      }`}
                      // placeholder="Email"
                      type="email"
                      onChange={handleEmail}
                      value={email}
                    />
                    {emailError ? (
                      <span className="invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {emailError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-filed mb-0">
                    <span className="basic-text required">
                      {t("Phone Number")}
                    </span>

                    <div
                      className={`form-control ${
                        phoneError.length > 0 ? "input-error" : "phone-input"
                      }`}
                      tabIndex={0}
                    >
                      <PhoneInput
                        tabIndex="-1"
                        international
                        defaultCountry={"AE"}
                        onChange={(value) => {
                          setPhone(value);
                          setPhoneError("");
                          setIsStateUpdate(true);
                        }}
                        // disabled={true}
                        value={phone}
                      />
                    </div>
                  </div>
                  {phoneError ? (
                    <span className="invalid-error ">
                      <img
                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                        src={Invalid}
                      />
                      {phoneError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-6">
                  <div className="input-filed">
                    <span className="basic-text required">{t("Relation")}</span>
                    <select
                      className={`select-dropdown form-select ${
                        relationError.length > 0
                          ? "input-error"
                          : "custom-input"
                      }`}
                      value={relation}
                      onChange={handleRelation}
                    >
                      <option value={0}>{t("Select Relation")}</option>
                      {relationListOfParent?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </select>
                    {relationError ? (
                      <span className="invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {relationError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-filed">
                    <span className="basic-text ">{t("Insurance Number")}</span>
                    <input
                      className="form-control custom-input"
                      type="text"
                      onChange={handleInsuranceNumber}
                      value={insuranceNumber}
                    />
                  </div>
                </div>

                <div className="input-filed">
                  <span className="basic-text ">{t("ID Upload")}</span>
                  <CustomFileUpload
                    onChange={handleIdUpload}
                    fileName={idUpload ? idUpload.name : ""}
                    showFile={identityURL}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    acceptedDocument={t("Image,PDF,Doc (max file 25mb)")}
                    accept={"application/pdf,.docs,.docx,.jpg,.jpeg,.png"}
                    className="custom-file"
                  />
                </div>
              </div>
              <div
                className="text-start medium-large-text"
                style={{
                  marginTop: "2%",
                }}
              >
                {t("Work Information")}
              </div>
              <div className="text-start row mt-2">
                <div className="col-6">
                  <div className="input-filed">
                    <span className="basic-text ">{t("Company Name")}</span>
                    <input
                      className="form-control custom-input"
                      // placeholder="Last Name"
                      type="text"
                      onChange={changeCompanyName}
                      value={companyName}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-filed">
                    <span className="basic-text ">
                      {t("Work Phone Number")}
                    </span>

                    <div className="form-control phone-input" tabIndex={0}>
                      <PhoneInput
                        tabIndex="-1"
                        international
                        defaultCountry={"AE"}
                        onChange={(value) => {
                          setWorkPhone(value);
                          setIsStateUpdate(true);
                        }}
                        // disabled={true}
                        value={workPhone}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-filed ">
                    <span className="basic-text">{t("Alternate Phone")}</span>

                    <div className="form-control phone-input" tabIndex={0}>
                      <PhoneInput
                        tabIndex="-1"
                        international
                        defaultCountry={"AE"}
                        onChange={(value) => {
                          setWorkAlternatePhone(value);
                          setIsStateUpdate(true);
                        }}
                        // disabled={true}
                        value={workAlternatePhone}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-filed ">
                    <span className="basic-text ">{t("Address")}</span>
                    <input
                      className="form-control custom-input"
                      // placeholder={t("Address")}
                      type="text"
                      value={workAddress1}
                      onChange={changeWorkAddress1}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-filed">
                    <span className="basic-text ">{t("Country")}</span>
                    <select
                      className="select-dropdown form-select custom-input"
                      value={workCountryId}
                      onChange={changeWorkCountryId}
                    >
                      <option value="">
                        {isCountryLoading
                          ? t("Loading...")
                          : t("Select Country")}
                      </option>
                      {workCountry.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-filed">
                    <span className="basic-text ">{t("State")}</span>
                    {/* <select
                      className="select-dropdown form-select custom-input"
                      value={workStateId}
                      onChange={changeWorkStateId}
                      disabled={!workCountryId}
                    >
                      <option value="">
                        {isCountryLoading ? t("Loading...") : t("Select State")}
                      </option>
                      {workState.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select> */}
                    <input
                      className="form-control custom-input"
                      // placeholder={t("Address")}
                      type="text"
                      value={workState}
                      onChange={changeWorkState}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="input-filed">
                    <span className="basic-text ">{t("City")}</span>
                    {/* <select
                      className="select-dropdown form-select custom-input"
                      value={workCityId}
                      onChange={changeWorkCity}
                      disabled={!workStateId}
                    >
                      <option value="">
                        {isCountryLoading ? t("Loading...") : t("Select city")}
                      </option>
                      {city.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select> */}
                      <input
                      className="form-control custom-input"
                      // placeholder={t("Address")}
                      type="text"
                      value={workCity}
                      onChange={changeWorkCity}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-filed ">
                    <span className="basic-text">{t("PIN Code")}</span>
                    <input
                      className={`form-control custom-input ${
                        pinCodeError.length > 0 ? "input-error" : ""
                      }`}
                      value={zipCode}
                      onChange={handleZipCodeChange}
                    />
                    {pinCodeError.length > 0 && (
                      <span className="invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {pinCodeError}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-start d-flex align-items-center justify-content-end gap-2">
                <div className="form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={invitation}
                    value={invitation}
                    onChange={sendInvitation}
                  />
                  <span
                    tabIndex={0}
                    className="form-check-label"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    {t("Send Invitation")}
                  </span>
                </div>

                <button
                  className="btn secondary-md-btn"
                  onClick={() => {
                    if (isStateUpdated) {
                      setIsAlertModal(true);
                    } else {
                      onClose();
                      reset();
                      setIsStateUpdate(false);
                    }
                  }}
                >
                  {t("Cancel")}
                </button>
                <button
                  // style={{ width: "120px", height: "48px" }}
                  className="btn primary-lg-btn"
                  onClick={handleSave}
                >
                  {t("Save Contact")}
                </button>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </Box>
      </SwipeableDrawer>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdate(false);
        }}
        onOk={() => {
          setIsAlertModal(false);
          setIsStateUpdate(false);
          onClose();
          reset();
        }}
      />
    </div>
  );
}
export default ToastHOC(AddContactComponent);
