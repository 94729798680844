import React, { useContext, useEffect, useState } from "react";
import Util from "../Util";
import UserComponent from "./UserComponent";
import notificationReadWhite from "../assets/Icon/notification-read-white.svg";
import notificationUnReadWhite from "../assets/Icon/notification-unread-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStudent } from "../actions/SelectStudentAction";
import Select from "react-dropdown-select";
import { googleLogout } from "@react-oauth/google";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SocketContext } from "../context/SocketContext";
import ModalComponent from "../screens/Dashbord/ModalComponent";
import NotificationModal from "../screens/Dashbord/NotificationModal";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Toggle } from "../actions/ToggleAction";
import ApiCalling from "../network/ApiCalling";

export default function ParentTopbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = Util.getUser();
  const socket = useContext(SocketContext);
  const [childList, setChildList] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const getLanguage = Util.getSelectedLanguage();
  const isShow = useSelector((state) => state.ToggleReducer);
  const isUpdate = useSelector((state) => state.impersonateUser);
  const [getUser, setGetUser] = useState(user);
  const [newNotification, setNewNotification] = useState(false);

  const makeUserOffline = () => {
    // console.log("makeUserOffline");
    if (Util.isValidData(user)) {
      socket.emit("offline", user?.id);
    }
  };

  const switchAsStaff = async () => {
    setShowPopup(false);
    await Util.removeUser();
    await Util.saveUser(user);
    await Util.saveParentMode(false);
    await Util.saveSelectedSchool(user.user_Schools[0].school);
    history.push("/Dashboard");
  };

  const handleBlur = () => {
    console.log("onBlur ***************");
    makeUserOffline();
  };
  const handleFocus = async () => {
    notification();
  };
  const tabletSidebar = async () => {
    const leftSidebar = document.getElementById("left-sidebar");

    if (leftSidebar.style.left === "-260px") {
      leftSidebar.style.left = "0";

      // Toggle the class
      if (toggleMenu) {
        document.body.classList.remove("layout-fullwidth");
      } else {
        document.body.classList.add("layout-fullwidth");
      }
    }
    setToggleMenu(!toggleMenu);
    dispatch(Toggle(false));
  };

  const mainSidebar = () => {
    document.getElementById("left-sidebar").style.left = "0";
    let origionalSize = "100%";
    let reduceSize = "260px";
    let size = `calc(${origionalSize} - ${reduceSize})`;
    document.getElementById("main-content").style.width = size;
    dispatch(Toggle(false));
  };
  useEffect(() => {
    if (getLanguage === "ar") {
      document.getElementById("main-content").style.float = "left";
    } else {
      document.getElementById("main-content").style.float = "right";
    }
    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);
    notification();
    const cleanup = async () => {
      makeUserOffline();
      window.removeEventListener("blur", handleBlur);
      window.addEventListener("focus", handleFocus);
    };

    cleanup();
    return () => {};
  }, [getLanguage]);

  useEffect(() => {
    if (user.Parents.length > 0) {
      const studentId = user.Parents[0].Student.id;
      setChildList(user.Parents);
      dispatch(setSelectedStudent(studentId, user.Parents[0].Student));
    }
  }, []);

  const getUserData = () => {
    ApiCalling.apiCallBodyDataGet(`user/get-user-byId/${user.id}`)
      .then((res) => {
        setGetUser(res.data);
        Util.saveUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isUpdate) {
      getUserData();
    }
  }, [isUpdate]);

  const notification = () => {
    // console.log({
    //   user_id: Util.getUser().id,
    //   dto: { page_size: 20, page_number: 1 },
    //   school: Util.getSelectedSchool()?.id,
    // });
    socket.emit("notify", {
      user_id: Util.getUser().id,
      dto: { page_size: 20, page_number: 1 },
      school: Util.getSelectedSchool()?.id,
    });

    socket.on("notify", (data) => {
      // console.log("NOTIFY", data);
      const hasUnreadNotification = data?.data?.some(
        (item) => item?.read_on === null
      );
      // console.log(hasUnreadNotification);
      setNewNotification(hasUnreadNotification);
    });
  };

  useEffect(() => {
    notification();
  }, [showNotification]);

  return (
    <div
      className=" header-border  sticky-top topbar"
      style={{
        height:
          Util.getUser()?.is_impersonated && Util.getUserImpersonate()
            ? "90px"
            : "",
      }}
    >
      <div>
        {Util.getUser()?.is_impersonated && Util.getUserImpersonate() ? (
          <div className="d-flex justify-content-center cust-account">
            <span>Customer Account</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="top-header">
        <div className="d-flex justify-content-between align-items-center">
          {isShow.Clicked == true && (
            <div id="barIcon">
              <IconButton onClick={mainSidebar} className="collops-main mt-2">
                <svg
                  id="cu3-icon-sidebarToggle"
                  viewBox="0 0 24 24"
                  fill="#6c767d"
                  width={24}
                  height={24}
                >
                  <path
                    fillRule="evenodd"
                    d="M5 4.83A1.17 1.17 0 0 0 3.83 6v12c0 .646.524 1.17 1.17 1.17h5.17V4.83H5Zm6.83 0v14.34H19A1.17 1.17 0 0 0 20.17 18V6A1.17 1.17 0 0 0 19 4.83h-7.17ZM2.17 6A2.83 2.83 0 0 1 5 3.17h14A2.83 2.83 0 0 1 21.83 6v12A2.83 2.83 0 0 1 19 20.83H5A2.83 2.83 0 0 1 2.17 18V6Zm3 2A.83.83 0 0 1 6 7.17h2a.83.83 0 0 1 0 1.66H6A.83.83 0 0 1 5.17 8Zm0 2.5A.83.83 0 0 1 6 9.67h2a.83.83 0 1 1 0 1.66H6a.83.83 0 0 1-.83-.83Zm0 2.5a.83.83 0 0 1 .83-.83h2a.83.83 0 1 1 0 1.66H6a.83.83 0 0 1-.83-.83Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </IconButton>

              <IconButton onClick={mainSidebar} className="collops-tablet mt-2">
                <svg
                  id="cu3-icon-sidebarToggle"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width={24}
                  height={24}
                >
                  <path
                    fillRule="evenodd"
                    d="M5 4.83A1.17 1.17 0 0 0 3.83 6v12c0 .646.524 1.17 1.17 1.17h5.17V4.83H5Zm6.83 0v14.34H19A1.17 1.17 0 0 0 20.17 18V6A1.17 1.17 0 0 0 19 4.83h-7.17ZM2.17 6A2.83 2.83 0 0 1 5 3.17h14A2.83 2.83 0 0 1 21.83 6v12A2.83 2.83 0 0 1 19 20.83H5A2.83 2.83 0 0 1 2.17 18V6Zm3 2A.83.83 0 0 1 6 7.17h2a.83.83 0 0 1 0 1.66H6A.83.83 0 0 1 5.17 8Zm0 2.5A.83.83 0 0 1 6 9.67h2a.83.83 0 1 1 0 1.66H6a.83.83 0 0 1-.83-.83Zm0 2.5a.83.83 0 0 1 .83-.83h2a.83.83 0 1 1 0 1.66H6a.83.83 0 0 1-.83-.83Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </IconButton>
            </div>
          )}
          <div id="navbar-search" className="mt-3 ml-3">
            {childList.length > 0 && (
              <Select
                searchable={false}
                className="select-custom"
                options={[
                  ...childList.map((st) => ({
                    id: st.Student.id,
                    name: st.Student.first_name + " " + st.Student.last_name,
                    data: st.Student,
                  })),
                ]}
                style={{ minWidth: "250px", paddingInline: "10px" }}
                labelField="name"
                values={[
                  {
                    id: 0,
                    name:
                      user?.Parents[0].Student?.first_name +
                      " " +
                      user?.Parents[0].Student?.last_name,
                    data: "",
                  },
                ]}
                valueField="id"
                onChange={(values) => {
                  // console.log("setSelectedStudent",this.props.setSelectedStudent());
                  // this.props.setSelectedStudent(values[0].id, values[0].data);
                  dispatch(setSelectedStudent(values[0].id, values[0].data));
                }}
              />
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center gap-2 mt-3">
            <a
              className={`dropdown-toggle icon-menu ${
                showNotification && "notification-circle"
              }`}
              onClick={() => {
                setShowNotification(true);
              }}
            >
              {/* {showNotification ? (
                <img
                  src={notificationWhite}
                  width={"22px"}
                  className="img-responsive logo svg-icon"
                  style={{ marginLeft: "8px", marginTop: "8px" }}
                />
              ) : (
                <img
                  src={notification}
                  alt="notification"
                  width={"24px"}
                  style={{ cursor: "pointer" }}
                />
              )} */}
              {newNotification ? (
                <img
                  src={notificationUnReadWhite}
                  width={"24px"}
                  className="img-responsive logo svg-icon"
                  style={{ marginLeft: "6px", marginTop: "6px" }}
                />
              ) : (
                <img
                  src={notificationReadWhite}
                  alt="notification"
                  width={"24px"}
                  style={{ cursor: "pointer" }}
                />
              )}
            </a>
            <span className="divider"></span>
            <UserComponent
              switchToStaff={() => {
                setShowPopup(true);
              }}
              onLogoutClick={(e) => {
                e.preventDefault();
                history.push("/");
                makeUserOffline();
                Util.removeUser();
                localStorage.clear();
                googleLogout();
              }}
            />
          </div>
        </div>
      </div>
      {showPopup == true && (
        <ModalComponent
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
          }}
          title={"Switch As Staff"}
          size="sm"
          onCancel="Cancel"
          onConfirm="Confirm"
          onCancelClick={() => {
            setShowPopup(false);
          }}
          onConfirmClick={switchAsStaff}
        >
          <span>
            {t("Are you sure to Logout")}?
            <br /> {t("You will be redirected to the ")}{" "}
            <b>{t("Staff Dashboard")}</b> {t("after logout from this school")}.
          </span>
        </ModalComponent>
      )}
      {showNotification == true && (
        <NotificationModal
          show={showNotification}
          onHide={() => setShowNotification(false)}
        />
      )}
    </div>
  );
}
