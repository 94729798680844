import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiCalling from "../../../network/ApiCalling";
import { CookieSharp } from "@mui/icons-material";
import moment from "moment";
import { Modal } from "react-bootstrap";
import ToastHOC from "../../HOC/ToastHOC";
import { Rings } from "react-loader-spinner";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import Sort from "../../../assets/Icon/Short.svg";
import AddGroup from "./AddGroup";
import ModalComponent from "../ModalComponent";
import NewGroup from "../../Message/NewGroup";
import Loader from "../../../components/common/Loader";

function Group(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const history = useHistory();
  const [groupList, setGroupList] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalGroup, setTotalGroup] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isAddGroup, setIsAddGroup] = useState({ show: false, isEdit: false });
  const [groupDetails, setGroupDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("Group");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedGroupList = [...groupList].sort((a, b) => {
    if (sortColumn === "Group") {
      return sortDirection === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    }
    return 0;
  });

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const groupData = () => {
    setIsLoading(true);
    const data = {
      page_size: recordPerPage,
      page_number: currentPage,
    };
    ApiCalling.apiCallBodyDataPost("group/list", data)
      .then((res) => {
        setIsLoading(false);
        setGroupList(res.data.data);
        setTotalPages(res.data.totalPages);
        setTotalGroup(res.data.total);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const handleDelete = () => {
    ApiCalling.apiCallDelete(`group/${deleteId}`)
      .then((res) => {
        if (res.data.status == true) {
          setIsToast(t("group Deleted Successfully"));
        } else {
          setIsToast(t("Somewhere used ,You Can't Delete"));
        }
        setShowDeleteConfirmation(false);
        groupData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    groupData();
  }, [currentPage]);

  // useEffect(() => {
  //   if (pendingToast && pendingToast.length > 0) {
  //     setIsToast(pendingToast);
  //   }
  // }, []);
  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="header text-right mb-3 px-0">
            <button
              className={`${
                Util.hasPermission("GROUP_ADD")
                  ? "btn custom-primary"
                  : "no-permission"
              }`}
              type="button"
              aria-expanded="false"
              onClick={() => {
                setIsAddGroup({ show: true, isEdit: false });
                setGroupDetails(null);
              }}
            >
              {t("Add Group")}
            </button>
          </div>
        </div>
        <div className="row clearfix">
          <div className="table-div overflow-hidden  p-0 ">
            <div className="col-lg col-md-12 m-0 p-0">
              <div className="body" style={{overflowX:"scroll"}}>
                {!isLoading ? (
                  <>
                    <table className="table">
                      <thead>
                        <tr className="">
                          <th scope="col"></th>
                          <th scope="col">
                            {t("Name")}{" "}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("Group");
                              }}
                            />
                          </th>

                          <th scope="col">{t("Staff")} </th>
                          <th scope="col">{t("Student")} </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      {groupList.length > 0 ? (
                        <>
                          <tbody>
                            {sortedGroupList.map((item, index) => {
                              const users = item.GroupPeople.filter(
                                (i) => i.User !== null && (i.User.is_staff && !i.User.is_parent)
                              ).map((u) => u.User.firstName);
                              console.log(users);
                              const students = item.GroupPeople.filter(
                                (i) => i.Student !== null
                              ).map((u) => u.Student.first_name);
                              return (
                                <tr key={index}>
                                  <td>
                                    {(currentPage - 1) * recordPerPage +
                                      index +
                                      1}
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setIsAddGroup({
                                          show: true,
                                          isEdit: true,
                                        });
                                        setGroupDetails(item);
                                      }}
                                    >
                                      {item.name}
                                    </a>
                                  </td>
                                  <td>{Util.getListDisplay(users, 3, ", ")}</td>
                                  <td>
                                    {Util.getListDisplay(students, 3, ", ")}
                                  </td>

                                  <td className="text-right">
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <a
                                          href="#"
                                          className={`${
                                            !Util.hasPermission("GROUP_EDIT") &&
                                            "no-permission"
                                          }`}
                                          onClick={(e) => {
                                            console.log("clicked");
                                            e.preventDefault();
                                            setIsAddGroup({
                                              show: true,
                                              isEdit: true,
                                            });
                                            setGroupDetails(item);
                                          }}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </a>
                                      </div>
                                      <div>
                                        <a
                                          href="#"
                                          className={`${
                                            !Util.hasPermission(
                                              "GROUP_Delete"
                                            ) && "no-permission"
                                          }`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowDeleteConfirmation(true);
                                            setDeleteId(item.id);
                                          }}
                                        >
                                          <i className="fa fa-trash-o text-danger"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </>
                      ) : (
                        <>
                          <tfoot>
                            <tr>
                              <td colSpan={4}>
                                {t(
                                  "There are no Group. Click the 'Add Group' button to create one."
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        </>
                      )}
                    </table>
                    {totalPages > 0 && (
                      <div
                        className="row m-0 border-top "
                        style={{ height: "72px" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center gap-2">
                            <div className="basic-text">
                              {t("Students Per Page")}
                            </div>
                            <div>
                              <select
                                name=""
                                id=""
                                style={{ width: "72px", cursor: "pointer" }}
                                className="form-select custom-input"
                                value={recordPerPage}
                                onChange={(e) => {
                                  setRecordPerPage(e.target.value);
                                }}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <Stack
                              spacing={2}
                              direction="row"
                              alignItems="center"
                            >
                              <div
                                className={`link-button ${
                                  currentPage == 1 ? "disabled" : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handlePreviousClick();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <KeyboardArrowLeftIcon />
                                <span className="basic-bold-text">
                                  {t("Previous")}
                                </span>
                              </div>
                              <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={(event, page) => {
                                  setCurrentPage(page);
                                }}
                                size="small"
                                hideNextButton
                                hidePrevButton
                              />
                              <div
                                className={`link-button ${
                                  currentPage == totalPages ? "disabled" : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleNextClick();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="basic-bold-text">
                                  {t("Next")}
                                </span>
                                <KeyboardArrowRightIcon />
                              </div>
                            </Stack>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <ModalComponent
            show={showDeleteConfirmation}
            onHide={() => {
              setShowDeleteConfirmation(false);
            }}
            title={t("Delete")}
            onCancel={t("Cancel")}
            onConfirm={t("Confirm")}
            subTitle={t("Are you sure, to delete this Group?")}
            size="sm"
            onCancelClick={() => {
              setShowDeleteConfirmation(false);
            }}
            onConfirmClick={handleDelete}
          />
          {/* <AddGroup
            isVisible={isAddGroup}
            isClose={() => {
              setIsAddGroup(false);
              setGroupDetails(null);
            }}
            groupDetails={groupDetails}
            notification={(msg) => {
              if (msg) {
                setIsToast(msg);
              }
            }}
            isSave={() => {
              groupData();
              setIsAddGroup(false);
              setGroupDetails(null);
            }}
          /> */}
          <NewGroup
            show={isAddGroup}
            data={groupDetails}
            onClose={() => {
              setIsAddGroup({ show: false });
            }}
            onSave={(msg) => {
              if (msg) {
                setIsToast(msg);
              }
            }}
            onCreateGroup={() => {
              setIsAddGroup({ show: false });
            }}
            onGroupClick={() => {
              setIsAddGroup({ show: false });
              groupData();
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(Group);
