import React, { useEffect, useState } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { PaymentStatus } from "../../../Enums";
import ApiCalling from "../../../network/ApiCalling";
import Loader from "../../../components/common/Loader";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Close from "../../../assets/Icon/close.png";

export default function PaymentView({ show, onClose, PaymentId }) {
  const getLanguage = Util.getSelectedLanguage();
  const { t } = useTranslation();
  const [paymentDetail, setPaymentDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getPaymentDetail = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`payment/${PaymentId}`)
      .then((res) => {
        setIsLoading(false);
        setPaymentDetail(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (PaymentId > 0) {
      getPaymentDetail();
    }
  }, [PaymentId]);
  return (
    <div>
      {Util.isValidData(paymentDetail) && (
        <SwipeableDrawer
          // hideBackdrop={true}
          anchor={getLanguage == "ar" ? "left" : "right"}
          open={show}
          onClose={() => {
            if (Util.isValidData(paymentDetail)) {
              onClose();
            }
          }}
          onOpen={() => {}}
        >
          <div
            className="box-area"
            style={{ position: "relative", width: "100%", height: "100%" }}
          >
            <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
              {!isLoading ? (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-start drawer-header">
                      {t("Payment log of")}{" "}
                      {`"${
                        paymentDetail?.Student.first_name +
                        " " +
                        paymentDetail?.Student.last_name
                      }"`}
                    </div>
                    {/* <IconButton
                      aria-label="close"
                      size="small"
                      onClick={() => {
                        if (Util.isValidData(paymentDetail)) {
                          onClose();
                        }
                      }}
                    >
                      <CloseIcon />
                    </IconButton> */}
                    <button
                      className="hidden-btn"
                      onClick={() => {
                        if (Util.isValidData(paymentDetail)) {
                          onClose();
                        }
                      }}
                    >
                      <img
                        src={Close}
                        className="svg-icon"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                  <div className="body text-start mt-3">
                    <div className="row text-start mt-2">
                      <div className="input-filed ">
                        <span className="basic-text text-secondary">
                          {t("Payment By")}
                        </span>
                        <div className="text-bold">
                          {paymentDetail?.User?.firstName +
                            " " +
                            paymentDetail?.User?.lastName}{" "}
                          {paymentDetail?.relation &&
                            `(${paymentDetail?.relation.relation})`}
                        </div>
                      </div>

                      <div className="input-filed ">
                        <span className="basic-text text-secondary">
                          {t("Payment Method")}
                        </span>
                        <div className="text-bold">
                          {paymentDetail?.payment_method}
                        </div>
                      </div>

                      <div className="input-filed ">
                        <span className="basic-text text-secondary">
                          {t("Amount")}
                        </span>
                        <div className="text-bold">
                          {Util.isValidData(paymentDetail?.amount) &&
                            Util.amountFormat(paymentDetail?.amount)}
                        </div>
                      </div>

                      <div className="input-filed ">
                        <span className="basic-text text-secondary">
                          {t("Payment Date")}
                        </span>
                        <div className="text-bold">
                          {Util.isValidData(paymentDetail?.date) &&
                            moment(paymentDetail?.date).format("DD/MM/YYYY")}
                        </div>
                      </div>

                      <div className="input-filed ">
                        <span
                          className="basic-text text-secondary"
                          style={{ fontWight: "400" }}
                        >
                          {t("Note")}
                        </span>
                        <div className="text-bold ">
                          {Util.isValidData(paymentDetail?.note)
                            ? paymentDetail?.note
                            : "-"}
                        </div>
                      </div>
                    </div>
                    {paymentDetail?.status == PaymentStatus.USED ? (
                      <div className="alert alert-success text-center">
                        {t("Payment is settled against invoice(s).")}
                      </div>
                    ) : paymentDetail?.status == PaymentStatus.PARTIAL_USED ? (
                      <div className="alert alert-warning text-center">
                        {t("Payment is Partially settled against invoice(s).")}
                      </div>
                    ) : (
                      <div className="alert alert-danger text-center">
                        {t("Payment is Pending to settle.")}
                      </div>
                    )}

                    {Util.isValidData(paymentDetail) &&
                      paymentDetail.invoice_settled.length > 0 && (
                        <div className="box-area">
                          <span className="basic-text text-secondary">
                            {t("Payment settled against ")}
                          </span>
                          <table
                            className="table"
                            style={{
                              border: "1px solid #e5e7eb",
                              borderRadius: "12px",
                            }}
                          >
                            <thead style={{ backgroundColor: "#e5e7eb" }}>
                              <tr>
                                <th>{t("Invoice")}</th>
                                <th>{t("Fee Type")}</th>
                                <th>{t("Invoice Total")}</th>
                                <th>{t("Amount Adjusted")}</th>
                              </tr>
                            </thead>
                            {paymentDetail.invoice_settled.length > 0 ? (
                              <>
                                <tbody>
                                  {paymentDetail.invoice_settled.map((In) => {
                                    return (
                                      <tr key={In.invoice_id}>
                                        <td>
                                          {Util.invoiceIdFormat(In.invoice_id)}
                                          <div className="small-text">
                                            {moment(In.date).format("DD/MM/YY")}
                                          </div>
                                        </td>
                                        <td>
                                          {In.types.length > 0 ? (
                                            <p
                                              style={{ whiteSpace: "pre-line" }}
                                            >
                                              {Util.getListDisplay(
                                                In.types.map((t) => t.Fee_Type),
                                                2,
                                                "\n"
                                              )}
                                            </p>
                                          ) : (
                                            "-"
                                          )}
                                        </td>

                                        <td>{Util.amountFormat(In.total)}</td>
                                        <td>
                                          {Util.amountFormat(
                                            In.amount_adjusted
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="text-right text-bold"
                                    >
                                      {t("Balance credit")}
                                    </td>
                                    <td className="text-bold">
                                      {Util.isValidData(paymentDetail) &&
                                        Util.amountFormat(
                                          paymentDetail?.not_used_amount
                                        )}
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            ) : (
                              <tfoot>
                                <tr>
                                  <td colSpan={5}>
                                    {t("No invoice at this time.")}
                                  </td>
                                </tr>
                              </tfoot>
                            )}
                          </table>
                        </div>
                      )}
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </Box>
          </div>
        </SwipeableDrawer>
      )}
    </div>
  );
}
