import React, { useEffect, useRef, useState } from "react";
import { Accordion, Card, Dropdown } from "react-bootstrap";
import PageHeader from "../../../components/PageHeader";
import ApiCalling from "../../../network/ApiCalling";
import { Modal } from "react-bootstrap";
import ToastHOC from "../../HOC/ToastHOC";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { YoutubeSearchedFor } from "@mui/icons-material";
import { Rings } from "react-loader-spinner";
import Const from "../../../Const";
import Util from "../../../Util";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";
import ModalContent from "../../../components/common/ModalContent";

const Room = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setIsToast } = props;
  const [stageData, setStageData] = useState([]);
  const [showStageModal, setShowStageModal] = useState(false);
  const [showGradeModal, setShowGradeModal] = useState(false);
  const [showRoomModal, setShowRoomModal] = useState(false);
  const [stageId, setStageId] = useState(0);
  const [stageTitle, setStageTitle] = useState("");
  const [stageTitleError, setStageTitleError] = useState("");
  const [stageSort, setStageSort] = useState(1);
  const [stageSortError, setStageSortError] = useState("");
  const [gradeId, setGradeId] = useState(0);
  const [gradeTitle, setGradeTitle] = useState("");
  const [gradeTitleError, setGradeTitleError] = useState("");
  const [roomId, setRoomId] = useState(0);
  const [roomTitle, setRoomTitle] = useState("");
  const [roomTitleError, setRoomTitleError] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteStageId, setDeleteStageId] = useState(null);
  const [deleteGradeId, setDeleteGradeId] = useState(null);
  const [deleteRoomId, setDeleteRoomId] = useState(null);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeStageModal = () => {
    setShowStageModal(false);
  };
  const closeGradeModal = () => {
    setShowGradeModal(false);
  };
  const closeRoomModal = () => {
    setShowRoomModal(false);
  };

  function addGrade(stage_id) {
    setStageId(stage_id);
    setShowGradeModal(true);
    setGradeId(0);
    setGradeTitle("");
    setGradeTitleError("");
  }

  function editGrade(item) {
    setShowGradeModal(true);
    setGradeId(item.id);
    setGradeTitle(item.title);
    setStageId(item.stage_id);
    setGradeTitleError("");
  }

  const handleGradeSave = () => {
    setGradeTitleError("");
    var error = false;
    if (gradeTitle == "") {
      setGradeTitleError(t("Grade required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      if (gradeId === 0) {
        const data = {
          title: gradeTitle,
          stage_id: stageId,
        };
        ApiCalling.apiCallBodyDataPost("grade/add", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              stageList();
              setShowGradeModal(false);
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } else {
        const data = {
          id: gradeId,
          title: gradeTitle,
          stage_id: stageId,
        };
        ApiCalling.apiCallBodyDataPost("grade/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              setShowGradeModal(false);
              stageList();
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      }
    }
  };

  const stageList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("stage/list")
      .then((res) => {
        setIsLoading(false);
        setStageData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addStage = () => {
    setShowStageModal(true);
    setStageId(0);
    setStageSort(1);
    setStageTitle("");
    setStageTitleError("");
  };

  function editStage(item) {
    setShowStageModal(true);
    setStageId(item.id);
    setStageSort(item.sort);
    setStageTitle(item.stage);
    setStageTitleError("");
  }
  const handleStageSave = () => {
    setStageTitleError("");
    setStageSortError("");
    var error = false;
    if (stageTitle === "") {
      setStageTitleError(t("Stage is required"));
      error = true;
    }
    if (stageSort === "") {
      setStageSortError(t("Sort is required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      if (stageId === 0) {
        const data = {
          stage: stageTitle,
          sort: stageSort,
        };
        ApiCalling.apiCallBodyDataPost("stage/add", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              setShowStageModal(false);
              stageList();
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      } else {
        const data = {
          id: stageId,
          stage: stageTitle,
          sort: stageSort,
        };
        ApiCalling.apiCallBodyDataPost("stage/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              setShowStageModal(false);
              stageList();
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }
    }
  };
  const handleDelete = () => {
    setIsLoading(true);
    if (deleteStageId !== null) {
      ApiCalling.apiCallDelete(`stage/${deleteStageId}`)
        .then((res) => {
          setIsLoading(false);
          setShowDeleteConfirmation(false);
          if (res.data.status == true) {
            setIsToast(t("Stage Deleted Successfully"));
            setDeleteStageId(null);
            stageList();
          } else if (res.data.status === false) {
            setIsToast(t("Somewhere used ,You Can't Delete"));
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else if (deleteGradeId !== null) {
      ApiCalling.apiCallDelete(`grade/${deleteGradeId}`)
        .then((res) => {
          setIsLoading(false);
          setShowDeleteConfirmation(false);

          setIsToast(t("Grade Deleted Successfully"));

          setDeleteGradeId(null);
          stageList();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else if (deleteRoomId !== null) {
      ApiCalling.apiCallDelete(`classroom/${deleteRoomId}`)
        .then((res) => {
          setIsLoading(false);
          setShowDeleteConfirmation(false);
          if (res.data.status === true) {
            setIsToast(t("Room Deleted Successfully"));
            setDeleteRoomId(null);
            stageList();
          } else {
            setIsToast(t(res.data.message));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };
  function addRoom(grade_id) {
    setGradeId(grade_id);
    setShowRoomModal(true);
    setRoomId(0);
    setRoomTitle("");
    setRoomTitleError("");
  }

  function editRoom(item) {
    setShowRoomModal(true);
    setRoomId(item.id);
    setRoomTitle(item.name);
    setRoomTitleError("");
  }

  const handleRoomSave = () => {
    setRoomTitleError("");
    var error = false;
    if (roomTitle == "") {
      setRoomTitleError(t("Room required"));
      error = true;
    }
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      if (roomId === 0) {
        const data = {
          name: roomTitle,
          grade_id: gradeId,
        };
        ApiCalling.apiCallBodyDataPost("classroom/add", data)
          .then((res) => {
            if (res.data.status === true) {
              stageList();
              setShowRoomModal(false);
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const data = {
          id: roomId,
          name: roomTitle,
        };
        ApiCalling.apiCallBodyDataPost("classroom/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              setShowRoomModal(false);
              stageList();
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    stageList();
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <button>
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      </button>
    </a>
  ));

  function truncateClassName(className, maxLength) {
    if (className.length <= maxLength) {
      return className;
    } else {
      return className.substring(0, maxLength - 3) + "...";
    }
  }

  return (
    <div>
      <div className="">
        <div className="row clearfix">
          <div className="header text-right mb-3">
            <button
              onClick={addStage}
              className={`${
                Util.hasPermission("CLASSROOM_ADD")
                  ? "custom-primary btn"
                  : "no-permission"
              }`}
            >
              <i className="fas fa-plus mr-1" aria-hidden="true"></i>{" "}
              {t("Add Level")}
            </button>
          </div>
        </div>
        <div className="table-div p-0 m-0">
          <div className=" col-lg col-md-12 ">
            <div className="body p-0">
              {!isLoading ? (
                <div className="d-wrapper">
                  <div className="row p-3">
                    {stageData.length > 0 ? (
                      <div className="col-lg-12 col-md-12">
                        <Accordion defaultActiveKey="0">
                          {stageData.map((item, index) => {
                            return (
                              <>
                                <Accordion.Toggle
                                  key={index}
                                  as="div"
                                  className="p-3"
                                  style={{ backgroundColor: "#FAFAFA" }}
                                  variant="link"
                                  eventKey={index + 1}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <strong>{item.stage}</strong>
                                    <div className="dots-button">
                                      <span
                                        className={`${
                                          Util.hasPermission(
                                            "CLASSROOM_EDIT"
                                          ) ||
                                          Util.hasPermission("CLASSROOM_DELETE")
                                            ? ""
                                            : "no-permission"
                                        }`}
                                      >
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            as={CustomToggle}
                                            variant="none"
                                            id="dropdown-basic"
                                          ></Dropdown.Toggle>

                                          <Dropdown.Menu className="dropdown-menu-right account">
                                            <Dropdown.Item
                                              onClick={(e) => {
                                                e.preventDefault();
                                                editStage(item);
                                              }}
                                              style={{
                                                display:
                                                  !Util.hasPermission(
                                                    "CLASSROOM_EDIT"
                                                  ) && "none",
                                              }}
                                            >
                                              <i className="fa fa-edit mr-2"></i>
                                              {t("Edit")} '{item.stage}'
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setShowDeleteConfirmation(true);
                                                setDeleteStageId(item.id);
                                              }}
                                              style={{
                                                display:
                                                  !Util.hasPermission(
                                                    "CLASSROOM_DELETE"
                                                  ) && "none",
                                              }}
                                            >
                                              <i
                                                className="fa fa-trash-o mr-2"
                                                aria-hidden="true"
                                              ></i>
                                              {t("Delete")} '{item.stage}'
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </span>
                                    </div>
                                  </div>
                                </Accordion.Toggle>

                                <Accordion className="card-body mui-exapanded">
                                  <Card.Text>
                                    <>
                                      {item.Grades.length > 0 ? (
                                        <>
                                          {item.Grades.map((g) => {
                                            return (
                                              <div
                                                className="col-lg-12"
                                                key={g.id}
                                              >
                                                <div className="dots-button">
                                                  <span className="d-flex align-items-center gap-2">
                                                    <strong>{g.title}</strong>

                                                    <Dropdown
                                                      className={`${
                                                        Util.hasPermission(
                                                          "CLASSROOM_EDIT"
                                                        ) ||
                                                        Util.hasPermission(
                                                          "CLASSROOM_DELETE"
                                                        )
                                                          ? ""
                                                          : "no-permission"
                                                      }`}
                                                    >
                                                      <Dropdown.Toggle
                                                        as={CustomToggle}
                                                        variant="none"
                                                        id="dropdown-basic"
                                                      ></Dropdown.Toggle>

                                                      <Dropdown.Menu className="dropdown-menu-right account">
                                                        <Dropdown.Item
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            editGrade(g);
                                                          }}
                                                          style={{
                                                            display:
                                                              !Util.hasPermission(
                                                                "CLASSROOM_EDIT"
                                                              ) && "none",
                                                          }}
                                                        >
                                                          <i className="fa fa-edit mr-2"></i>
                                                          {t("Edit")} '{g.title}
                                                          '
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                          style={{
                                                            display:
                                                              !Util.hasPermission(
                                                                "CLASSROOM_DELETE"
                                                              ) && "none",
                                                          }}
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            if (
                                                              g.ClassRooms
                                                                .length > 0
                                                            ) {
                                                              setIsToast(
                                                                t(
                                                                  "Can't delete grade with rooms"
                                                                )
                                                              );
                                                            } else {
                                                              setShowDeleteConfirmation(
                                                                true
                                                              );
                                                              setDeleteGradeId(
                                                                g.id
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-trash-o mr-2"
                                                            aria-hidden="true"
                                                          ></i>
                                                          {t("Delete")} '
                                                          {g.title}'
                                                        </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </span>
                                                </div>

                                                <div className="row my-2 py-2 border-top">
                                                  <div className="col-lg-2 col-md-3"></div>
                                                  {g.ClassRooms.map((room) => {
                                                    return (
                                                      <>
                                                        <div
                                                          className="col-lg-3"
                                                          key={room.id}
                                                        >
                                                          <Dropdown>
                                                            <p
                                                              className="badge badge-primary"
                                                              style={{
                                                                textTransform:
                                                                  "none",
                                                              }}
                                                            >
                                                              {truncateClassName(
                                                                room.name,
                                                                17
                                                              )}
                                                            </p>
                                                            <Dropdown.Toggle
                                                              as={CustomToggle}
                                                              variant="none"
                                                              id="dropdown-basic"
                                                            ></Dropdown.Toggle>

                                                            <Dropdown.Menu className="dropdown-menu-right account">
                                                              <Dropdown.Item
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();
                                                                  editRoom(
                                                                    room
                                                                  );
                                                                }}
                                                                style={{
                                                                  display:
                                                                    !Util.hasPermission(
                                                                      "CLASSROOM_EDIT"
                                                                    ) && "none",
                                                                }}
                                                              >
                                                                <i className="fa fa-edit fa-fw"></i>
                                                                {t("Edit")} '
                                                                {room.name}'
                                                              </Dropdown.Item>
                                                              <Dropdown.Item
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();
                                                                  setShowDeleteConfirmation(
                                                                    true
                                                                  );
                                                                  setDeleteRoomId(
                                                                    room.id
                                                                  );
                                                                }}
                                                                style={{
                                                                  display:
                                                                    !Util.hasPermission(
                                                                      "CLASSROOM_DELETE"
                                                                    ) && "none",
                                                                }}
                                                              >
                                                                <i
                                                                  className="fa fa-trash-o fa-fw"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                {t("Delete")} '
                                                                {room.name}'
                                                              </Dropdown.Item>
                                                              <Dropdown.Item
                                                                onClick={() => {
                                                                  history.push({
                                                                    pathname: `/teacher`,
                                                                    roomData: {
                                                                      room,
                                                                      g,
                                                                    },
                                                                  });
                                                                }}
                                                              >
                                                                <i
                                                                  className="fa fa-book fa-fw"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                {t("Teachers")}
                                                              </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                          </Dropdown>
                                                        </div>
                                                      </>
                                                    );
                                                  })}

                                                  <div
                                                    className={`${
                                                      g.ClassRooms.length > 0
                                                        ? "col-lg-2"
                                                        : "col-lg-12"
                                                    }`}
                                                  >
                                                    {g.ClassRooms.length ===
                                                      0 && (
                                                      <div className="text-muted">
                                                        {t(
                                                          "No room found in this grade. Add new room:"
                                                        )}
                                                      </div>
                                                    )}
                                                    <button
                                                      className={`${
                                                        Util.hasPermission(
                                                          "CLASSROOM_ADD"
                                                        )
                                                          ? "primary-xs-btn btn"
                                                          : "no-permission"
                                                      }`}
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        addRoom(g.id);
                                                      }}
                                                    >
                                                      <i
                                                        className="fas fa-plus mr-1 text-white"
                                                        aria-hidden="true"
                                                      ></i>{" "}
                                                      {t("Add Room")}
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                          <button
                                            className={`${
                                              Util.hasPermission(
                                                "CLASSROOM_ADD"
                                              )
                                                ? "custom-primary btn"
                                                : "no-permission"
                                            }`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              addGrade(item.id);
                                            }}
                                          >
                                            {t("Add Grade")}{" "}
                                            <i
                                              className="fa fa-plus mx-1 color-white "
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {Util.hasPermission(
                                            "CLASSROOM_ADD"
                                          ) && (
                                            <div className="col-lg-12">
                                              <div className="text-muted">
                                                {t(
                                                  "No grades found in this stage. Add new grade:"
                                                )}
                                              </div>
                                              <button
                                                className="custom-primary"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  addGrade(item.id);
                                                }}
                                              >
                                                {t("Add Grade")}{" "}
                                                <i
                                                  className="fa fa-plus mx-1 color-white "
                                                  aria-hidden="true"
                                                ></i>
                                              </button>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  </Card.Text>
                                </Accordion>
                              </>
                            );
                          })}
                        </Accordion>
                      </div>
                    ) : (
                      <span className="small text text-center">
                        {t(
                          "There are no Room Structure. Click the 'Add Level' button to create one."
                        )}
                      </span>
                    )}
                  </div>
                  <Modal
                    show={showGradeModal}
                    onHide={closeGradeModal}
                    animation={true}
                    centered={true}
                    className="modal-view"
                    onShow={() => {
                      setIsClickedOnce(false);
                    }}
                  >
                    <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                      <h6>
                        {gradeId == 0
                          ? t("Register New Grade")
                          : t("Edit Grade")}
                      </h6>

                      <button className="hidden-btn" onClick={closeGradeModal}>
                        <img
                          src={Close}
                          className="svg-icon"
                          height={20}
                          width={20}
                        />
                      </button>
                    </div>
                    <Modal.Body>
                      {!isLoading ? (
                        <div className="input-field text-start">
                          <label className="basic-text required">
                            {t("Grade Title")}
                          </label>
                          <input
                            className={`form-control custom-input ${
                              gradeTitleError.length > 0 && "input-error"
                            }`}
                            type="text"
                            value={gradeTitle}
                            onChange={(e) => setGradeTitle(e.target.value)}
                          />
                          {gradeTitleError.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {gradeTitleError}
                            </span>
                          )}
                        </div>
                      ) : (
                        <Loader />
                      )}
                    </Modal.Body>
                    <Modal.Footer className="border-0">
                      <button
                        className="btn custom-primary"
                        onClick={handleGradeSave}
                      >
                        {t("Save")}
                      </button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={showStageModal}
                    onHide={closeStageModal}
                    animation={true}
                    centered={true}
                    className="modal-view"
                    onShow={() => {
                      setIsClickedOnce(false);
                    }}
                  >
                    <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                      <h6>
                        {stageId == 0
                          ? t("Register New Level")
                          : t("Edit Level")}
                      </h6>

                      <button className="hidden-btn" onClick={closeStageModal}>
                        <img
                          src={Close}
                          className="svg-icon"
                          height={20}
                          width={20}
                        />
                      </button>
                    </div>
                    <ModalContent isLoading={isLoading}>
                      <Modal.Body>
                        <>
                          <div className="input-filed text-start">
                            <label className="basic-text required">
                              {t("Level")}
                            </label>
                            <input
                              className={`form-control custom-input ${
                                stageTitleError.length > 0 && "input-error"
                              }`}
                              type="text"
                              value={stageTitle}
                              onChange={(e) => {
                                setStageTitle(e.target.value);
                                setStageTitleError("");
                              }}
                            />
                            {stageTitleError.length > 0 && (
                              <span className="text-danger invalid-error">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {stageTitleError}
                              </span>
                            )}
                          </div>
                          <div className="input-filed text-start">
                            <label className="basic-text">{t("Sort")} </label>
                            <input
                              className="form-control custom-input"
                              type="number"
                              value={stageSort}
                              onChange={(e) => {
                                setStageSort(e.target.value);
                              }}
                            />
                            {stageSortError.length > 0 && (
                              <span className="text-danger">
                                {stageSortError}
                              </span>
                            )}
                          </div>
                        </>
                      </Modal.Body>
                      <Modal.Footer className="border-0">
                        <button
                          className="btn custom-primary"
                          onClick={handleStageSave}
                        >
                          {t("Save")}
                        </button>
                      </Modal.Footer>
                    </ModalContent>
                  </Modal>

                  <Modal
                    show={showRoomModal}
                    onHide={closeRoomModal}
                    animation={true}
                    centered={true}
                    className="modal-view"
                    onShow={() => {
                      setIsClickedOnce(false);
                    }}
                  >
                    <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                      <h6>
                        {roomId == 0 ? t("Register New Room") : t("Edit Room")}
                      </h6>

                      <button className="hidden-btn" onClick={closeRoomModal}>
                        <img
                          src={Close}
                          className="svg-icon"
                          height={20}
                          width={20}
                        />
                      </button>
                    </div>
                    <Modal.Body>
                      {!isLoading ? (
                        <div className="input-filed text-start">
                          <label className="basic-text required">
                            {t("Room Name")}
                          </label>
                          <input
                            className={`form-control custom-input ${
                              roomTitleError.length > 0 && "input-error"
                            }`}
                            type="text"
                            value={roomTitle}
                            onChange={(e) => {
                              setRoomTitle(e.target.value);
                              setRoomTitleError("");
                            }}
                          />
                          {roomTitleError.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {roomTitleError}
                            </span>
                          )}
                        </div>
                      ) : (
                        <Loader />
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn custom-primary"
                        onClick={handleRoomSave}
                      >
                        {t("Save")}
                      </button>
                    </Modal.Footer>
                  </Modal>

                  <ModalComponent
                    show={showDeleteConfirmation}
                    onHide={() => {
                      setShowDeleteConfirmation(false);
                      setDeleteStageId(null);
                      setDeleteGradeId(null);
                      setDeleteRoomId(null);
                    }}
                    title={"Delete"}
                    size="sm"
                    onCancel={t("Cancel")}
                    onConfirm={t("Confirm")}
                    onCancelClick={() => {
                      setShowDeleteConfirmation(false);
                      setDeleteStageId(null);
                      setDeleteGradeId(null);
                      setDeleteRoomId(null);
                    }}
                    onConfirmClick={handleDelete}
                  >
                    <span>
                      {t("Are you Sure to delete this")}{" "}
                      {deleteStageId !== null
                        ? t("Level")
                        : deleteGradeId !== null
                        ? t("Grade")
                        : t("Room")}
                    </span>
                  </ModalComponent>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(Room);
