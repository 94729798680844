import { Box, SwipeableDrawer } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApiCalling from "../../../network/ApiCalling";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExitAlert from "../../../components/ExitAlert";
import Calender from "../../../assets/Icon/calendar.svg";
import Invalid from "../../../assets/Icon/invalid.svg";
import Loader from "../../../components/common/Loader";
import { ToastComponent } from "../../HOC/ToastHOC";
import Close from "../../../assets/Icon/close.png";

function CreateBillingPlan({ show, onClose, onSave, updateData, item }) {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const getLanguage = Util.getSelectedLanguage();
  const datepickerRef = useRef(null);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState("");
  const [period, setPeriod] = useState("");
  const [periodError, setPeriodError] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [feeTypeList, setFeeTypeList] = useState([]);
  const [saveInLibrary, setSaveInLibrary] = useState(false);
  const [enabledEndDates, setEnabledEndDates] = useState([]);
  const [lastPaymentDaysList, setLastPaymentDaysList] = useState([]);
  const [lastPaymentDay, setLastPaymentDay] = useState(0);
  const [lastPaymentDayError, setLastPaymentDayError] = useState("");
  const [billingPlanLibraryId, setBillingPlanLibraryId] = useState(0);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [billPlanList, setBillPlanList] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastSubtitle, setToastSubtitle] = useState("");
  const [toastType, setToastType] = useState("success");
  const [termsId, setTermsId] = useState(0);
  const [terms, setTerms] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [selectTax, setSelectTax] = useState(false);
  const [fields, setFields] = useState([
    {
      id: 1,
      taxId: 0,
      taxPercentage: 0,
    },
  ]);
  const [fieldError, setFieldError] = useState(false);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [stepper, setStepper] = useState(false);
  const [feeTypefields, setFeeTypeFields] = useState([
    {
      id: 1,
      feeTypeId: 0,
      amount: "",
    },
  ]);
  const [fieldFeeTypeError, setFeeTypeFieldError] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [discountType, setDiscountType] = useState("");
  const [discountTypeError, setDiscountTypeError] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountAmountError, setDiscountAmountError] = useState("");

  const periodList = [
    // { value: "WEEKLY", name: "WEEKLY" },
    // { value: "BY-WEEKLY", name: "BY-WEEKLY" },
    { value: "MONTHLY", name: "MONTHLY" },
    { value: "QUARTERLY", name: "QUARTERLY" },
    { value: "ONE TIME", name: "ONE TIME" },
  ];
  const CustomInput = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className={`custom-input__field ${
          startDateError.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  ));

  const currency = Util.getSelectedSchool()?.currency;

  const handleName = (e) => {
    setName(e.target.value);
    setNameError("");
    setIsStateUpdated(true);
  };
  const handleStartDate = (date) => {
    setStartDate(date);
    setStartDateError("");
    getSelectedPeriod(period, date);
    if (period == "ONE TIME") {
      setEndDate(date);
    }
    setIsStateUpdated(true);
  };

  const handleLastPaymentDay = (e) => {
    const days = e.target.value;
    setLastPaymentDay(days);
    setLastPaymentDayError("");
    setIsStateUpdated(true);
  };

  const getSelectedPeriod = (period, date) => {
    if (period === "WEEKLY") {
      const startDateObj = new Date(date);
      const endDateObj = new Date("2099-12-31");
      const enabledDates = [];
      enabledDates.push(startDateObj);

      let currentDate = new Date(startDateObj);
      while (currentDate < endDateObj) {
        currentDate.setDate(currentDate.getDate() + 7);
        enabledDates.push(new Date(currentDate));
      }
      const formattedDate = enabledDates.map((d) =>
        moment(d).format("YYYY-MM-DD")
      );

      setEnabledEndDates(formattedDate);
      fillDays(7);
    } else if (period === "BY-WEEKLY") {
      const startDateObj = new Date(date);
      const endDateObj = new Date("2099-12-31");
      const enabledDates = [];
      enabledDates.push(startDateObj);

      let currentDate = new Date(startDateObj);
      while (currentDate < endDateObj) {
        currentDate.setDate(currentDate.getDate() + 14);
        enabledDates.push(new Date(currentDate));
      }
      const formattedDate = enabledDates.map((d) =>
        moment(d).format("YYYY-MM-DD")
      );

      setEnabledEndDates(formattedDate);
      fillDays(15);
    } else if (period === "MONTHLY") {
      const startDateObj = new Date(date);
      const endDateObj = new Date("2099-12-31");
      const enabledDates = [];
      enabledDates.push(startDateObj);

      let currentDate = new Date(startDateObj);
      while (currentDate < endDateObj) {
        currentDate.setMonth(currentDate.getMonth() + 1);
        enabledDates.push(new Date(currentDate));
      }
      const formattedDate = enabledDates.map((d) =>
        moment(d).format("YYYY-MM-DD")
      );
      setEnabledEndDates(formattedDate);
      fillDays(30);
    } else if (period === "QUARTERLY") {
      const startDateObj = new Date(date);
      const endDateObj = new Date("2099-12-31");
      const enabledDates = [];
      enabledDates.push(startDateObj);

      let currentDate = new Date(startDateObj);
      while (currentDate < endDateObj) {
        currentDate.setMonth(currentDate.getMonth() + 3);
        enabledDates.push(new Date(currentDate));
      }
      const formattedDate = enabledDates.map((d) =>
        moment(d).format("YYYY-MM-DD")
      );
      setEnabledEndDates(formattedDate);
      fillDays(30);
    } else if (period === "ONE TIME") {
      // handleEndDate(new Date(date))
      // console.log(date)
      fillDays(30);
    } else {
      setEnabledEndDates([]);
    }
  };

  const handlePeriod = (e) => {
    setIsStateUpdated(true);
    const selectedPeriod = e.target.value;
    setPeriod(selectedPeriod);
    setPeriodError("");
    getSelectedPeriod(selectedPeriod, startDate);
    if (selectedPeriod == "ONE TIME") {
      setEndDate(startDate);
    } else {
      setEndDate(null);
    }
  };

  const fillDays = (days) => {
    const arr = [];
    for (var i = 1; i < days; i++) {
      arr.push({ value: i, name: i + " day(s) after invoice generation date" });
    }
    setLastPaymentDaysList(arr);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setIsStateUpdated(true);
  };

  const handleTerms = (e) => {
    setTermsId(e.target.value);
    setIsStateUpdated(true);
  };

  const handleSelectTax = (e) => {
    setSelectTax(e.target.checked);
    setIsStateUpdated(true);
    if (e.target.checked == false) {
      setFields([
        {
          id: 1,
          taxId: 0,
          taxPercentage: 0,
        },
      ]);
      setFieldError(false);
      // setTotalAmount(0);
      // setTotalTaxAmount(0);
      // setSubTotalAmount(0);
    } else {
      // setTotalAmount(amount);
      setTotalTaxAmount(0);
      // setSubTotalAmount(amount);
    }
  };
  const handleTax = (index, event) => {
    setIsStateUpdated(true);
    const newFields = [...fields];
    const filteredData = taxList.filter((it) => it.id == event.target.value);
    newFields[index]["taxId"] = event.target.value;
    newFields[index]["taxPercentage"] = filteredData[0]?.tax_percentage;
    setFields(newFields);

    let total;
    let taxDetails = newFields.map((ele) => {
      const taxAmount = (ele.taxPercentage / 100) * Number(subTotalAmount - discountAmount);
      return { taxName: ele.taxId, taxAmount };
    });
    total =
      Number(subTotalAmount - discountAmount) +
      taxDetails.reduce((sum, tax) => sum + tax.taxAmount, 0);
    const sum = taxDetails.reduce(
      (acc, field) => acc + Number(field.taxAmount),
      0
    );
    // setSubTotalAmount(subTotalAmount - discountAmount);
    setTotalAmount(total);
    setTotalTaxAmount(sum);
  };

  const handleAddField = () => {
    setIsStateUpdated(true);
    setFields([
      ...fields,
      {
        id: fields.length + 1,
        taxId: 0,
        taxPercentage: 0,
      },
    ]);
  };

  const handleRemoveField = (index) => {
    if (fields.length <= 1) {
      setFields([
        {
          id: 1,
          taxId: 0,
          taxPercentage: 0,
        },
      ]);
      setTotalTaxAmount(0);
      setTotalAmount(subTotalAmount);
      return;
    }
    setIsStateUpdated(true);
    const newFields = fields.filter((field, i) => i !== index);
    setFields(newFields);
    let total;
    let taxDetails = newFields.map((ele) => {
      const taxAmount = (ele.taxPercentage / 100) * Number(subTotalAmount - discountAmount);
      return { taxName: ele.taxId, taxAmount };
    });
    total =
      Number(subTotalAmount - discountAmount) +
      taxDetails.reduce((sum, tax) => sum + tax.taxAmount, 0);
    const sum = taxDetails.reduce(
      (acc, field) => acc + Number(field.taxAmount),
      0
    );
    // setSubTotalAmount(subTotalAmount - discountAmount);
    setTotalAmount(total);
    setTotalTaxAmount(sum);
  };

  const handleFeeTypeAddField = () => {
    setIsStateUpdated(true);
    setFeeTypeFields([
      ...feeTypefields,
      {
        id: feeTypefields.length + 1,
        feeTypeId: 0,
        amount: "",
      },
    ]);
  };

  const handleFeeTypeRemoveField = (index) => {
    setIsStateUpdated(true);
    setFeeTypeFields(feeTypefields.filter((field, i) => i !== index));
  };

  const handleInputChange = (index, event) => {
    setIsStateUpdated(true);
    const newFields = [...feeTypefields];
    newFields[index][event.target.name] = event.target.value;
    setFeeTypeFields(newFields);
  };

  const handleFeeTypeId = (index, event) => {
    setIsStateUpdated(true);
    const newFields = [...feeTypefields];
    newFields[index]["feeTypeId"] = event.target.value;
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
  };

  const handleDiscount = (e) => {
    setDiscount(e.target.checked);
    setIsStateUpdated(true);
  };
  const handleDiscountType = (e) => {
    setDiscountType(e.target.value);
    setDiscountTypeError("");
    setIsStateUpdated(true);
  };
  const handleDiscountAmount = (e) => {
    const amount = e.target.value;
    setDiscountAmount(amount);
    setDiscountAmountError("");
    // setTotalAmount(subTotalAmount - Number(amount));
    setIsStateUpdated(true);
  };

  const handleSaveInLibrary = (e) => {
    setSaveInLibrary(!saveInLibrary);
    setIsStateUpdated(true);
  };
  const handleBillingPlanLibraryId = (e) => {
    setIsStateUpdated(true);
    setBillingPlanLibraryId(e.target.value);
    const templatePlan = billPlanList.find((obj) => {
      return obj.id === Number(e.target.value);
    });

    if (templatePlan !== undefined) {
      setName(templatePlan.name);
      setPeriod(templatePlan.period);
      setLastPaymentDay(templatePlan.last_payment_date);
    } else {
      setName("");
      setPeriod("");
      setLastPaymentDay(0);
      setBillingPlanLibraryId(0);
    }
  };
  const handleSave = () => {
    setIsLoading(true);
    const start_date = moment(startDate).format("YYYY-MM-DD");
    const end_date = moment(endDate).format("YYYY-MM-DD");
    const data = {
      name: name,
      start_date: start_date,
      end_date: end_date,
      period: period,
      save_in_library: saveInLibrary,
      last_payment_date: lastPaymentDay,
      terms_id: termsId,
      tax_id: fields.map((it) => it.taxId),
      fee_type_ids: feeTypefields,
      discount_amount: discountAmount,
      discount_type: discountType,
      amount: totalAmount,
      sub_total: subTotalAmount
    };
    console.log("DA", data);
    if (show.isEdit) {
      ApiCalling.apiCallBodyDataPost(
        `billing-plans/updatePlan/${item.id}`,
        data
      )
        .then((res) => {
          if (res.data?.status === true) {
            setIsLoading(false);
            onSave(res.data.message);
            onClose();
            reset();
            updateData();
          } else {
            setIsLoading(false);
            setShowNotification(true);
            setToastType("error");
            setToastTitle("Error");
            setToastSubtitle(res.data?.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      ApiCalling.apiCallBodyDataPost("billing-plans/add", data)
        .then((res) => {
          if (res.data?.status === true) {
            setIsLoading(false);
            reset();
            onSave(res.data.message);
            onClose();
            updateData();
          } else {
            setIsLoading(false);
            setShowNotification(true);
            setToastType("error");
            setToastTitle("Error");
            setToastSubtitle(res.data?.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const validate = () => {
    setIsStateUpdated(false);
    let error = false;
    setNameError("");
    setStartDateError("");
    setPeriodError("");
    setLastPaymentDayError("");
    setFieldError(false);
    setFeeTypeFieldError(false);
    setDiscountTypeError("");
    setDiscountAmountError("");
    if (name === "") {
      setNameError(t("Name is required"));
      error = true;
    }
    if (startDate == null) {
      setStartDateError(t("Start Date is required"));
      error = true;
    }
    if (period === "") {
      setPeriodError(t("Period is required"));
      error = true;
    }
    if (lastPaymentDay === 0) {
      setLastPaymentDayError(
        t("Last Payment date of invoice After is required")
      );
      error = true;
    }
    fields.map((f) => {
      if (selectTax && f.taxId == 0) {
        setFieldError(true);
        error = true;
      }
    });
    feeTypefields.map((f) => {
      if (f.feeTypeId == 0 || f.amount == "") {
        setFeeTypeFieldError(true);
        error = true;
      }
    });
    if (discount && discountType == "") {
      setDiscountTypeError(t("Discount Type is required"));
      error = true;
    }
    if (discount && discountAmount == 0) {
      setDiscountAmountError(t("Discount Amount is required"));
      error = true;
    }
    console.log(feeTypefields);
    if (!error) {
      setStepper(false);

      const sum = feeTypefields.reduce(
        (acc, field) => acc + Number(field.amount),
        0
      );
      setSubTotalAmount(sum);
      setTotalAmount(sum - discountAmount);
    }
  };

  const feeTypeData = () => {
    ApiCalling.apiCallBodyDataGet("fee-types/list")
      .then((res) => {
        setFeeTypeList(res.data || []);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const termsData = () => {
    ApiCalling.apiCallBodyDataGet("terms/school")
      .then((res) => {
        setTerms(res.data || []);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    feeTypeData();
    termsData();
    getTaxList();
    setStepper(true);
  }, []);

  useEffect(() => {
    console.log("getBillPlanList useEffect");
    let mounted = true;

    const getBillPlanList = () => {
      setIsLoading(true);
      ApiCalling.apiCallBodyDataGet("billing-plans/saved")
        .then((res) => {
          if (mounted) {
            setIsLoading(false);
            setBillPlanList(res.data);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    getBillPlanList();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    // console.log(item);
    if (Object.keys(item) !== 0 && show.show && show.isEdit) {
      setName(item.name);
      setStartDate(
        Util.isValidData(item.start_date) ? new Date(item.start_date) : null
      );
      setPeriod(item.period);
      setEndDate(
        Util.isValidData(item.end_date) ? new Date(item.end_date) : null
      );
      setLastPaymentDay(item.last_payment_date);
      getSelectedPeriod(item.period, item.start_date);
      setTermsId(item.terms_id);
      if(item?.BillingInvoiceFee && item?.BillingInvoiceFee.length > 0){
        const fields = item?.BillingInvoiceFee.map((feetype, index) => ({
          id: index + 1,
          feeTypeId : feetype.Fee_Type.id,
          amount: feetype.total
        }))
        setFeeTypeFields(fields);
      }
      if (item?.BillingPlanTax && item.BillingPlanTax.length > 0) {
        const taxFields = item.BillingPlanTax.map((billingPlanTax, index) => ({
          id: index + 1,
          taxId: billingPlanTax?.tax_id,
          taxPercentage: billingPlanTax?.Tax?.tax_percentage,
        }));
        setSelectTax(true);
        setFields(taxFields);
        let totalAmount;
        let taxDetails = taxFields.map((ele) => {
          const taxAmount = (ele.taxPercentage / 100) * Number(item.amount);
          return { taxName: ele.taxId, taxAmount };
        });
        totalAmount =
          Number(item.amount) +
          taxDetails.reduce((sum, tax) => sum + tax.taxAmount, 0);
        const sum = taxDetails.reduce(
          (acc, field) => acc + Number(field.taxAmount),
          0
        );
        setSubTotalAmount(item.amount);
        setTotalAmount(totalAmount);
        setTotalTaxAmount(sum);
      }
      setDiscountType(item?.discount_type);
      setDiscountAmount(item?.discount_amount);
    }
  }, [item, show]);

  const reset = () => {
    setName("");
    setStartDate(null);
    setPeriod("");
    setEndDate(null);
    setSaveInLibrary(false);
    setNameError("");
    setStartDateError("");
    setPeriodError("");
    setBillingPlanLibraryId(0);
    setLastPaymentDay(0);
    setLastPaymentDayError("");
    setTermsId(0);
    setSelectTax(false);
    setFields([
      {
        id: 1,
        taxId: 0,
        taxPercentage: 0,
      },
    ]);
    setFieldError(false);
    setFeeTypeFields([
      {
        id: 1,
        feeTypeId: 0,
        amount: "",
      },
    ]);
    setFeeTypeFieldError(false);
    setTotalAmount(0);
    setTotalTaxAmount(0);
    setSubTotalAmount(0);
    setStepper(true);
    setDiscount(false);
    setDiscountType("");
    setDiscountAmount(0);
    setDiscountTypeError("");
    setDiscountAmountError("");
  };

  const getTaxList = () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("tax/school")
      .then((res) => {
        setTaxList(res.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage === "ar" ? "left" : "right"}
        open={show.show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            onClose();
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "656px",
              padding: "20px",
              position: "relative",
            }}
            role="presentation"
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">
                {show.isEdit ? "Edit Billing Plan" : t("Add Billing Plan")}
              </div>
              {/* <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    onClose();
                    reset();
                    setStepper(true);
                    setIsStateUpdated(false);
                  }
                }}
              >
                <CloseIcon />
              </IconButton> */}
               <button
                className="hidden-btn"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    onClose();
                    reset();
                    setStepper(true);
                    setIsStateUpdated(false);
                  }
                }}
              >
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>

            {!isLoading ? (
              <div>
                <div className="body mt-3">
                  <div
                    className="row text-start mt-2"
                    style={{ paddingBottom: "50px" }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center mb-1"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      <div>
                        {stepper ? t("Billing Details") : t("Tax Details")}
                      </div>
                      <div>{stepper ? 1 : 2}/2</div>
                    </div>

                    <div className="d-flex align-items-center gap-2">
                      <div className="active-stepper"></div>
                      <div
                        className={
                          stepper ? "inactive-stepper" : "active-stepper"
                        }
                      ></div>
                    </div>

                    {stepper ? (
                      <>
                        <div className="input-filed pb-2 mt-4">
                          <span className="basic-text required">
                            {t("What should the billing plan be called?")}
                          </span>
                          <input
                            type="text"
                            className={`form-control ${
                              nameError.length > 0
                                ? "input-error"
                                : "custom-input"
                            }`}
                            onChange={handleName}
                            value={name}
                          />
                          {nameError.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                                alt="Invalid Icon"
                              />
                              {nameError}
                            </span>
                          )}
                        </div>

                        <div className="input-filed pb-2">
                          <span className="basic-text required">
                            {t("When will first invoice be generated?")}
                          </span>

                          <DatePicker
                            ref={datepickerRef}
                            selected={startDate}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={handleStartDate}
                            dateFormat={school.date_format}
                            popperPlacement="top"
                            customInput={<CustomInput icon={Calender} />}
                            disabled={show.isEdit && item.students.length > 0}
                          />

                          {startDateError.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                                alt="Invalid Icon"
                              />
                              {startDateError}
                            </span>
                          )}
                        </div>

                        <div className="input-filed pb-2">
                          <span className="basic-text required">
                            {t("How often should payers receive invoices?")}
                          </span>
                          <select
                            name=""
                            id=""
                            className={`select-dropdown  form-select ${
                              periodError.length > 0
                                ? "input-error"
                                : "custom-input"
                            }`}
                            value={period}
                            onChange={handlePeriod}
                            disabled={
                              !startDate ||
                              (show.isEdit && item.students.length > 0)
                            }
                          >
                            <option disabled value="">
                              {t("Select Period")}
                            </option>
                            {periodList?.map((item) => (
                              <option value={item.value} key={item.value}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {periodError.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                                alt="Invalid Icon"
                              />
                              {periodError}
                            </span>
                          )}
                        </div>

                        <div className="input-filed pb-2">
                          <span className="basic-text">
                            {t("Last invoice will generate on?")}
                          </span>
                          <DatePicker
                            selected={endDate}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={handleEndDate}
                            dateFormat={school.date_format}
                            className="form-control custom-input"
                            popperPlacement="top"
                            disabled={
                              !period ||
                              (show.isEdit && item.students.length > 0) ||
                              period === "ONE TIME"
                            }
                            filterDate={(d) =>
                              enabledEndDates.includes(
                                moment(d).format("YYYY-MM-DD")
                              )
                            }
                          />
                        </div>

                        <div className="input-filed pb-2">
                          <span className="basic-text required">
                            {t("Last payment date of invoice must be after?")}
                          </span>
                          <select
                            name=""
                            id=""
                            className={`select-dropdown form-select ${
                              lastPaymentDayError.length > 0
                                ? "input-error"
                                : "custom-input"
                            }`}
                            value={lastPaymentDay}
                            onChange={handleLastPaymentDay}
                            disabled={
                              !startDate ||
                              (show.isEdit && item.students.length > 0)
                            }
                          >
                            <option disabled value={0}>
                              {t("Select Days")}
                            </option>
                            {lastPaymentDaysList?.map((item) => (
                              <option value={item.value} key={item.value}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {lastPaymentDayError.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                                alt="Invalid Icon"
                              />
                              {lastPaymentDayError}
                            </span>
                          )}
                        </div>

                        <div className="">
                          <span className="basic-text required">
                            {t("Select charge for this plan")}
                          </span>

                          {feeTypefields.map((f, index) => {
                            return (
                              <div className="row" key={index}>
                                <div className="col-8 mt-2">
                                  {index + 1} {". "}
                                  <select
                                    name="feeTypeId"
                                    id="feeTypeId"
                                    style={{
                                      display: "inline-block",
                                      width: "95%",
                                    }}
                                    className="select-dropdown form-select custom-input"
                                    value={f.feeTypeId}
                                    onChange={(event, i) =>
                                      handleFeeTypeId(index, event)
                                    }
                                    disabled={show.isEdit && item.students.length > 0}
                                  >
                                    <option value={0}>
                                      {t("Select Charge")}
                                    </option>
                                    {feeTypeList?.map((item) => (
                                      <option value={item.id} key={item.id}>
                                        {item.type}
                                      </option>
                                    ))}
                                  </select>
                                  {fieldFeeTypeError && f.feeTypeId == 0 && (
                                    <span className="text-danger invalid-error">
                                      <img
                                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                                        src={Invalid}
                                      />
                                      {t("Charge is required")}
                                    </span>
                                  )}
                                </div>
                                <div className="input-filed col-3 mt-2">
                                  <input
                                    placeholder="Amount *"
                                    name="amount"
                                    type="number"
                                    className="form-control custom-input"
                                    onChange={(event) =>
                                      handleInputChange(index, event)
                                    }
                                    value={f.amount}
                                    disabled={show.isEdit && item.students.length > 0}
                                  />
                                  {fieldFeeTypeError && f.amount == "" && (
                                    <span className="text-danger invalid-error">
                                      <img
                                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                                        src={Invalid}
                                      />
                                      {t("Amount is required")}
                                    </span>
                                  )}
                                </div>
                                {feeTypefields.length > 1 && (
                                  <button
                                    className="col-1"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleFeeTypeRemoveField(index);
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      backgroundColor: "transparent",
                                      justifyContent: "center",
                                      marginTop: "-20px",
                                    }}
                                    disabled={show.isEdit && item.students.length > 0}
                                  >
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                )}
                              </div>
                            );
                          })}
                        </div>

                        <button
                          style={{
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#6418c3",
                            cursor: "pointer",
                            backgroundColor: "transparent",
                            textAlign: "left",
                          }}
                          className="mb-3"
                          onClick={handleFeeTypeAddField}
                          disabled={show.isEdit && item.students.length > 0}
                        >
                          + {t("Add")}
                        </button>

                        <div className="input-filed pb-2">
                          <span className="basic-text">
                            {t("Select terms for this plan")}
                          </span>
                          <select
                            name=""
                            id=""
                            className="select-dropdown form-select custom-input"
                            value={termsId}
                            onChange={handleTerms}
                            disabled={show.isEdit && item.students.length > 0}
                          >
                            <option disabled value={0}>
                              {t("Select Terms")}
                            </option>
                            {terms?.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="d-flex justify-content-start gap-4 mt-1">
                          <div className="input-filed mt-3">
                            <label
                              style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                              <span className="mr-2">
                                <i></i>
                                {t("Apply discount?")}
                              </span>
                              <input
                                name="holiday"
                                type="checkbox"
                                checked={discount}
                                onChange={handleDiscount}
                                disabled={show.isEdit && item.students.length > 0}
                              />
                              <span tabIndex={0}></span>
                            </label>
                          </div>

                          {discount && (
                            <div className="d-flex align-items-center gap-5">
                              <div className="input-filed">
                                <input
                                  type="text"
                                  placeholder="Type / Reference"
                                  className={`form-control ${
                                    discountTypeError.length > 0
                                      ? "input-error"
                                      : "custom-input"
                                  }`}
                                  onChange={handleDiscountType}
                                  value={discountType}
                                  disabled={show.isEdit && item.students.length > 0}
                                />
                                {discountTypeError.length > 0 && (
                                  <span className="text-danger invalid-error">
                                    <img
                                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                                      src={Invalid}
                                    />
                                    {discountTypeError}
                                  </span>
                                )}
                              </div>
                              <div className="input-filed">
                                <input
                                  type="number"
                                  placeholder="disco  unt"
                                  className={`form-control ${
                                    discountAmountError.length > 0
                                      ? "input-error"
                                      : "custom-input"
                                  }`}
                                  onChange={handleDiscountAmount}
                                  value={discountAmount}
                                  disabled={show.isEdit && item.students.length > 0}
                                />
                                {discountAmountError.length > 0 && (
                                  <span className="text-danger invalid-error">
                                    <img
                                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                                      src={Invalid}
                                    />
                                    {discountAmountError}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="input-filed pb-2 mt-4">
                          <label
                            style={{ fontSize: "16px", fontWeight: "normal" }}
                          >
                            <span className="mr-2">
                              <i></i>
                              {t("Apply tax?")}
                            </span>
                            <input
                              name="tax"
                              type="checkbox"
                              checked={selectTax}
                              onChange={handleSelectTax}
                              disabled={show.isEdit && item.students.length > 0}
                            />
                            <span tabIndex={0}></span>
                          </label>
                        </div>

                        {selectTax && (
                          <div className="row">
                            <div className="col-8">
                              <label>Type of Tax</label>
                            </div>
                            <div className="col-3">
                              <label>Tax Percentage</label>
                            </div>
                          </div>
                        )}
                        {selectTax &&
                          fields.map((f, index) => {
                            const availableTaxList = taxList?.filter(
                              (item) =>
                                !fields.some(
                                  (field, i) =>
                                    i != index && field.taxId == item.id
                                )
                            );
                            return (
                              <div className="row" key={index}>
                                <div className="col-8 mt-2">
                                  {index + 1} {". "}
                                  <select
                                    name="taxId"
                                    id="taxId"
                                    style={{
                                      display: "inline-block",
                                      width: "95%",
                                    }}
                                    className="select-dropdown form-select custom-input"
                                    value={f.taxId}
                                    onChange={(event, i) =>
                                      handleTax(index, event)
                                    }
                                    disabled={
                                      show.isEdit && item.students.length > 0
                                    }
                                  >
                                    <option value={0}>{t("Select tax")}</option>
                                    {availableTaxList?.map((item) => (
                                      <option value={item.id} key={item.id}>
                                        {item.tax_name}
                                      </option>
                                    ))}
                                  </select>
                                  {fieldError && f.taxId == 0 && (
                                    <span className="text-danger invalid-error">
                                      <img
                                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                                        src={Invalid}
                                      />
                                      {t("Tax is required")}
                                    </span>
                                  )}
                                </div>
                                <div className="input-filed col-3 mt-2">
                                  <input
                                    placeholder="Tax Percentage"
                                    name="amount"
                                    type="number"
                                    className="form-control custom-input"
                                    value={f.taxPercentage}
                                    disabled
                                  />
                                </div>
                                {/* {fields.length > 1 && ( */}
                                <button
                                  className="col-1"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveField(index);
                                  }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    justifyContent: "center",
                                    marginTop: "-20px",
                                  }}
                                  disabled={
                                    show.isEdit && item.students.length > 0
                                  }
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                {/* // )} */}
                              </div>
                            );
                          })}
                        <div>
                          {selectTax && (
                            <button
                              style={{
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#6418c3",
                                cursor: "pointer",
                                backgroundColor: "transparent",
                                textAlign: "left",
                              }}
                              onClick={handleAddField}
                              disabled={show.isEdit && item.students.length > 0}
                            >
                              + {t("Add")}
                            </button>
                          )}
                        </div>

                        <div className="row">
                          <div className="col-8 text-right text-bold">
                            {t("Sub Total")}
                          </div>
                          <div className="col-3">
                            {currency === "USD"
                              ? "$"
                              : currency === "AED"
                              ? "AED"
                              : "$"}{" "}
                            {subTotalAmount}
                          </div>

                          <div className="col-8 text-right text-bold">
                            {t("Discount Amount")}
                          </div>
                          <div className="col-3">
                           - {currency === "USD"
                              ? "$"
                              : currency === "AED"
                              ? "AED"
                              : "$"}{" "}
                            {discountAmount}
                          </div>

                          <div className="col-8 text-right text-bold">
                            {t("Tax Amount")}
                          </div>
                          <div className="col-3">
                            {currency === "USD"
                              ? "$"
                              : currency === "AED"
                              ? "AED"
                              : "$"}{" "}
                            {totalTaxAmount}
                          </div>

                          <div className="col-8 text-right text-bold mt-3">
                            <span
                              style={{
                                fontSize: "18px",
                                fontWeight: "700",
                                color: "#07112b",
                              }}
                            >
                              {" "}
                              {t("Total")}
                            </span>
                          </div>
                          <div className="col-3 mt-3">
                            <span
                              style={{
                                fontSize: "18px",
                                fontWeight: "700",
                                color: "#07112b",
                              }}
                            >
                              {currency === "USD"
                                ? "$"
                                : currency === "AED"
                                ? "AED"
                                : "$"}{" "}
                              {totalAmount}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="border-top "
                  style={{
                    position: "fixed",
                    width: "656px",
                    bottom: "0px",
                    right: getLanguage !== "ar" && "0px",
                    background: "#ffffff",
                    padding: "10px",
                  }}
                >
                  {/* <button
                    className="secondary-md-btn btn mr-2"
                    onClick={() => {
                      if (isStateUpdated) {
                        setIsAlertModal(true);
                      } else {
                        onClose();
                        reset();
                        setIsStateUpdated(false);
                      }
                    }}
                  >
                    {t("Cancel")}
                  </button>

                  <button
                    style={{ float: "right" }}
                    className="primary-lg-btn btn"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    {t("Save")}
                  </button> */}

                  {stepper ? (
                    <button
                      className="secondary-md-btn btn ml-2"
                      onClick={() => {
                        if (isStateUpdated) {
                          setIsAlertModal(true);
                        } else {
                          onClose();
                          reset();
                          setIsStateUpdated(false);
                        }
                      }}
                    >
                      {t("Cancel")}
                    </button>
                  ) : (
                    <button
                      className="secondary-md-btn btn mr-2"
                      onClick={() => {
                        setStepper(true);
                        setSelectTax(false);
                        setFields([
                          {
                            id: 1,
                            taxId: 0,
                            taxPercentage: 0,
                          },
                        ]);
                        setTotalTaxAmount(0)
                      }}
                    >
                      {t("Previous")}
                    </button>
                  )}

                  {stepper ? (
                    <>
                      <button
                        style={{ float: "right" }}
                        className="primary-btn btn"
                        onClick={() => {
                          validate();
                        }}
                      >
                        {t("Continue")}
                      </button>
                    </>
                  ) : (
                    <button
                      style={{ float: "right" }}
                      className="primary-xl-btn btn"
                      onClick={handleSave}
                    >
                      {t("Save")}
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </Box>
        </div>

        {showNotification && (
          <ToastComponent
            showNotification={showNotification}
            setShowNotification={setShowNotification}
            title={toastTitle}
            subtitle={toastSubtitle}
            type={toastType}
          />
        )}
      </SwipeableDrawer>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
        onOk={() => {
          onClose();
          setIsAlertModal(false);
          reset();
          setIsStateUpdated(false);
        }}
      />
    </div>
  );
}
export default CreateBillingPlan;
