import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";
import moment from "moment/moment";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ApiCalling from "../../../network/ApiCalling";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "react-dropdown-select";
import { InvoiceStatus } from "../../../Enums";
import { imageStudentList, invoiceDownload } from "../../CommonImage";
import { Avatar } from "@mui/material";
import InvoiceView from "../../ParentsDashboard/Billing/InvoiceView";
import GeneratePayment from "../BillingPlanStudents/AddPayment";
import ToastHOC from "../../HOC/ToastHOC";
import Search from "../../../assets/Icon/Search.svg";
import close from "../../../assets/Icon/close.svg";
import Loader from "../../../components/common/Loader";
import { set } from "date-fns";
import Sort from "../../../assets/Icon/Short.svg";
import Table, { ExampleTable } from "../../../components/common/Table";
import PendingInvoicesTable from "./PendingInvoicesTable";

function PendingInvoices({ message, InvoiceCount }) {
  const { t } = useTranslation();
  const history = useHistory();
  const school = Util.getSelectedSchool();
  const schoolId = school?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [isVisibleInvoice, setIsVisibleInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [studentId, setStudentId] = useState(0);
  const [studentName, setStudentName] = useState("");
  const [status, setStatus] = useState("0,1");
  const [gradeList, setGradeList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [classId, setClassId] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);

  const statusList = [
    { value: "0,1", title: "All" },
    { value: "0", title: "Unpaid" },
    { value: "1", title: "Pending" },
  ];

  const searchClicked = () => {
    if (search.length > 0) {
      setSearch("");
      setSearchClick("");
    } else {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };
  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setSearchClick(search);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const changeStatus = (data) => {
    setCurrentPage(1);
    setStatus(data[0].value);
  };

  const changeGrade = (data) => {
    setCurrentPage(1);
    setGradeId(data[0].id);
    setClassId(0);
  };

  const changeClassroom = (data) => {
    setCurrentPage(1);
    setClassId(data[0].id || 0);
  };

  useEffect(() => {
    let isInvoiceMounted = true;
    const getAllInvoices = () => {
      setIsLoading(true);
      const data = {
        school_id: schoolId,
        page: currentPage,
        size: recordPerPage,
        student_name: searchClick,
        start_date: "",
        fee_type: "",
        end_date: "",
        status: status,
        grade_id: gradeId,
        classroom_id: classId,
        ascdesc: sortOrder ? "desc" : "asc",
        order_by: sortBy
      };
      ApiCalling.apiCallBodyDataPost("billing-invoice/school", data)
        .then((res) => {
          if (isInvoiceMounted) {
            if (res.data) {
              setIsLoading(false);
              setTotalPages(res.data.total_page);
              InvoiceCount(res.data.total);
              setAllInvoices(res.data.response);
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    getAllInvoices();
    return () => {
      isInvoiceMounted = false;
    };
  }, [currentPage, recordPerPage, searchClick, status, gradeId, classId, sortBy, sortOrder]);

  useEffect(() => {
    console.log("getGradeList useEffect");
    let mounted = true;

    const getGradeList = () => {
      setIsLoading(true);
      ApiCalling.apiCallBodyDataGet("grade/list").then((res) => {
        if (mounted) {
          setGradeList(res?.data);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }
      });
    };

    getGradeList();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    console.log("getClassList useEffect");
    let mounted = true;

    const getClassList = () => {
      setIsLoading(true);
      ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
        if (mounted) {
          const modifiedClassList = res?.data
            .filter((g) => gradeId === 0 || g.Grade.id === gradeId)
            .map((grade) => ({
              id: grade.id,
              name: `${grade.Grade.title}-${grade.name}`,
            }));
          setClassList(modifiedClassList);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }
      });
    };

    getClassList();

    return () => {
      mounted = false;
    };
  }, [gradeId]);

  const allInvoicesdata = () =>{
    setIsLoading(true);
    const data = {
      school_id: schoolId,
      page: currentPage,
      size: recordPerPage,
      student_name: searchClick,
      start_date: "",
      fee_type: "",
      end_date: "",
      status: status,
      grade_id: gradeId,
      classroom_id: classId,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy
    };
    ApiCalling.apiCallBodyDataPost("billing-invoice/school", data)
      .then((res) => {
          if (res.data) {
            setIsLoading(false);
            setTotalPages(res.data.total_page);
            InvoiceCount(res.data.total);
            setAllInvoices(res.data.response);
          }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const manualSort = (asc) => {
    console.log("som", allInvoices)
    console.log(asc)
    const data = [...allInvoices];
    data.sort((a, b) => {
      console.log(a?.types?.map(item => item.Fee_Type), b?.types?.map(item => item.Fee_Type))
      const feeTypeA = a?.types?.map(item => item.Fee_Type).join(" ") || "";
      const feeTypeB = b?.types?.map(item => item.Fee_Type).join(" ") || "";
      console.log(feeTypeA, feeTypeB)
      if (asc) {
        return feeTypeA.localeCompare(feeTypeB);
      } else {
        return feeTypeB.localeCompare(feeTypeA);
      }
    });
    console.log(data);
    setAllInvoices(data);
  };

  return (
    <div className="mt-4">
      <div className={"table-div"}>
        {/* TABLE FILTERS */}
        <div className="d-flex gap-20 align-items-center table-sort-bar">
          {/* SEARCH FILTER */}
          <div className="custom-search-wrap">
            <div className="input-group">
              <input
                className="form-control search"
                placeholder="Search Student"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => EnterHandler(e)}
              />
              <div className="input-group-prepend">
                <div className="search-icon" onClick={searchClicked}>
                  <img
                    src={search.length > 0 ? close : Search}
                    className="svg-icon"
                    alt=""
                    onClick={searchClicked}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* STATUS FILTER */}
          <div className="mr-2">
            <Select
              searchable={false}
              className="select-custom text-nowrap"
              options={[...statusList]}
              onChange={(values) => changeStatus(values)}
              style={{ width: "100%" }}
              labelField="title"
              values={[{ value: "all", title: "Status" }]}
              valueField="value"
            />
          </div>
          {/* GRADE FILTER */}
          <div className="mr-2">
            <Select
              searchable={false}
              className="select-custom text-nowrap"
              placeholder="Grade"
              options={[{ id: 0, title: "All" }, ...gradeList]}
              onChange={(values) => changeGrade(values)}
              style={{ width: "100%" }}
              labelField="title"
              values={[{ id: 0, title: "Grade" }]}
              valueField="id"
            />
          </div>
          {/* ROOMS FILTER */}
          <div className="mr-2">
            <Select
              searchable={false}
              className="select-custom text-nowrap"
              placeholder="Rooms"
              options={[{ id: 0, name: "All" }, ...classList]}
              onChange={(values) => changeClassroom(values)}
              style={{ width: "100%" }}
              labelField="name"
              values={[{ id: 0, name: "Rooms" }]}
              valueField="id"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="table-wrapper">
              {!isLoading ? (
                <PendingInvoicesTable
                  invoices={allInvoices}
                  message={message}
                  setIsVisibleInvoice={setIsVisibleInvoice}
                  setInvoiceId={setInvoiceId}
                  setInvoiceData={setInvoiceData}
                  setIsVisiblePayment={setIsVisiblePayment}
                  setStudentId={setStudentId}
                  setStudentName={setStudentName}
                  setSortBy={setSortBy}
                  setSortOrder={setSortOrder}
                  manualSort={manualSort}
                />
              ) : (
                <Loader />
              )}
              <InvoiceView
                show={isVisibleInvoice}
                onClose={() => {
                  setIsVisibleInvoice(false);
                }}
                invoiceId={invoiceId}
              />
              <GeneratePayment
                show={isVisiblePayment}
                onClose={() => {
                  setIsVisiblePayment(false);
                }}
                onSave={(msg) => {
                  if (msg) {
                    message(msg);
                  }
                  setIsVisiblePayment(false);
                  setCurrentPage(1);
                }}
                studentIds={studentId}
                studentName={studentName}
                invoiceDetails={invoiceData}
                updateData={() => {allInvoicesdata()}}
              />
            </div>
            {totalPages > 0 && (
              <div className="row m-0 table-footer border-top mb-2">
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ height: "72px" }}
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="basic-text">{t("Students Per Page")}</div>
                    <select
                      style={{ width: "72px", cursor: "pointer" }}
                      className="select-dropdown custom-input"
                      value={recordPerPage}
                      onChange={(e) => {
                        setRecordPerPage(e.target.value);
                      }}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>

                  <Stack spacing={2} direction="row" alignItems="center">
                    <div
                      className={`link-button ${
                        currentPage == 1 ? "disabled" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviousClick();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <KeyboardArrowLeftIcon />
                      <span
                        className="basic-text"
                        style={{ fontWeight: "600" }}
                      >
                        {t("Previous")}
                      </span>
                    </div>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, page) => {
                        setCurrentPage(page);
                      }}
                      size="small"
                      hideNextButton
                      hidePrevButton
                    />
                    <div
                      className={`link-button ${
                        currentPage == totalPages ? "disabled" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextClick();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="basic-text"
                        style={{ fontWeight: "600" }}
                      >
                        {t("Next")}
                      </span>
                      <KeyboardArrowRightIcon />
                    </div>
                  </Stack>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(PendingInvoices);
