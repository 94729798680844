import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import AddAnnouncement from "./AddAnnouncement";
import Select from "react-dropdown-select";
import ApiCalling from "../../../network/ApiCalling";
import moment from "moment/moment";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import { Rings } from "react-loader-spinner";
import ToastHOC from "../../HOC/ToastHOC";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Sort from "../../../assets/Icon/Short.svg";
import ExitAlert from "../../../components/ExitAlert";
import AddIcon from "../../../assets/Icon/add post.svg";
import Loader from "../../../components/common/Loader";

function Announcement(props) {
  const { setIsToast } = props;
  const pendingToast = props.location?.pendingToast;
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  let openPopup = props.location.state?.visibleAnnouncement ? true : false;

  const [visibleAnnouncement, setVisibleAnnouncement] = useState(false);
  const [typeId, setTypeId] = useState(0);
  const [gradeData, setGradeData] = useState([]);
  const [gradeId, setGradeId] = useState(0);

  const [activityList, setActivityList] = useState([]);
  const [staff, setStaff] = useState(false);

  const [roomData, setRoomData] = useState([]);
  const [roomId, setRoomId] = useState(0);

  const [stageData, setStageData] = useState([]);
  const [stageId, setStageId] = useState(0);

  const [groupData, setGroupData] = useState([]);
  const [groupId, setGroupId] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalStudents, setTotalStudents] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [announcementData, setAnnouncementData] = useState([]);
  const [announcementID, setAnnouncementId] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [typeList, setTypeList] = useState([]);

  const changeType = (data) => {
    setTypeId(data[0].id);
  };
  const changeCheck = (data) => {
    setGradeId(data[0].id);
  };
  const changeRoom = (data) => {
    setRoomId(data[0].id);
  };
  const changeStage = (data) => {
    setStageId(data[0].id);
  };
  const changeGroup = (data) => {
    setGroupId(data[0].id);
  };
  const searchClicked = () => {
    setSearchClick(search);
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    setAnnouncementId(id);
    setVisibleAnnouncement(true);
  };

  const gradeList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("grade/list")
      .then((res) => {
        setIsLoading(false);
        setGradeData(res.data);
      })
      .catch((err) => {
        return err;
      });
  };
  const roomList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("classroom/list")
      .then((res) => {
        setIsLoading(false);
        const modifiedClassList = res.data.map((c) => ({
          id: c.id,
          name: c.name,
        }));
        setRoomData(modifiedClassList);
      })
      .catch((err) => {
        return err;
      });
  };
  const stageList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("stage/list")
      .then((res) => {
        setIsLoading(false);
        setStageData(res.data);
      })
      .catch((err) => {
        return err;
      });
  };
  const groupList = async () => {
    setIsLoading(true);
    const data = {
      page_size: 10000,
      page_number: 1,
    };
    ApiCalling.apiCallBodyDataPost("group/list", data)
      .then((res) => {
        setIsLoading(false);
        setGroupData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTypeList = async () => {
    setIsLoading(true);

    ApiCalling.apiCallBodyDataGet("announcement-type/list")
      .then((res) => {
        setIsLoading(false);
        setTypeList(res.data || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTypeList();
    gradeList();
    roomList();
    stageList();
    groupList();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  useEffect(() => {
    setVisibleAnnouncement(openPopup);
    openPopup = !openPopup;
  }, [openPopup]);

  const announcementList = async () => {
    setIsLoading(true);
    let data = {
      strSearch: "",
      page_number: currentPage,
      page_size: recordPerPage,
      stage_id: stageId,
      type: typeId,
      grade_id: gradeId,
      group_id: groupId,
      room_id: roomId,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
    };
    ApiCalling.apiCallBodyDataPost("activity/school-announcements", data)
      .then((res) => {
        // console.log("GET Announcement", res.data.data);
        setIsLoading(false);
        setActivityList(res.data.data);
        setStaff(true);
        setAnnouncementData(res.data.data);
        setTotalPages(res.data.totalPages);
        setTotalStudents(res.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  useEffect(() => {
    announcementList();
  }, [
    sortBy,
    sortOrder,
    typeId,
    roomId,
    gradeId,
    stageId,
    groupId,
    currentPage,
    recordPerPage,
  ]);

  return (
    <div>
      <div className="container-fluid">
        <div className="body">
          <div className="col-lg col-md-12 p-3">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="page-head py-3">{t("Announcement")}</h2>
              <button
                className={`${
                  Util.hasPermission("ANNOUNCEMENT_ADD")
                    ? "btn primary-sm-btn"
                    : "no-permission"
                }`}
                type="button"
                onClick={() => {
                  setVisibleAnnouncement(true);
                  setAnnouncementId(0);
                }}
              >
                <img src={AddIcon} className="mr-1" height={20} width={20} />
                {t("Add New Announcement")}
              </button>
            </div>
            <div className="row p-3">
              <div className="table-div p-0">
                <div className=" d-flex align-items-center text-nowrap  col-lg-8 col-md-12 my-2 p-1">
                  <div className="ml-3">
                    <Select
                      className="select-custom"
                      options={[{ id: 0, type: "Type" }, ...typeList]}
                      labelField="type"
                      valueField="id"
                      values={[{ id: 0, type: "Type" }]}
                      onChange={(values) => changeType(values)}
                    />
                  </div>

                  <div className="ml-3">
                    <Select
                      className="select-custom"
                      options={[{ id: 0, stage: "Level" }, ...stageData]}
                      labelField="stage"
                      valueField="id"
                      values={[{ id: 0, stage: "Level" }]}
                      onChange={(values) => changeStage(values)}
                    />
                  </div>

                  <div className="ml-3">
                    <Select
                      className="select-custom"
                      options={[{ id: 0, title: "Grade" }, ...gradeData]}
                      labelField="title"
                      valueField="id"
                      values={[{ id: 0, title: "Grade" }]}
                      onChange={(values) => changeCheck(values)}
                    />
                  </div>

                  <div className="ml-3">
                    <Select
                      className="select-custom"
                      options={[{ id: 0, name: "Room" }, ...roomData]}
                      labelField="name"
                      valueField="id"
                      values={[{ id: 0, name: "Room" }]}
                      onChange={(values) => changeRoom(values)}
                    />
                  </div>

                  <div className="ml-3">
                    <Select
                      className="select-custom"
                      options={[{ id: 0, name: "Group" }, ...groupData]}
                      labelField="name"
                      valueField="id"
                      values={[{ id: 0, name: "Group" }]}
                      onChange={(values) => changeGroup(values)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 m-0 p-0">
                  <div className="body">
                    {!isLoading ? (
                      <>
                        <table className="table">
                          <thead>
                            <tr className="text-nowrap">
                              {/* <th scope="col" className="check-col">
                                <label className="fancy-checkbox element-left">
                                  <input
                                    type="checkbox"
                                    name="allselect"
                                    onChange={handleAllSelectedStudent}
                                  />
                                  <span></span>
                                </label>
                              </th> */}

                              <th scope="col">
                                {t("Announcement")}{" "}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("activity.title");
                                  }}
                                />
                              </th>
                              <th scope="col">
                                {t("Type")}{" "}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("activity.announcement_type");
                                  }}
                                />
                              </th>
                              <th scope="col" className="text-right">
                                {t("Date")}{" "}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("activity.created_on");
                                  }}
                                />
                              </th>
                            </tr>
                          </thead>
                          {announcementData.length > 0 ? (
                            <>
                              <tbody>
                                {announcementData.map((item) => {
                                  return (
                                    <tr
                                      style={{
                                        backgroundColor: `${
                                          selectedId.includes(item.id)
                                            ? "rgba(76, 166, 168, 0.1)"
                                            : ""
                                        }`,
                                      }}
                                      key={item.id}
                                    >
                                      {/* <td>
                                          <label className="fancy-checkbox element-left">
                                            <input
                                              type="checkbox"
                                              name={item.title}
                                              onChange={AnnouncementChecked}
                                              value={item.id}
                                              checked={selectedId.includes(
                                                item.id
                                              )}
                                            />
                                            <span></span>
                                          </label>
                                        </td> */}
                                      <td style={{ verticalAlign: "middle" }}>
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            handleEdit(e, item.id);
                                          }}
                                        >
                                          {item.title}
                                        </a>
                                      </td>
                                      <td>{item.announcement_type?.type}</td>
                                      <td className="text-right">
                                        {moment(item.created_on).format(
                                          Util.getMomentDateTimeFormat(
                                            school.date_format
                                          )
                                        ) +
                                          " " +
                                          moment(item.created_on).format(
                                            Util.getMomentDateTimeFormat(
                                              school.time_format
                                            )
                                          )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </>
                          ) : (
                            <>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    {t(
                                      "There are no sent announcements. Click the 'New Announcement ' button to create one."
                                    )}
                                  </td>
                                </tr>
                              </tfoot>
                            </>
                          )}
                        </table>
                        <div className="row m-0 table-footer border-top ">
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ height: "72px" }}
                          >
                            <div className="d-flex align-items-center gap-2">
                              <div className="basic-text">
                                {t("Announcement Per Page")}
                              </div>
                              <div>
                                <select
                                  name=""
                                  id=""
                                  style={{ width: "72px", cursor: "pointer" }}
                                  className="select-dropdown  custom-input"
                                  value={recordPerPage}
                                  onChange={(e) => {
                                    setRecordPerPage(e.target.value);
                                  }}
                                >
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>
                              </div>
                            </div>
                            {totalPages > 0 && (
                              <div>
                                <Stack
                                  spacing={2}
                                  direction="row"
                                  alignItems="center"
                                >
                                  <div
                                    className={`link-button ${
                                      currentPage == 1 ? "disabled" : ""
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handlePreviousClick();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <KeyboardArrowLeftIcon />
                                    <span className="basic-bold-text">
                                      {t("Previous")}
                                    </span>
                                  </div>
                                  <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={(event, page) => {
                                      setCurrentPage(page);
                                    }}
                                    size="small"
                                    hideNextButton
                                    hidePrevButton
                                  />
                                  <div
                                    className={`link-button ${
                                      currentPage == totalPages
                                        ? "disabled"
                                        : ""
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleNextClick();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="basic-bold-text">
                                      {t("Next")}
                                    </span>
                                    <KeyboardArrowRightIcon />
                                  </div>
                                </Stack>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddAnnouncement
        announcementId={announcementID}
        isVisible={visibleAnnouncement}
        isClose={() => {
          setVisibleAnnouncement(false);
        }}
        onSave={(msg) => {
          if (msg) {
            setIsToast(msg);
          }
          setVisibleAnnouncement(false);
          announcementList();
        }}
      />
    </div>
  );
}
export default ToastHOC(Announcement);
